import React, { useEffect, memo, useState } from 'react';
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import SideMenu from './../../Components/SideMenu';
import './TrustCenterEditorStyles.css';
import EditorDocuments from './EditorDocuments';
import SecurityCard from './../../SecurityCard/SecurityCard';
import OverviewComlianceTruestCenter from './OverviewComlianceTruestCenter';

const TrustCenterEditor: React.FC = memo(() => {
    useEffect(()=>{
        console.log('wow');
        
    },[])
    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'Trust Center Editor'} />
                <div className="router-container pt-3">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 offset-lg-1 offset-xl-1 offset-xxl-1">
                        <OverviewComlianceTruestCenter/>
                        <EditorDocuments />
                        <div className="mt-4"></div>
                        <SecurityCard isEditor={true} />
                    </div>
                </div>
            </section>
        </main>
    )
})

export default TrustCenterEditor;