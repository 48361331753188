import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideMenu from './../../Components/SideMenu';
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import React, { useEffect, useState } from 'react'
import { faClone, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './Peoples.css'
import { getPeopleList, savePeopleList, deletePeopleList } from './../../ApiServices/People.Service'
import Pager from './../../Helper/Pager';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form";
import { PaginationProps, PeoplesModel } from '@/Models/PeoplesModel';
import { NotificationModel } from './../../Models/SecurityModel';
import Avatar from './../../Components/Avatar';
import { confirmationManager } from './../../Components/ConfirmationDialog/ConfirmationManager';
import CheckMark from './../../Components/CheckMark';

const Peoples = () => {
    const [show, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [peopleList, setPeopleList] = useState<any[]>([]);
    const [succssMsg, setSuccssMsg] = useState<string>('')
    const [inviteMsg, setInviteMsg] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationProps>({ pageSize: 10, totalPages: 0, currentPage: 1 });
    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
    const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<PeoplesModel>({
        defaultValues: {
            email: '',
            status: true
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setNotification({ type: 'none', msg: '' });
        reset({ email: '',status: true})
        setShow(true)
    };

    useEffect(() => {
        _getPeopleList();
    }, [pagination.currentPage]);

    /**@GetPeopleList Fetches the list of people and updates state with the data and pagination info*/
    const _getPeopleList = async () => {
        setPeopleList([]);
        const result: any = await getPeopleList(pagination.currentPage, pagination.pageSize);
        if (result && result.data && result.data.length) {
            setPeopleList(result.data);
        }
        setPagination((preProps: any) => ({ ...preProps, totalPages: result?.totalPages }));
    }

    /**@Capitalizes the first letter of a given string */
    const capitalizeFirstLetter = (str: any) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    /**@onSubmit Handles form submission and processes data */
    const onSubmit = async (data: PeoplesModel) => {
        setNotification({ type: 'none', msg: '' });
        setLoading(true);

        try {
            const result: any = await savePeopleList(data);
            if (result && result.status === true) {
                handleInviteMsg();
                setNotification({ type: 'success', msg: `${(data.email ? 'Updated' : 'Added')} Successfully` });
                reset({ email: '',status: true})
                _getPeopleList();
                setTimeout(() => {
                    handleClose();
                }, 4000);

            } else {
                if (result.message == 'this email format not accepted') {
                    setNotification({ type: 'error', msg: 'This email format is not accepted.' }); //result.message
                } else {
                    setNotification({ type: 'error', msg: capitalizeFirstLetter(result.message) }); //result.message
                }
            }
            setLoading(false);
        } catch (error) {
            setNotification({ type: 'error', msg: 'An error occurred' });
            setLoading(false);
        }
    }

    const handleInviteMsg = () => {
        setSuccssMsg('Invitation sent successfully!');
        setTimeout(() => {
            setInviteMsg('');
            setSuccssMsg('');
        }, 4000);
    }

    /**@DELETE_PEOPLE */
    const deletePeople = (item: PeoplesModel) => {
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete?',
            onConfirm: async () => {
                const result = await deletePeopleList(item._id);
                if (result && result.status) {
                    _getPeopleList();
                } else {
                    setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
                }
            },
            onCancel: () => {
                console.log('Delete canceled');
            },
        });
    };

    const handlePageChange = (page: number) => {
        setPagination((preProps: any) => ({ ...preProps, currentPage: page }));
    };

    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'People'} />
                <div className="router-container ">
                    <div className="row">
                        <div className="col-lg-10 col-xl-10 offset-xl-1 offset-lg-1 mt-4">
                            <div className="card border">
                                <div className="card-header bg-light border-bottom">
                                    <button onClick={handleShow} className="btn btn-sm btn-success ms-auto d-flex" >Invite people</button>
                                </div>
                                <div className="card-body px-0 pt-1">
                                    {peopleList.map((item: any, index: number) => (
                                        <div className="people-sec">
                                            <div key={`index-${index}`} className="row  ">
                                                <div className="col-md-5 col-8">
                                                    <div className='d-flex'>
                                                        <div>
                                                            <input className="form-check-input mt-1 me-3" type="checkbox" value="" aria-label="Checkbox for following text input" />
                                                        </div>

                                                        <div className='d-flex'>
                                                            <span>
                                                                <Avatar key={index} username={`${item.firstName} ${item.lastName}`} />
                                                            </span>
                                                            <span>
                                                                <div className="people-name">{item.firstName} {item.lastName}</div>
                                                                <p className='people-desc'>{item.companyName}</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 col-12 pe-0 email-sec">
                                                    {item.email}
                                                </div>

                                                <div className="col-md-2 col-12 pe-0">
                                                    <div className='ms-auto PendingInvite'>{item.Approved == 'accept' ? "Registration Finish" : "Pending Invite"} <FontAwesomeIcon icon={faClone} /></div>
                                                </div>
                                                <div className="col-md-2 col-12">
                                                    <div className="d-flex justify-content-end mt-2">
                                                        {item.Approved == 'accept' ? (<button className='btn btn-outline-secondary btn-sm me-2 border collaborate-btn'>
                                                            collaborate
                                                        </button>
                                                        ) : null}

                                                        <button onClick={() => deletePeople(item)} className='btn btn-sm btn-outline-danger border px-2'>
                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    
                                    <div className="d-flex justify-content-end mt-3 pe-3">
                                        <Pager
                                            totalPages={pagination.totalPages}
                                            currentPage={pagination.currentPage}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal size="lg" centered show={show} onHide={handleClose} animation={true}>
                <form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton className='bg-light'>
                        <Modal.Title className='fs-5'>Invite People</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='mt-4 px-lg-5'>
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                        pattern: {
                                            value: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                                            message: "Invalid email address"
                                        }
                                    }}
                                    render={({ field }) => <input type="text" autoComplete="off" className="form-control py-2 mb-3" placeholder="Email" {...field} />}
                                />
                                {errors.email && (
                                    <div className="sc-input-err-msg" style={{marginTop:-15}}>{errors.email?.message}</div>
                                )}
                                {!errors.email && notification.type == 'error' && (
                                    <div className="sc-input-err-msg" style={{marginTop:-15}}>{notification?.msg}</div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 pb-0'>
                        <Button type='submit' disabled={isLoading} className='custom-btn mx-auto mb-3'>Send Invitation</Button>
                    </Modal.Footer>
                    <div className='text-center mb-3 d-flex justify-content-center gap-2'>
                        <span>{succssMsg}</span> {succssMsg ? <CheckMark height={20} width={20} color={'success'} /> : null}
                    </div>
                </form>
            </Modal>
        </main>
    )
}

export default Peoples;