import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowRestore, faCircleCheck, faArrowRight, faPlus, faShieldHalved, faSave, faWarning, faInfo, faInfoCircle, faCheckCircle, faTrash, faFilePdf, faDownload } from '@fortawesome/free-solid-svg-icons';
import './SecurityCard.css'; // Import the CSS file
import SecutiryCardDetailPopup from './SecutiryCardDetailPopup';
import { useEffect, useState } from 'react';
import IconButton from '../Components/Buttons/IconButton';
import Spinner from 'react-bootstrap/Spinner';
import { Props } from '../Models/SecurityModel';
import { getSecurityQuestion } from './../ApiServices/TrustCenter';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SecurityCardEdit from './SecurityCardEdit';
import ReactContext from './../ApiServices/ReactContext/ReactContext';
import GetAccessPermission from './../TrustCenter/GetAccessPermission';
import { useDispatch } from 'react-redux';
import { getTSList } from './../Redux/Actions/SagaAction';

const SecurityCard = ({ isEditor }: Props) => {
    const [securityData, setSecurityData] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [selectCardIndexNo, setSelectCardIndexNo] = useState(0);
    const [selectCardSubIndexNo, setSelectCardSubIndexNo] = useState(0);
    const [tempObj, setTempObj] = useState<any>({});
    const [isGetAccess, setIsGetAccess] = useState(false);
    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;
    const dispatch = useDispatch()

    useEffect(() => {
        getData();
    }, []);



    const getData = async () => {
        dispatch(getTSList((isEditor ? false : true)))
        const result: any = await getSecurityQuestion((isEditor ? false : true));
        if (result && result.data) {
            setData({ ...data, securityList: result.data });
            setSecurityData(result.data);
        }
    }

    const updateSecutityQuestion = (val: any) => {
        setTempObj(val);
        console.log(val, 'val');
        setShowDialog(true);
    };

    const AddNewTrustCenterCard = () => {
        setTempObj({
            isNewCard: true,
            mainTitle: "",
            subData: [{
                title: "",
                description: "",
                isPublic: false,
                files: []
            }]
        })
        setShowDialog(true);
    }

    const openViewDetail = (index: number, subIndex: number) => {
        setSelectCardIndexNo(index);
        setSelectCardSubIndexNo(subIndex);
        setShow(true);
    }

    const renderData = (securityData: any) => {
        if (securityData && securityData.length == 0) {
            return (<div></div>)
        }
        return securityData.map((item: any, index: number) => (
            <div className="col-12 col-md-6 col-lg-4 mt-2" key={`sc-${index}`}>
                <div className="">
                    <div className="statuscard card shadow-sm">
                        <div className="statuscard-header card-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 style={{ width: '100%', fontSize: 20 }}>
                                    <div onClick={() => openViewDetail(index, 0)} className="d-flex align-items-center header pointer">
                                        <FontAwesomeIcon icon={faWindowRestore} color="#777" />
                                        <div className="ml-3 text-truncate capitalize-first-letter" style={{ color: "#000", maxWidth: isEditor ? 'calc(100% - 80px)' : '100%' }}>{item?.mainTitle}</div>
                                    </div>
                                </h2>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            {isEditor ? (
                                <IconButton onClick={() => updateSecutityQuestion(item)} style={{ height: 25 }} className="more-icon">
                                    <span>Edit</span>
                                    <img style={{ width: 16, height: 'auto' }} src={process.env.PUBLIC_URL + 'assets/imgs/edit.png'} />
                                </IconButton>

                            ) : null}


                            <ul className="list-group ">
                                {item.subData && item.subData.length ? (
                                    (item.subData.slice(0, 3).map((subItem: any, subIndex: number) => (
                                        <React.Fragment key={`sq-${subIndex}`}>
                                            <li onClick={() => openViewDetail(index, subIndex)} className="list-group-item border-0 px-0 pointer">
                                                <div className="d-flex">
                                                    {subItem.title !== '' ? (
                                                        <>
                                                            <FontAwesomeIcon className="checkicon" icon={faCircleCheck} color="#4BA654" width={24} />
                                                            <span className="ml-2 fw-bold capitalize-first-letter" style={{ color: "#000" }}>{subItem.title}</span>
                                                        </>) : null}

                                                </div>
                                            </li>
                                        </React.Fragment>
                                    )))
                                ) : null}
                                {/* {Object.keys().filter(subKey => subKey !== '_id').slice(0, 3).map((subKey, subIndex, subArray) => (
                                    <React.Fragment key={subKey}>
                                        <li onClick={() => openViewDetail(index, subIndex)} className="list-group-item border-0 px-0 pointer">
                                            <div className="d-flex">
                                                <FontAwesomeIcon className="checkicon" icon={faCircleCheck} color="#4BA654" width={24} />
                                                <span className="ml-2" style={{ color: "#014181" }}>{subKey}</span>
                                            </div>
                                        </li>
                                        {(subArray.length === 1 || subIndex < subArray.length - 1)}
                                    </React.Fragment>
                                ))} */}
                            </ul>

                            {(item.subData && item.subData.length > 3) && (
                                <div onClick={() => openViewDetail(index, 0)} className="viewmore pointer">
                                    View more
                                    <span>
                                        <FontAwesomeIcon icon={faArrowRight} className="arrowicon" />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        ))
    };

    return (
        <div className="wrapper pt-2" style={isEditor ? { width: '100%' } : {}}>
            <div className="row">
                {securityData ? renderData(securityData) : <div className="text-center"><Spinner animation="border" variant="primary" />&nbsp;&nbsp;Loading...</div>}

                {isEditor ? (
                    <div className="col-12 col-md-6 col-lg-4 mt-2">
                        <div onClick={AddNewTrustCenterCard} className="statuscard card shadow-sm">
                            <div className="card-body d-flex pointer flex-column align-items-center justify-content-center">
                                <FontAwesomeIcon className="checkicon" style={{ fontSize: '100px' }} icon={faPlus} color={'var(--lt-primary)'} />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            {/* SECURITY_CARD_EDITOR */}
            <SecurityCardEdit
                securityData={tempObj}
                getData={getData}
                show={showDialog}
                onHide={() => setShowDialog(false)}
            />


            {/* right side list  */}
            <Offcanvas placement={'end'} show={show} style={{ minWidth: '730px', }} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton className='border-bottom'>
                    <Offcanvas.Title className=''><FontAwesomeIcon className="checkicon" icon={faShieldHalved} color={'var(--lt-primary)'} />&nbsp;&nbsp;{securityData && securityData.length ? securityData[selectCardIndexNo]?.mainTitle : ''}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <SecutiryCardDetailPopup data={securityData} onAccessRequest={() => {
                        setIsGetAccess(true);
                        setShow(false)
                    }} selectCardSubIndexNo={selectCardSubIndexNo} selectCardIndexNo={selectCardIndexNo} />
                </Offcanvas.Body>
            </Offcanvas>

            {isGetAccess ? (
                <GetAccessPermission onHide={() => { setIsGetAccess(!isGetAccess) }} openPopup={isGetAccess} />
            ) : null}
        </div>
    );
};
export default SecurityCard;
