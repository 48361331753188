// Documents.tsx
import React, { useEffect, useState } from 'react';
import './SecurityReview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSearch, faXmark, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import { Modal, Button, Container, Form, } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Validate from './../../Helper/ValidationUtils';
import { SecurityReviewFormModel } from './../../Models/SecurityModel';
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
// import InputGroup from 'react-bootstrap/InputGroup';
import { addAndUpdateAccounts } from './../../ApiServices/TrustCenter';
import { toastManager } from './../../Components/ToastDialog/ToastManager';
declare var bootstrap: any;

const SecurityReview: React.FC = () => {
    var modal: any;
    const [show, setShow] = useState(false);
    const [getAccessPopup, setShowAccessPopup] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [email, setEmail] = useState<string>("");

    const { control, handleSubmit, formState: { errors }, watch, reset } = useForm<SecurityReviewFormModel>({
        defaultValues: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            workEmail: "",
            companyName: "",
            relationshpLinkedIn: "",
            countryRegion: "",
            _id: "",
            cboxA: false,
            cboxB: false,
        }
    });

    const countryList: any = [{ "country": "China" }, { "country": "India" }, { "country": "United States" }, { "country": "Indonesia" }, { "country": "Brazil" }, { "country": "Nigeria" }, { "country": "Bangladesh" }, { "country": "Russia" }, { "country": "Mexico" }, { "country": "Japan" }, { "country": "Ethiopia" }, { "country": "Philippines" }, { "country": "Egypt" }, { "country": "Vietnam" }, { "country": "DR Congo" }, { "country": "Turkey" }, { "country": "Iran" }, { "country": "Germany" }, { "country": "Thailand" }];

    const toggleShow = (e: boolean) => {
        if (modal) {
            modal.toggle();
        }
        setShow(e)
    };

    const toggleGetAccessModal = (type: boolean) => {
        setShowAccessPopup(type);
    }

    const checkGetAccessValidation = () => {
        setShowAccessPopup(false);
        toggleShow(true);
        reset({
            firstName: "",
            lastName: "",
            jobTitle: "",
            workEmail: email,
            companyName: "",
            relationshpLinkedIn: "",
            countryRegion: "",
            _id: "",
            cboxA: false,
            cboxB: false,
        })
    }

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
        if (Validate.Email(e.target.value)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }


    const onSubmit = async (e: any) => {
        // e.preventDefault();
        console.log(e);
        const data = watch();
        const obj: any = {
            firstName: data.firstName,
            lastName: data.lastName,
            jobTitle: data.jobTitle,
            workEmail: data.workEmail,
            companyName: data.companyName,
            relationshpLinkedIn: data.relationshpLinkedIn,
            countryRegion: data.countryRegion,
            getUpdates: data.cboxA,
            termsOfservice: data.cboxB
        }

        const result: any = await addAndUpdateAccounts('', obj); /**@UPADTE_API */
        if (result && result.status == true) {
            toastManager.show({
                title: 'Success!',
                message: 'Are you sure you want to delete this item?',
                type: 'success'
            });
            reset({
                firstName: "",
                lastName: "",
                jobTitle: "",
                workEmail: "",
                companyName: "",
                relationshpLinkedIn: "",
                countryRegion: "",
                _id: "",
                cboxA: false,
                cboxB: false,
            })
            setEmail("");
            toggleShow(false);
        } else {
            toastManager.show({
                title: 'Success!',
                message: 'Are you sure you want to delete this item?',
                type: 'danger'
            });
            // setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
        }

        
    };


    return (
        <div className='wrapper'>
            {/* <div className="reviewcard card ">
                <div className="statuscard-header">
                    <div className="d-flex align-items-center justify-content-between">

                        <div className="align-items-center ">
                            <FontAwesomeIcon icon={faLock} color="#014181" />
                            <span className="ml-3" style={{ color: "var(--lt-primary)" }}>Start your security review</span>
                            <div className="mt-3">
                                <span className="downloadtxt" >View & download sensitive information
                                </span>
                            </div>
                        </div>

                        <button onClick={() => toggleGetAccessModal(true)} className="getaccess-button btn btn-primary">
                    </div>
                </div>
            </div> */}

            {/* search items start */}
            {/* <div className="card mt-4 shadow-sm" style={{ width: '100%' }}>
                <div className='d-flex'>
                    <button className="card-body d-block" data-bs-toggle="modal" data-bs-target="#exampleModalToggle2">
                        <FontAwesomeIcon icon={faSearch} color="#999" /> Search items
                    </button>
                    <span className='ctrlK-tet'> Ctrl K </span>
                </div>
            </div> */}

            {/* popup start */}

            {/* <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content rounded">
                        <div className="modal-header bg-white py-1 border-bottom">
                            <FontAwesomeIcon icon={faSearch} color="#999" className='text-sm' /> <input type="search" className='form-control border-0' placeholder='Search items' />
                        </div>
                        <div className="modal-body bg-white text-center searchListt">
                            <div className="searchList m-auto my-4">
                                <div className="pop-search">
                                    <FontAwesomeIcon icon={faSearch} color="#0a66c2" className='text-sm ' />
                                </div>
                                <h5 className='mt-3'>Start searching above</h5>
                            </div>
                        </div>
                        <div className="modal-footer py-0 bg-light">
                            <div className='footer-tet'>
                                <span>to select</span>
                                <span>to navigate</span>
                                <span>to close</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* popup end */}
            {/* search items start */}
            <Modal show={getAccessPopup} onHide={() => toggleGetAccessModal(false)}>
                <Modal.Body>
                    <button onClick={() => toggleGetAccessModal(false)} type="button" className="btn-close-icon">
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div className="welcome-logo">
                        <FontAwesomeIcon icon={faDoorOpen} color={'var(--lt-text-primary)'} />
                    </div>
                    <div className="box">
                        <h5>Get access to LinkedIn's Trust Center</h5>
                        <h6 className="text-muted">To get started, please enter your email</h6>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div data-testid="form-feedback-email">
                                <div className="form-group">
                                    <label className="form-label">
                                        <span>Work Email</span>
                                        <span className="required-label">*</span>
                                    </label>
                                    <input autoComplete="off" onChange={(e: any) => onChangeEmail(e)} value={email} name="email" placeholder="john@example.com" type="email" id="memberEmail" className="form-control" />
                                    <div className="sc-6147408b-0 kpRxNk"></div>
                                    {!isEmailValid ? <div className="invalid-feedback d-block">Please Enter valid Email id</div> : null}
                                </div>
                            </div>

                            <div className="text-center mt-3">
                                <span style={{ fontSize: 12 }}>Do you work at LinkedIn?&nbsp;</span>
                                <a href="https://app.safebase.io/login" target="_blank" rel="noopener noreferrer" className="d-inline btn btn-plain-primary">
                                    <span style={{ color: `rgb(53, 118, 119)` }}>Log into LinkedIn’s workspace</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => toggleGetAccessModal(false)} type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back to Trust Center</button>
                    <PrimaryButton disabled={isEmailValid ? false : true} onClick={() => checkGetAccessValidation()} name={'Continue'} />
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={() => toggleShow(false)}>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                    <Modal.Title>Request access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-primary'>
                        Please provide the following details to get access to Linkedin's Trust Center
                    </p>

                    <Form onSubmit={handleSubmit(onSubmit)} method="post">
                        <Row md={2} className='mb-2'>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`firstName`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter First name" {...field} />}
                                    />
                                    {errors?.firstName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Last name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`lastName`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter Last name" {...field} />}
                                    />
                                    {errors?.lastName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Job title <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`jobTitle`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter job title" {...field} />}
                                    />
                                    {errors?.jobTitle && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.jobTitle?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Work email <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`workEmail`}
                                        control={control}
                                        rules={{ required: 'This field is required', 
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                                                message: "Invalid email address"
                                            } 
                                        }}
                                        render={({ field }) => <Form.Control required readOnly type="text" placeholder="Enter Work email" {...field} />}
                                    />
                                    {errors?.workEmail && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.workEmail?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Company name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`companyName`}
                                        control={control}
                                        rules={{required: 'This field is required'}}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter Company Email" {...field} />}
                                    />
                                    {errors?.companyName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.companyName?.message}</Form.Control.Feedback>
                                    )}


                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Relation with Linkden <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`relationshpLinkedIn`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => (
                                            <Form.Select required {...field}>
                                                <option>Select...</option>
                                                <option>Customer</option>
                                                <option>Prospective Customer</option>
                                                <option>Vendor</option>
                                                <option>Partner</option>
                                                <option>Regulator/Auditor</option>
                                                <option>Researcher</option>
                                                <option>Other</option>
                                            </Form.Select>
                                        )}
                                    />
                                    {errors?.relationshpLinkedIn && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.relationshpLinkedIn?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Country / Region <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`countryRegion`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => (
                                            <Form.Select required {...field}>
                                                <option value={""}>Select Relationship LinkedIn</option>
                                                {countryList.map((item: any, index: number) => <option key={`country-${index}`}>{item.country}</option>)}
                                            </Form.Select>
                                        )}
                                    />
                                    {errors?.countryRegion && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.countryRegion?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mb-2">
                            <Controller
                                name={`cboxA`}
                                control={control}
                                rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-checkbox"
                                        {...field}
                                        value={'false'}
                                        label={
                                            <div>
                                                I have read and agree to SafeBase's <a href='#' className='link-href '>Terms of Service</a> and <a href='#' className='link-href '>Privacy Policy</a> and the <a href='#' className='link-href '>Terms of Service</a> and <a href='#' className='link-href '>Privacy Policy</a> of LinkedIn

                                            </div>
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name={`cboxB`}
                                control={control}
                                rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-checkbox"
                                        {...field}
                                        value={'false'}
                                        label={<div>I would like to get updates from Linkedin's Trust Center via email
                                        </div>}
                                    />
                                )}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { toggleShow(false); setShowAccessPopup(true); }}>
                        Back
                    </Button>

                    <Button className='custom-btn' type="button" variant="primary" onClick={() => {
                        handleSubmit(onSubmit)();
                        console.log(errors, 'errors');

                    }}>
                        Submit Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>

    );

};

export default SecurityReview;
