import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { getAuth, logoutGlobal } from './ApiServices/Auth.Service'
import LoginPage from './Screens/LoginPage/LoginPage';
import AuthManger from './Screens/AuthScreens/index';
import MainPage from './MainPage/MainPage';
import EnterpriseApps from './Screens/EnterpriseApps/EnterpriseApps';
import LLMReview from './Screens/EnterpriseApps/LLMReview';
import Dashboard from './Screens/Dashboard/Dashboard';
import Review from './Screens/EnterpriseApps/Review/Review';
import Account from './Screens/Accounts';
import TrustCenterEditor from './Screens/TrustCenterEditor';
import TrustCenter from './TrustCenter/TrustCenter';
import Register from './Screens/Register';
import Profile from './Screens/Profile';
import Settings from './Screens/Settings';
import ForgotPassword from './Screens/ForgotPassword';
import Peoples from './Screens/Peoples';
import TermsOfService from './Screens/TermsOfService';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import NDA from './Screens/NDA';
import AddNDA from './Screens/NDA/addNDA';
import AiQuestionnaireAutoComplete from './Screens/AiQuestionnaireAutoComplete';
import TokenCheck from './Helper/TokenCheck';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./Redux/Store";
import { useSelector, useDispatch } from 'react-redux';
import { checkSpecialDomain } from './Redux/Actions/SagaAction';

const isAuthenticated = () => {
  const isAuth = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`) ? true : false;
  return isAuth;
};

const PrivateRoute: React.FC<{ element: React.ElementType }> = ({ element: Element }) => {
  return isAuthenticated() ? <Element /> : <Navigate to="/login" replace />;
};

export const AppRoutes = () => {
  const authData: any = useSelector((state: any) => state.AppReducer.authData);
  const isSpecialDomain: string = useSelector((state: any) => state.AppReducer.isSpecialDomain);
  const dispatch = useDispatch();

  React.useEffect(() => {
    checkRedux();
  }, []);

  React.useEffect(() => {
    if (isSpecialDomain == 'TRUE') {
      console.log('isSpecialDomain', '=>', isSpecialDomain);
    }
  }, [isSpecialDomain])

  const checkRedux = () => {
    if (localStorage.getItem('appVersion') && process.env.REACT_APP_VERSION != localStorage.getItem('appVersion')) {
      logoutGlobal();
    } else {
      localStorage.setItem('appVersion', `${process.env.REACT_APP_VERSION}`);
    }

    // dispatch(checkSpecialDomain('versatilecommerce.targheesecstage3221.com'))
    dispatch(checkSpecialDomain(window.location.hostname))
  }


  /**@CHECK_SPECIAL_DOMAIN :Render a loading state while checking the domain */
  if (isSpecialDomain === "") {
    return <div style={{ height: '100vh', width: '100%', color: '#FFF', zIndex: 999, fontSize: 16, backgroundColor: '#FFF' }}></div>;
  }



  return (
    <Routes>
      {isSpecialDomain === 'TRUE' ? (
        <>
          <Route path="/" element={<TrustCenter />} />
          <Route path="/:userId" element={<TrustCenter />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/NDA" element={<NDA />} />
          <Route path="*" element={<TrustCenter />} />
        </>
      ) : (
        <>
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<TrustCenterEditor />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:emailValue" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/provision" element={<AuthManger />} />
          <Route path="/trust-center" element={<PrivateRoute element={TrustCenter} />} />
          {/* email params route */}
          <Route path="/trust-center/:userId" element={<PrivateRoute element={TrustCenter} />} />
          <Route path="/main" element={<PrivateRoute element={MainPage} />} />
          <Route path="/enterprise-apps" element={<PrivateRoute element={EnterpriseApps} />} />
          <Route path="/enterprise-apps/review/:questionnareId" element={<PrivateRoute element={Review} />} /> 
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/account" element={<PrivateRoute element={Account} />} />
          <Route path="/trust-center-editor" element={<PrivateRoute element={TrustCenterEditor} />} />
          <Route path="/profile" element={<PrivateRoute element={Profile} />} />
          <Route path="/settings" element={<PrivateRoute element={Settings} />} />
          <Route path="/llm-question-review" element={<PrivateRoute element={LLMReview} />} />
          <Route path="/ai-questionnaire-auto-complete" element={<PrivateRoute element={AiQuestionnaireAutoComplete} />} />
          <Route path="/NDA/addNDA" element={<PrivateRoute element={AddNDA} />} />
          {authData?.admin ? (<Route path="/people" element={<PrivateRoute element={Peoples} />} />
          ) : null}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      )}
    </Routes>
  )
}

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router basename="/">
          <TokenCheck>
            <AppRoutes />
          </TokenCheck>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
