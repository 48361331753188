import React, { useEffect, useState } from 'react';
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import SideMenu from './../../Components/SideMenu';
import './EnterpriseApps.css';
import LoadingPage from '../../Components/LoadingPage/LoadingPage';
import Download from './Download/Download';
import FilesList from './FilesList/FilesList';
import Offcanvas from 'react-bootstrap/Offcanvas';
import EnterpriseService from '../../ApiServices/Enterprise.Service';
import { CompanysModal } from './../../Models/Companys';
import { Controller, useForm } from 'react-hook-form';
import { getCompanyList, getComplianceData, getQuestionnairesData, setSelectedQuestion, deleteCompanyRequest, getClientDetails } from "./../../Redux/Actions/SagaAction";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CheckMark from './../../Components/CheckMark';
import { Card, Col, Row } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmationManager } from './../../Components/ConfirmationDialog/ConfirmationManager';

// BUCKET_NAME_VALIDATION
function validateS3BucketName(companyName: string) {
  // Check for leading or trailing whitespace
  if (companyName !== companyName.trim()) {
    return "Company name cannot have leading or trailing whitespace.";
  }

  if (companyName.length < 3 || companyName.length > 63) {
    return "Company name must be between 3 and 63 characters.";
  }

  const allowedCharacters = /^[A-Za-z0-9 ]+$/;
  if (!allowedCharacters.test(companyName)) {
    return "Company name can only contain alphabate, numbers, and spaces.";
  }
  return null;
}


const EnterpriseApps: React.FC = (props) => {
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [showDownloadPage, setShowDownloadPage] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState('new');
  const [listType, setListType] = useState<string>('');
  const [companyError, setCompanyError] = useState<any>("");
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modelData, setmodelData] = useState<any>({});
  const dispatch = useDispatch();
  //model
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [modelshow, modelsetShow] = useState(false);
  const modelhandleShow = () => modelsetShow(true);
  const data = useSelector((state: any) => state.AppReducer);
  const selectedQuestion: any = useSelector((state: any) => state.AppReducer.selectedQuestion);
  const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);
  const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<CompanysModal>({
    defaultValues: { companyName: '' }
  });


  useEffect(()=>{
    if (EnterpriseService.getCompanyInfo && EnterpriseService?.getCompanyInfo?._id) {
      setSelectedVendor(EnterpriseService?.getCompanyInfo?._id);
      onChangeCompany(EnterpriseService?.getCompanyInfo?._id);
      console.log('AAA');
    }else if(data.companyList && data.companyList?.length){
      let lastIndex=data.companyList?.length-1;
      setSelectedVendor(data?.companyList[lastIndex]?._id);
      onChangeCompany(data?.companyList[lastIndex]?._id);
    }
  },[data.companyList])


  useEffect(() => {
    /**@REDUX_CALL */
    dispatch(getCompanyList());
    dispatch(getClientDetails());
    // const gres = await getClientDetails();
  }, [dispatch]);


  /**
   * Handles the change in company selection, updates the selected company, 
   * and dispatches actions to fetch compliance and questionnaires data.
   * @param e - The change event from the company selection input.
  */
  const onChangeCompany = async (val: any) => {
    EnterpriseService.selectedCompanyId = val;
    for (let i = 0; i < data.companyList.length; i++) {
      if (data.companyList[i]?._id == val) {
        EnterpriseService.setCompnayInfo=data.companyList[i];
        setSelectedCompanyName(data.companyList[i]?.companyName);
        break;
      }
    }

    setSelectedVendor(val);
    if (val != 'new' || val != '') {
      dispatch(getComplianceData(val));
      dispatch(getQuestionnairesData(val));
    }
  };

  // Function to handle button click
  const handleShowLoadingPage = (data: any, listType: string): void => {
    if (listType == 'only-doument-download') {
      setListType('only-doument-download')
      if (data && data['files'] && data['files'].length) {
        dispatch(setSelectedQuestion({
          questions: [],
          fileName: data['files'][0]['fileName'],
          fileId: data['files'][0]['_id'],
          company_id: data['company_id'],
          _id: data['_id'],
          createdAt: data['createdAt'],
        }));
      }
    } else {
      setListType('')
    }

    const { item, itemType } = data;
    console.log(item, 'item');

    if (item && item['files'] && item['files'].length) {
      dispatch(setSelectedQuestion({
        questions: item['files'][0]['questions'],
        fileName: item['files'][0]['fileName'],
        fileId: item['files'][0]['_id'],
        company_id: item['company_id'],
        _id: item['_id'],
        createdAt: item['createdAt'],
      }));
    }

    if (itemType == 'documents') {
      return;
    }

    setShow(true);
    setShowLoadingPage(true);
    setTimeout(() => {
      setShowLoadingPage(false);
      setShowDownloadPage(true);
    }, 1000); // 20000 milliseconds = 20 seconds
  };

  // Function to handle file process showing loading
  const fileProcessShowLoader = (data: any): void => {
    if (data?.modelShow) {
      modelsetShow(data?.modelShow)
      setmodelData(data)
    }

  };
  //Model close 
  const modelhandleClose = () => {
    modelsetShow(false);
    if (modelData?.itemType != 'documents' && modelData?.checkmark) {
      handleShowLoadingPage(modelData, modelData?.itemType);
      setTimeout(() => {
        setmodelData({})
      }, 10);
    } else {
      setmodelData({})
    }
  }

  // save companys
  const saveCompanyName = async (e: any) => {
    // Trim the companyName property
    e.companyName = e.companyName.trim();
    const obj:any={
      companyName:e.companyName.trim(),
      compliance_bucket:data.clientDetails?.complianceBucket,
      questionnaires_bucket:data.clientDetails?.questionnairesBucket
    }
    if(!obj?.compliance_bucket || !obj?.questionnaires_bucket){
      return;
    }
    setLoading(true);
    const res = await EnterpriseService.saveCompanys(obj);
    if (res) {
      if (res && res?.data && res.data?._id) {
        EnterpriseService.setCompnayInfo=res?.data;
        onChangeCompany(res.data._id);
      } else if (res?.message) {
        setCompanyError(res?.message);
      }
      dispatch(getCompanyList());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    let item: any = (data.questionArrayList[data.questionArrayList.length - 1]);
    if (item && item['files']) {
      dispatch(setSelectedQuestion({
        questions: item['files'][0]['questions'],
        fileName: item['files'][0]['fileName'],
        fileId: item['files'][0]['_id'],
        company_id: item['company_id'],
        _id: item['_id'],
        createdAt: item['createdAt'],
      }));
    }
  }, [data.questionArrayList])


  /**
   * Refreshes the questionnaires data by dispatching an action with the currently selected vendor.
  */
  const refresh = async () => {
    dispatch(getQuestionnairesData(selectedVendor));
  }

    /**@DELETE_PEOPLE */
    const deleteCompany = (item: any) => {
      confirmationManager.requestConfirmation({
          message: 'Are you sure you want to delete?',
          onConfirm: async () => {
              let obj:any={
                companyId:item._id,
                compliance_bucket:clientDetails.complianceBucket,
                questionnaires_bucket:clientDetails.questionnairesBucket,
             }
             //console.log(item, 'deleteCompany', obj);
              dispatch(deleteCompanyRequest(obj));
          },
          onCancel: () => {
              console.log('Delete canceled');
          },
      });
  };

  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title='Vendor' />
        <div className="router-container">
          <div className="enterprise-container mt-3">
            {/* {JSON.stringify(data.companyList)}++ */}
            {data.companyList.length ? (
              <section>
                <div className="row">
                  <div className="col-lg-4">
                    <select onChange={(e: any) => onChangeCompany(e.target.value)} value={selectedVendor} className='form-select'>
                      <option value="new">Create Vendor</option>
                      {data.companyList.map((item: any, index: number) => {
                        return <option value={item?._id} key={index}>{item.companyName}</option>
                      })}
                    </select>
                  </div>
                </div>
              </section>
            ) : null}

            {((data.companyList.length == 0) || selectedVendor == 'new') ?
              (<section>
                <div className='row'>
                  <div className="col-md-6 offset-md-3 mb-5">
                    <div className='companys-reg mt-5 px-lg-5'>
                      <div className="bg-light border rounded p-5">
                        <form autoComplete={'off'} onSubmit={handleSubmit(saveCompanyName)}>
                          <div>
                            <label htmlFor="" style={{ paddingBottom: 5 }}>Company name</label>
                            <Controller
                              name="companyName"
                              control={control}
                              rules={{
                                required: 'This field is required',
                                validate: value => validateS3BucketName(value) || true
                              }}
                              render={({ field }) => <input type="text" autoComplete="off" className="form-control" {...field} />}
                            />
                            {errors.companyName && (
                              <div className="sc-input-err-msg">{errors.companyName?.message}</div>
                            )}
                            {!errors?.companyName && companyError != "" ? (
                              <div className="sc-input-err-msg capitalize-first-letter">{companyError}</div>
                            ) : null}
                          </div>
                          <br />
                          <div className="text-center">
                            <button disabled={isLoading ? true : false} className='btn btn-outline-secondary ms-auto px-4'>Submit</button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="text-center mt-4">
                      <Button className="mb-2" variant="secondary" onClick={() => setDeleteCompanyModal(true)}>Edit Company List</Button>
                    </div>
                  </div>
                </div>
              </section>
              ) : null}

            {selectedVendor != 'new' ?
              (
                <section>
                  <div className="main">
                    <div className="main-left">
                      <div className="heading-container"></div>
                      <FilesList listType="documents" companyName={selectedCompanyName} files={data.complianceArrayList} refresh={() => dispatch(getComplianceData(selectedVendor))} handleShowLoadingPage={handleShowLoadingPage} fileProcessShowLoader={fileProcessShowLoader} onClick={handleShow} companyId={selectedVendor} />
                      <div className="horizontal-divider" />
                      <FilesList listType="questions" companyName={selectedCompanyName} files={data.questionArrayList} refresh={() => refresh()} handleShowLoadingPage={handleShowLoadingPage} fileProcessShowLoader={fileProcessShowLoader} onClick={handleShow} companyId={selectedVendor} />
                    </div>
                  </div>
                </section>
              ) : null}

          </div>
        </div>
      </section>

      <Modal
        show={modelData?.checkmark ? true :false}
        onHide={modelhandleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{modelData?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body d-flex justify-content-center align-items-center gap-2"
          style={{ lineHeight: '20px' }}>
          <span className="text-capitalize">{modelData?.message}</span>
          <span><CheckMark height={20} width={20} color={'success'} /></span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={modelhandleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

      <Offcanvas show={show} placement={'end'} onHide={handleClose} style={{ width: '40%' }}>
        <Offcanvas.Header closeButton className='shadow-sm'>
          <Offcanvas.Title>
            <div className="d-flex">
              <img src={process.env.PUBLIC_URL + 'assets/imgs/logo-secondary.png'} alt="logo" style={{ width: '72px' }} />
              <h2 className='ms-3'>{selectedQuestion?.fileName ? selectedQuestion?.fileName : ''}</h2>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="main-right">
            {showDownloadPage && <Download loadingTime={1000} listType={listType} />}
            {showLoadingPage && <LoadingPage />}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={deleteCompanyModal} fullscreen={true} onHide={() => setDeleteCompanyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Company List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
              <Card body className={"shadow-sm"}>
                <ListGroup as="ol" numbered variant="flush">
                  {data.companyList.map((item: any, index: number) => {
                    return (
                      <ListGroup.Item as="li"
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">
                          <span className="fw-bold">{item.companyName}</span>
                        </div>
                        <span onClick={() => deleteCompany(item)} className={'pointer'}>
                          <FontAwesomeIcon color='#d90429' icon={faTrash} />
                        </span>
                      </ListGroup.Item>
                    )
                  })}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </main>
  )
}
export default EnterpriseApps;