import React, { useState } from 'react';
import './Tabs.css'; // Ensure you import the CSS file

interface Props{
    tabs:string[];
    isEditor?:boolean;
    onChangeTab:(e:string)=>void;
}
const Tabs = ({ tabs, onChangeTab, isEditor }:Props) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const handleTabClick = (tabName:string) => {
        setActiveTab(tabName);
        onChangeTab(tabName);
    };

    return (
        <div className={`documents-card-tab-nav ${isEditor ? 'editor':''}`} data-testid="documents-card-tabs-nav" role="tablist">
            {tabs.map((tab:any) => (
                <div key={tab} className={`nav-item ${activeTab === tab ? 'active' : ''}`}
                onClick={() => handleTabClick(tab)}>
                    {tab}
                </div>
            ))}
        </div>
    );
};

export default Tabs;