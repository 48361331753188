import Api from "../Helper/Constant";
import ApiFetch from "../Helper/FetchApi";
import axios from 'axios';
const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;
class EnterpriseService {
    selectedCompanyId: string = "";
    constructor() {

    }

    get getCompanyInfo() {
        const encodedString = sessionStorage.getItem('companyInfo');
        if (encodedString) {
            const jsonString = atob(encodedString);
            return JSON.parse(jsonString);
        }
        return {};
    }

    // Getter for the name property
    set setCompnayInfo(obj: any) {
        const jsonString = JSON.stringify(obj);
        const encodedString = btoa(jsonString);
        sessionStorage.setItem('companyInfo', encodedString);
    }

    /**@GET_COMPANYS */
    async getCompanys() {
        return ApiFetch.fetchGet(`${Api.getCompanys}`);
    }

    /**@SAVE_COMPANYS */
    async saveCompanys(reqBody: any) {
        return ApiFetch.fetchPost(`${Api.saveCompanys}`, JSON.stringify(reqBody));
    }

    /**@DELETE_COMPANYS */
    async deleteCompanys(reqBody:any) {
        return ApiFetch.fetchDelete(`${Api.deleteCompanys}`, JSON.stringify(reqBody));
    }


    /**@GET_COMPANYS */
    async getCompliancefiles(cId: string) {
        return ApiFetch.fetchGet(`${Api.getCompliancefile}${cId}`);
    }

    /**@UPDATE_COMPANYS */
    async compliancefileUpdate(id: string, reqBody: any) {
        return ApiFetch.fetchPut(`${Api.compliancefileUpdate}/${id}`, JSON.stringify(reqBody));
    }

    /**@SAVE_COMLIANCEFILE */
    async formDataFileSave(reqBody: any) {
        let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
        let token: any = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`);
        if (!token) {
            token = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`)
        }
        const option: any = { method: 'POST', body: reqBody, headers: { 'DbName': `Bearer ${dbNameToken}`, 'Authorization': `Bearer ${token}` } };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${Api.questionnairesSave}`, option);
        const result = await response.json();
        return result;
    }

    /**@GET_QUESTIONNAIRES */
    async getQuestionnaires(cId: string) {
        return ApiFetch.fetchGet(`${Api.questionnairesList}/${cId}`);
    }


    /**@complianceFileSave */
    async complianceFileSave(reqBody: any) {
        return ApiFetch.fetchPost(`${Api.complianceFileSave}`, JSON.stringify(reqBody));
    }


    /**@questionnaires/question/update */
    async updateAnswer(reqBody: any) {
        return ApiFetch.fetchPut(`${Api.updateEnterpriseAnswer}`, JSON.stringify(reqBody));
    }

    /**@getCommentList */
    async getCommentList(question: string) {
        return ApiFetch.fetchGet(`${Api.getComment}${question}`);
    }

    /**@getCountOfQuestion */
    async getCountOfQuestion(question: string) {
        return ApiFetch.fetchGet(`${Api.getCountOfQuestion}${question}`);
    }

    /**@getCountOfQuestion */
    async getLLMQuestions(fileName: string) {
        return ApiFetch.fetchFlaskGet(`${Api.llmQuestions}?file_name=${fileName}`);
    }

    // TO DO: why are variable names different
    /**@getQuestionAndAnswers */
    async getLLMQuestionsAnswers(fileName: string) {
        return ApiFetch.fetchFlaskGet(`${Api.llmQuestionsAnswers}?collection_name=${fileName}`);
    }

    /**@getCountOfQuestion */
    async getTestCollections() {
        return ApiFetch.fetchFlaskGet(`${Api.testCollections}`);
    }

    /**@GET_ClientDetails */
    async getClientDetails(isOutSide?: boolean) {
        return ApiFetch.fetchGet(isOutSide ? Api.getclientDetailsOutside : Api.getclientDetails);
    }

    /**@Genarate_Answer_Using_LLM */
    async genarateAnswerUsingLlm(reqBody:any) {
        return ApiFetch.fetchPostFlask(`${Api.genarateAnswerUsingLlm}`, JSON.stringify(reqBody));
    }

    /**@DELETE_ENTERPRISE_FILES */
    async deleteEnterpriceFiles(fileId:any, action:'documents' | 'questions') {
        return ApiFetch.fetchDelete(`${(action=='documents' ?Api.complianceFileDelete :Api.questionnairesDelete)}${fileId}`,JSON.stringify({}));
    }

    /**@DELETE_ENTER_PRISE_FILES_FROM_S3 */
    async enterpriseS3bucketDeleteFile(reqBody:any) {
        return ApiFetch.fetchPostFlask(`${Api.enterpriseS3bucketDeleteFile}`,JSON.stringify(reqBody));
    }

    // /**@downloadQuestion */
    // async downloadQuestion(fileId: string){
    //     return ApiFetch.fetchGet(`${Api.downloadQuestion}${fileId}`);
    // }

    /**@downloadQuestion */
    async downloadQuestion(fileId: string) {
        try {
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            try {
                dbNameToken = dbNameToken.replace(/["]/g, '')
            } catch (e) {
                dbNameToken = window.location.hostname;
            }

            let token: any = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`);
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}${Api.downloadQuestion}${fileId}`, // Your API endpoint
                method: 'GET',
                responseType: 'blob', // Important for downloading files
                headers: { 'DbName': `Bearer ${dbNameToken}`, 'Authorization': `Bearer ${token}` }
            });

            // Create a URL for the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'questions_and_answers.xlsx'); // Specify file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }
}
export default new EnterpriseService();

