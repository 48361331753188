import React, { useEffect, useRef, useState } from 'react'
import './SecutiryCardDetailPopup.css'
import { faEye, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { S3Download } from './../../ApiServices/S3Bucket';
import PrimaryButton from './../../Components/Buttons/PrimaryButton';
import { userAccessCheck, createDownloadLogs, getLoginUserAccess } from '../../ApiServices/TrustCenter';
import CheckMark from './../../Components/CheckMark';
import { useSelector } from 'react-redux';

const SecutiryCardDetailPopup = ({ data, selectCardIndexNo, selectCardSubIndexNo, onAccessRequest }: any) => {
	const [fileAccessPermission, setFileAccessPermission] = useState<boolean>(false);
	const cardRefs = useRef<(HTMLDivElement | any)[]>([]);
	const securityList: any = useSelector((state: any) => state.AppReducer.securityList);


	useEffect(() => {
		(async () => {
			setFileAccessPermission((await userAccessCheck()));
		})();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (selectCardSubIndexNo > -1 && cardRefs.current[selectCardSubIndexNo]) {
				cardRefs.current[selectCardSubIndexNo].scrollIntoView({ behavior: 'smooth' });
			}
		}, 500);
	}, [selectCardSubIndexNo]);

	const onFileDownload = async (index: number, fileIndex: number, isPublic: boolean) => {
		//Download file
		const obj: any = { ...data[selectCardIndexNo]['subData'][index] };
		let fileObj: any = obj.files[fileIndex];
		S3Download({ fileKey: fileObj._id, bucketName: 'mytest1-docs', newFileName: fileObj.fileName }).then((res: any) => {
			getLoginUserAccess().then((res: any) => {
				if (res) {
					createDownloadLogs([fileObj.fileName], "Security Card", "editor", isPublic);
				} else {
					createDownloadLogs([fileObj.fileName], "Security Card", "user", isPublic);
				}
			})

		}).catch((err: any) => {
			console.log(err, 'err file');
		})
	};

	const FileGroupList = ({ sno, files, isPublic }: any) => {
		if (isPublic == false && fileAccessPermission == false) {
			return (
				<div>
					<PrimaryButton onClick={onAccessRequest}>Get Access</PrimaryButton>
				</div>
			)
		}

		return (
			<ul className="list-group list-group-flush tc-docs-list border-0">
				{files.map((item: any, fileIndex: number) => (
					<li key={`compliance-files-list-${fileIndex}`} className="list-group-item">
						<div className="row align-items-center">
							<div className="col-8 col-lg-8 col-xl-8 text-truncate" >
								<FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />
								&nbsp;&nbsp;&nbsp;{item.fileName}</div>
							<div className="col-4 col-lg-4 col-xl-4 tc-docs-action text-end">
								<span>
									<FontAwesomeIcon icon={faDownload} color={'var(--lt-primary)'} />
									<a href="javascript:void(0)" onClick={() => onFileDownload(sno, fileIndex, files.isPublic)} className="view-download-link">Download</a>
								</span>
							</div>
						</div>
					</li>
				))}
			</ul>
		)
	}

	if (securityList.length == 0) {
		return (<div>loading...</div>)
	}

	console.log(securityList, 'securityList');


	return (
		<div>
			{(securityList[selectCardIndexNo]  && securityList[selectCardIndexNo]['subData'] ? <>
				{(securityList[selectCardIndexNo]['subData']).map((item: any, sno: number) => (
					<div key={item?._id}
						ref={(el) => (cardRefs.current[sno] = el)}
						className={`${sno === selectCardSubIndexNo ? 'rp-card-active' : 'rp-card'}`} >
						<div >
							<div className={'d-flex flex-row'} style={{ fontSize: 17 }}>
								<span style={{ marginTop: 4, marginRight: 8 }}>
									<CheckMark height={18} width={18} color={'success'} />
								</span>
								<b className='capitalize-first-letter'>{item.title}</b>
								<a href="#" className='CopyLink d-none'>Copy link</a>
							</div>
							<p className='capitalize-first-letter' style={{ paddingLeft: 27 }}>{item['description']}</p>
						</div>

						<section style={{ paddingLeft: 25 }}>
							{(item?.files && item?.files.length > 0) ? (
								<FileGroupList sno={sno} files={item?.files} isPublic={item?.isPublic} />
							) : null}
						</section>
					</div>
				))}
			</> : null)}
		</div>
	)
}

export default SecutiryCardDetailPopup 