import React, { useEffect, useState } from 'react';
import './Register.css';
import { useForm, Controller, useWatch } from "react-hook-form";
import { postUsercreate } from './../../ApiServices/Auth.Service';
import { UserCreateModel } from './../../Models/AuthModel';
import { NotificationModel } from './../../Models/SecurityModel';
import { Link, useNavigate } from 'react-router-dom';
import { checkEmailExists } from './../../ApiServices/ValidationApis';
import { setAuthData } from './../../Redux/Actions/SagaAction';
import { useDispatch } from 'react-redux';
import { peopleInviteSignUpchk, peopleCreateUser } from './../../ApiServices/People.Service'

const Register = () => {
    const [errPwd, setErrors] = useState<string[]>([])
    const [errCPwd, setCpsErrors] = useState<string[]>([])
    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
    const [emailExists, setEmailExists] = useState<boolean | null>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { control, handleSubmit, formState: { errors }, watch, reset, setValue, getValues } = useForm<UserCreateModel>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
        }
    });

    const password = useWatch({ control, name: 'password', defaultValue: '' });
    const confirmPassword = useWatch({ control, name: 'confirmPassword', defaultValue: '' });

    /**@CHECKING_FIRST_AND_LAST_NAME */
    useEffect(() => {
        let firstName = capitalizeFirstLetter(watch().firstName);
        let lastName = capitalizeFirstLetter(watch().lastName);
        setValue('firstName', firstName);
        setValue('lastName', lastName);
    }, [watch().firstName, watch().lastName])

    /**
     *@ON_SUBMIT_SIGN_UP_FORM
     * Handles form submission for user creation. Validates the password confirmation, checks if the email exists,
     * creates the user, and handles login if the user is successfully created.
     * @param data - The user data for creation or update.
     */
    const onSubmit = async (data: UserCreateModel) => {


        if (confirmPassword && confirmPassword !== password) {
            return;
        }
        if (errPwd.length) {
            return;
        }
        setNotification({ type: 'none', msg: '' });

        try {
            // Check if the email already exists
            const resultEmail: any = await checkEmailExists(data.email);
            const peopleEmail: any = resultEmail.exists == false ? await peopleInviteSignUpchk(data) : ''
            //  console.log(resultEmail, 'resultEmail', peopleEmail);

            if (resultEmail?.exists || peopleEmail?.status) {
                try {
                    localStorage.clear();
                    let result: any
                    if (!peopleEmail?.status) {
                        result = await postUsercreate(data); /**@POST_API */
                    } else {
                        result = await peopleCreateUser(data);
                    }
                    if (result != undefined && result?.status) {
                        setNotification({ type: 'success', msg: `${(data.firstName ? 'Updated' : 'Added')} Successfully` });
                        await handleLogin(data.email, data.password);
                        if (!peopleEmail?.status) {
                            navigate('/provision');
                        }

                    } else {
                        setNotification({ type: 'error', msg: result?.error }); //result.message
                    }
                } catch (error) {
                    setEmailExists(null);
                    setNotification({ type: 'error', msg: 'An error occurred' });
                }
            } else {
                setNotification({ type: 'error', msg: 'Sorry, This Email is not eligible for sign up' }); //result.message
            }
        } catch {
            setNotification({ type: 'error', msg: 'An error occurred' });
        }
    };

    /**
     * Handles user login by sending a POST request to the login endpoint, 
     * and updates the local storage with user data if login is successful.
     * @param username - The email of the user.
     * @param password - The password of the user.
     */
    const handleLogin = async (username: string, password: string) => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: username, password })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/userlogin`, options);
            if (response.status === 200) {
                const result = await response.json();
                dispatch(setAuthData(result.data));
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/authData`, result.data);
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/dbName`, result.data.dbName);
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/token`, result.data.token);
                console.log("set user data to token");
                navigate("/trust-center-editor");
            }
        } catch (error) {
            // Handle errors during login
        }
    };

    /**
     * Sets an item in local storage.
     * @param key - The key under which the item will be stored.
     * @param value - The value to be stored.
     * @returns A promise that resolves when the item is successfully set.
     */
    async function setLocalStorage(key: string, value: any): Promise<void> {
        return new Promise<void>((resolve) => {
            localStorage.setItem(key, JSON.stringify(value));
            resolve();
        });
    }

    /**
     * Handles password change and validation. Updates error state based on password validity.
     * @param value - The new password value.
     * @param type - The type of password ('psw' or other).
     * @returns An array of validation errors.
     */
    const handleChangePassword = (value: string, type: string): string[] => {
        const validationErrors = validatePassword(value, type);
        if (type === 'psw') {
            setErrors(validationErrors);
        } else {
            setCpsErrors(validationErrors);
        }
        return validationErrors.length === 0 ? [] : validationErrors;
    };

    /**
     * Validates the provided password based on various criteria. Updates error state based on password validity.
     * @param password - The password to validate.
     * @param type - The type of password ('psw' or other).
     * @returns An array of validation errors.
     */
    const validatePassword = (password: string, type: string): string[] => {
        
        const errors: string[] = [];
        if (!password) {
            return ['This field is required'];
        }

        if (!/[a-zA-Z]/.test(password)) {
            errors.push("Password must contain at least one letter.");
        }

        if (!/[0-9]/.test(password)) {
            errors.push("Password must contain at least one number.");
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push("Password must contain at least one special character.");
        }

        if (password.length < 8) {
            errors.push("Password should be a minimum of 8 characters.");
        }
        if (type === 'psw') {
            setErrors(errors);
        } else {
            setCpsErrors(errors);
        }
        // console.log(errors,'ww');

        return errors;
    };

    /**
     * Capitalizes the first letter of the provided string.
     * @param string - The string to capitalize.
     * @returns The string with the first letter capitalized.
     */
    function capitalizeFirstLetter(string: string): string {
        if (!string) return string; // Handle empty strings
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <section className="register-sec">
                <div className="container">
                    <img src={process.env.PUBLIC_URL + 'assets/imgs/watermark.png'} alt="watermark" className="watermark" />
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="register">
                                <div className='text-center mt-lg-1'>
                                    <img src={process.env.PUBLIC_URL + 'assets/imgs/logo.png'} alt="Targhee security" className="logo-img" />
                                    <h1 className='mt-4'>Sign Up</h1>
                                </div>

                                <form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)} noValidate>
                                    <div className="row mt-3 mt-lg-4">
                                        <div className="col-lg-6 col-md-6 ">
                                            <div className="form-item">
                                                <Controller
                                                    name="firstName"
                                                    control={control}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="text" required id="firstName" autoComplete="off" {...field} />}
                                                />
                                                <label htmlFor="firstName">First Name*</label>
                                                {errors.firstName && (
                                                    <div className="sc-input-err-msg">{errors.firstName?.message}</div>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-item">
                                                <Controller
                                                    name="lastName"
                                                    control={control}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="text" required id="lastName" autoComplete="off"  {...field} />}
                                                />
                                                <label htmlFor="lastName">Last Name*</label>
                                                {errors.lastName && (
                                                    <div className="sc-input-err-msg">{errors.lastName?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-item">
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    rules={{
                                                        required: 'This field is required',
                                                        pattern: {
                                                            value: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                                                            message: "Invalid email address"
                                                        }
                                                    }}
                                                    render={({ field }) => <input type="text" required id="email" autoComplete="off"  {...field} />}
                                                />
                                                <label htmlFor="email">Email*</label>
                                                {errors.email && (
                                                    <div className="sc-input-err-msg">{errors.email?.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-item">
                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="password" required id="password" autoComplete="off"  {...field} onKeyUp={(e: any) => { validatePassword(e.target.value, 'psw') }} />}
                                                />
                                                <label htmlFor="password">Password*</label>
                                                {errors.password && errors.password?.message && (
                                                    <div className="sc-input-err-msg">{errors.password?.message}</div>
                                                )}

                                                {errPwd && (typeof errPwd == 'object') && (
                                                    <div className="sc-input-err-msg">
                                                        {errPwd.map((msg: any, index: number) => (
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0" key={index}>{msg}</p>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-item">
                                                <Controller
                                                    name="confirmPassword"
                                                    control={control}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="password" required id="confirmPassword" autoComplete="off"  {...field} />}
                                                />
                                                <label htmlFor="confirmPassword">Confirm Password*</label>
                                                {errors.confirmPassword && (
                                                    <div className="sc-input-err-msg">{errors.confirmPassword?.message}</div>
                                                )}
                                                {confirmPassword && confirmPassword != password && (
                                                    <div className="sc-input-err-msg">Password & confirm password should be equal.</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center my-2">
                                        <div className={`text-${notification.type}`}>{notification.msg}</div>
                                    </div>
                                    <div className="text-center my-2">
                                        <button className='btn btn-outline-secondary px-5 py-2 text-white' type="submit">Submit</button>
                                    </div>
                                </form>
                                <p className='text-white pt-3 mb-0'>Already have an account?
                                    <Link className='text-decoration-none' to="login"> Login </Link> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Register;