import { useContext, useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { S3Upload, S3Download } from '../../../../ApiServices/S3Bucket';
import { faCheckCircle, faCircleCheck, faDownload, faFilePdf, faFileShield, faImage, faInfoCircle, faNewspaper, faPlus, faSave, faShieldHalved, faTrash } from '@fortawesome/free-solid-svg-icons'
import { NotificationModel, OverviewModel, ComplianceModel, ComplianceFormModel } from '../../../../Models/SecurityModel';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckMark from '../../../../Components/CheckMark';
import { FileModel } from '../../../../Models/EditorDocumentModel';
import { compliancePredefined } from '../../../../Helper/Compliance';
import { addAndUpdateCompliance, deleteCompliance, createDownloadLogs, bulkDelete, saveHistory, getAuth } from '../../../../ApiServices/TrustCenter';
import { confirmationManager } from '../../../../Components/ConfirmationDialog/ConfirmationManager';
import IconButton from '../../../../Components/Buttons/IconButton';
import PrimaryButton from '../../../../Components/Buttons/PrimaryButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactContext from "../../../../ApiServices/ReactContext/ReactContext";
import History from "../../History";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { getTCenterComplianceList } from './../../../../Redux/Actions/SagaAction';

function debounce<T extends (...args: any[]) => Promise<void>>(
    func: T,
    wait: number,
    stateMap: Map<number, { timeout: ReturnType<typeof setTimeout> | null, isDebounced: boolean }>
) {
    return function (this: any, ...args: Parameters<T>): Promise<void> {
        return new Promise((resolve, reject) => {
            const [index, ...rest] = args;

            if (!stateMap.has(index)) {
                stateMap.set(index, { timeout: null, isDebounced: true });

                let isNewState = true;
                console.log('no value found Setting Value', index, isNewState);

                func.apply(this, [index, ...rest, isNewState])
                    .then(resolve)
                    .catch(reject);
            } else {
                const state = stateMap.get(index)!;

                // Always clear the previous timeout if it exists
                if (state.timeout) {
                    clearTimeout(state.timeout);
                }
                let isNewState = false;
                // Set a new timeout to call the function after the wait period
                console.log('value Found', index, isNewState);
                state.timeout = setTimeout(() => {
                    func.apply(this, [index, ...rest, isNewState])
                        .then(resolve)
                        .catch(reject);
                }, wait);

                // Update the stateMap with the new timeout
                stateMap.set(index, state);
            }
        });
    };
}

interface Props {
    complianceList?: any[];
    show: boolean;
    onHide: () => void;
    getComplianceList: () => void;
    selectedObj?: ComplianceModel;
}

const ComplianceEditModel = ({ show, onHide, getComplianceList, selectedObj }: Props) => {
    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [selectedIconCompliance, setSelectedIconCompliance] = useState<number>(-1);
    const [requestedComplienceIndex, setRequestedComplienceIndex] = useState<number>(-1);
    const [modalShow, setModalShow] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const cardRefs: any = useRef([]);
    const context = useContext(ReactContext);
    const [updateTrigger, setUpdateTrigger] = useState(0); // state to trigger re-render
    const tempDataRef = useRef<any[]>([]);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const setRef = (index: number, element: HTMLInputElement | null) => {
        inputRefs.current[index] = element;
    };

    const dispatch = useDispatch();

    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;
    const { control, handleSubmit, formState: { errors }, setValue, watch: watch, reset } = useForm<ComplianceFormModel>({
        defaultValues: {
            items: [{ subTitle: '', description: '', icon: '', files: [], _id: '', isPublic: false, isUpdated: false }]
        }
    });
    const { fields, append, remove, update } = useFieldArray({ control, name: 'items' });
    const [checkMarkStates, setCheckMarkStates] = useState<{ [key: number]: { color: string, refresh: boolean, trigger: boolean } }>({});

    const stateMap = useRef(new Map<number, { timeout: ReturnType<typeof setTimeout> | null, isDebounced: boolean }>()).current;
    const complianceList: any = useSelector((state: any) => state.AppReducer.tCenterComplianceList);
    const isLoading: any = useSelector((state: any) => state.AppReducer.isLoading);

    useEffect(() => {
        handleClose();
    }, [onHide]);

    useEffect(() => {
        // Ensure that checkMarkStates are reset when the Offcanvas opens
        if (show) {
            dispatch(getTCenterComplianceList(false));
        }
    }, [show]);

    useEffect(() => {
        if(show && !isLoading){
            const fetchData = async () => {
                console.log(tempDataRef.current.length, 'tempDataRef.current.length , cl', complianceList.length);
                
                if(tempDataRef.current.length==0){
                    await editCompliance();
                    scrollToIndex();
                }
                if (complianceList){
                    console.log(complianceList)
                    complianceList.forEach((item: any, index: number) => {
                        resetCheck(index);
                    });
                }
            }
    
            fetchData();
        }
    }, [show, complianceList, isLoading]);


    const resetCheck = (index: number) => {
        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'success', refresh: true, trigger: !checkMarkStates[index]?.trigger }
        }));
    };


    const clearDebounceState = () => {
        stateMap.forEach((state, key) => {
            if (state.timeout) {
                clearTimeout(state.timeout);
            }
        });
        stateMap.clear();
    };

    const handleClose = () => {
        clearDebounceState();
    };

    /**
     * @OVERVIEW_OPEN_POPUP_FOR_EDIT
     * Resets the form with data from `complianceList` for editing or adds a default item.
     * Updates `tempDataRef.current` with the current form data.
     */
    const editCompliance = async () => {
        console.log(complianceList?.length, 'complianceList?.length');
        
        setNotification({ type: 'none', msg: `` });
        if (complianceList && complianceList?.length) {
            reset({
                items: []
            });
            tempDataRef.current = [];
            for (let i = 0; i < complianceList?.length; i++) {
                if (complianceList[i]['_id']) {
                    const config: any = {
                        _id: complianceList[i]['_id'],
                        icon: complianceList[i]['icon'],
                        files: (complianceList[i]['files'] || []),
                        subTitle: complianceList[i]['subTitle'],
                        description: complianceList[i]['description'],
                        isPublic: complianceList[i]['isPublic'],
                        isUpdated: false
                    }
                    append(config);
                    tempDataRef.current.push(config);
                }
            }
        }
        else if (complianceList?.length == 0) {
            reset({
                items: [{ subTitle: "", description: "", icon: "", files: [], _id: "", isPublic: false }]
            });
            changeToGrayNoDebounce(0, "", stateMap);


        }
    }


    /**
    * @Scrolls to the index of `selectedObj` in `complianceList`.
    * Uses smooth scrolling behavior after a delay.
    */
    const scrollToIndex = () => {
        setTimeout(() => {
            if (selectedObj && selectedObj['_id'] && complianceList && complianceList.length) {
                for (let i = 0; i < complianceList.length; i++) {
                    if (complianceList[i]['_id'] == selectedObj['_id']) {
                        if (cardRefs.current[i]) {
                            cardRefs.current[i].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }
                    }
                }
            }
        }, 1000);
    };


    /**
    * Toggles the compliance popup modal.
    * @param val - Boolean value to show/hide the modal.
    */
    const toggleCompliancePopup = (val: boolean) => {
        setModalShow(val);
    }

    /**
     * Handles the selection of an icon for a compliance item.
     * Shows the compliance popup if 'pick-icon' is selected.
     * @param item - Selected icon name or action.
     * @param index - Index of the compliance item.
     */
    const onSelectedIcon = (item: any, index: number) => {
        if (item === 'pick-icon') {
            toggleCompliancePopup(true)
            setRequestedComplienceIndex(index)
        } else {
            setSelectedIconCompliance(index);
        }
    }

    /**
     * Finalizes the selected icon for a compliance item.
     * Closes the compliance popup and updates the form with selected icon details.
     */
    const onFinalIconSeletected = () => {
        if (selectedIconCompliance != -1) {
            toggleCompliancePopup(false);
            let obj: any = compliancePredefined[selectedIconCompliance];
            setValue(`items.${requestedComplienceIndex}.subTitle`, obj.subTitle);
            update(requestedComplienceIndex, { ...watch().items[requestedComplienceIndex], subTitle: obj.subTitle, icon: obj.iconName })
            changeToGrayNoDebounce(requestedComplienceIndex, "subTitle", stateMap);
        }
    }

    /**
    * @SUBMIT_COMPLIANCE_FORM
    * Handles the form submission for compliance items.
    * Logs the form data to console.
    * @param data - Form data submitted.
    */
    const onSubmit2: SubmitHandler<ComplianceFormModel> = (data) => {
        console.log(data);
    }

    /**
     * @SUBMIT_COMPLIANCE_FORM_MANUALLY
     * Submits the compliance form programmatically.
     * Adds or updates compliance item based on form data.
     * @param index - Index of the compliance item to submit.
     */
    const submitFormProgrammatically = async (index: number) => {
        setCurrentIndex(index);
        setNotification({ type: 'none', msg: '' });
        // setSubmittedIndex(index);

        handleSubmit(onSubmit2)();
        const obj: ComplianceModel = { ...watch().items[index] };
        const _id: string = (obj['_id'] || '');
        delete obj['_id'];
        // console.log('🌟🌟🌟🌟');

        if (obj.subTitle && obj.subTitle.trim().length > 30) {
            setNotification({ type: 'error', msg: 'Title field should be maximum 30 characters.' });
            return;
        }

        if (obj.description && obj.description.trim() && obj.subTitle && obj.subTitle.trim() && obj.icon) {
            addHistory(_id, obj);
            const result = await addAndUpdateCompliance(_id, obj);
            if (result && result.status == true) {

                setNotification({ type: 'success', msg: `${(_id ? 'Updated' : 'Added')} Successfully` })
                update(index, { ...obj, _id: result.data['_id'], isUpdated: false });
                dispatch(getTCenterComplianceList(false));
                getComplianceList();
                reload('a');
                resetCheck(index);
            } else {
                setNotification({ type: 'error', msg: result.message });
            }
        } else {
            if (!obj['subTitle']) {
                setNotification({ type: 'error', msg: 'Title field is required.' });
            } else if (!obj['description']) {
                setNotification({ type: 'error', msg: 'Description field is required.' });
            } else if (!obj['icon']) {
                setNotification({ type: 'error', msg: 'Please select icon.' });
            }
        }
    };

    /**
    * @ADD_COMPLIANCE_HISTORY
    * Adds a compliance history entry for auditing.
    * @param _id - ID of the compliance item.
    * @param obj - Updated compliance item object.
    */
    const addHistory = (_id: string, obj: any) => {
        const auth: any = getAuth();
        let reqBody: any = [{
            ...obj,
            firstName: auth.firstName,
            lastName: auth.lastName,
            email: auth.email,
            userId: auth._id,
            status: (_id ? 'update' : 'create'),
            type: 'compliance',
        }];

        if (reqBody.length) {
            const result: any = saveHistory(reqBody);
        }
    }

    /**
    * @DELETE_COMPLIANCE 
    * Deletes a compliance item from the list.
    * @param index - Index of the compliance item to delete.
    */
    const deleteComplianceCard = async (index: number) => {
        let files: any = watch().items[index]?.files || [];
        setCurrentIndex(index);
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete?',
            onConfirm: async () => {
                let _id: string = watch().items[index]?._id || '';
                let files: any = watch().items[index]?.files || [];
                const result: any = await deleteCompliance(_id);
                if (result) {
                    bulkDelete(files, "mytest1-docs");
                    setNotification({ type: 'none', msg: '' });
                    remove(index);
                    tempDataRef.current.splice(index, 1);
                    getComplianceList();
                    reload('a');
                    // submitFormProgrammatically(index); /**@AUTOSAVE_FILE */
                    // setShowDialog(false);
                }
            },
            onCancel: () => {
                // Handle the cancel action
                console.log('Delete canceled');
            },
        });
    }

    /**
     * Adds a new compliance item to the form.
    */
    const addNew = () => {
        changeToGrayNoDebounce(fields.length, "", stateMap);
        setCurrentIndex(-1);
        setNotification({ type: 'none', msg: '' });
        append({ subTitle: "", description: "", icon: "", files: [], _id: "", isPublic: false, isUpdated: false });
        tempDataRef.current.push({ subTitle: "", description: "", icon: "", files: [], _id: "", isPublic: false })
    }

    /**
     * Opens the file picker for selecting a file to upload.
     * @param index - Index of the compliance item to upload a file for.
     */
    const chooseFile = (index: number) => {
        if (fileInputRefs.current[index]) {
            fileInputRefs.current[index]?.click();
        }
    };

    /**
     * @ON_FILE_UPLOAD
     * Handles the upload of a file for a compliance item.
     * @param event - Change event containing the uploaded file.
     * @param index - Index of the compliance item to upload the file for.
     */
    const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.files) {
            setIsFileLoading(true);
            const obj: any = { ...watch().items[index] };
            const _id = obj._id;
            delete obj._id;
            const newFile = new FileModel(event.target.files[0].name);
            obj.files.push(newFile);
            const result = await addAndUpdateCompliance(_id, obj);
            if (result && result.status && result.data && result.data?.files && result?.data?.files.length) {
                let fileId: string = result.data.files[result.data.files.length - 1]._id;
                S3Upload({ file: event.target.files[0], fileId: fileId, bucketName: 'mytest1-docs', folderName: 'compliance' }).then((res: any) => {
                    if (res && res['status'] == 200) {
                        setValue(`items.${index}._id`, result?.data?._id);
                        setValue(`items.${index}.files`, result?.data?.files);
                        update(index, { ...watch().items[index], _id: result?.data?._id, files: result?.data?.files, isUpdated: false });
                        setNotification({ type: 'success', msg: `${(_id ? 'Updated' : 'Added')} Successfully` });
                        setIsFileLoading(false);
                        submitFormProgrammatically(index); /**@AUTOSAVE_FILE */
                        resetCheck(index);
                    } else {
                        console.log('why else', res, res['message'] == "File uploaded successfully");
                        fileUploadFailedHandle(obj, newFile, index, _id);
                    }

                }).catch((err: any) => {
                    fileUploadFailedHandle(obj, newFile, index, _id);
                    setIsFileLoading(false);
                })
            } else {
                setIsFileLoading(false);
                setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
            }
        }
    }

    /**
     * Handles the case when a file upload fails by removing the failed file and updating the corresponding compliance object.
     * 
     * @param {any} obj - The object containing the files array.
     * @param {any} newFile - The new file object that was attempted to be uploaded.
     * @param {number} index - The index of the item in the items array.
     * @param {string} _id - The ID of the compliance object to be updated.
     */
    const fileUploadFailedHandle = async (obj: any, newFile: any, index: number, _id: string) => {
        if (obj.files.length) {
            const fileName = obj.files[obj.files.length - 1]['fileName'];
            if (fileName == newFile.fileName) {
                obj.files.pop();
                const result = await addAndUpdateCompliance(_id, obj);
                setValue(`items.${index}._id`, result?.data?._id);
                setValue(`items.${index}.files`, result?.data?.files);
                update(index, { ...watch().items[index], _id: result?.data?._id, files: result?.data?.files, isUpdated: false });
            }
        }
    }

    /**
     * Handles the download of a file for a compliance item.
     * @param index - Index of the compliance item.
     * @param fileIndex - Index of the file within the compliance item.
     * @param isPublic - Boolean indicating if the file is public.
    */
    const onFileDownload = async (index: number, fileIndex: number, isPublic: boolean) => {
        const obj: any = { ...watch().items[index] };
        let fileObj: any = obj.files[fileIndex];
        S3Download({ fileKey: fileObj._id, bucketName: 'mytest1-docs', newFileName: fileObj.fileName }).then((res: any) => {
            createDownloadLogs([fileObj.fileName], 'compliance', 'editor', isPublic);
        }).catch((err: any) => {
            console.log(err, 'err file');
        })
    };

    /**
     * Handles the deletion of a file for a compliance item.
     * @param index - Index of the compliance item.
     * @param fileIndex - Index of the file within the compliance item.
    */
    const onFileDelete = async (index: number, fileIndex: number) => {
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete?',
            onConfirm: async () => {
                const obj: any = { ...watch().items[index] };
                const _id = obj._id;

                /**@DELETE_FROM_S3_BUCKET*/
                let _deleteFiles: any = obj?.files[fileIndex] || [];
                bulkDelete([{ ..._deleteFiles }], "mytest1-docs");

                obj['files'] = obj.files.filter((item: any, sno: number) => sno != fileIndex);
                const result = await addAndUpdateCompliance(_id, obj);
                if (result && result.status) {
                    setValue(`items.${index}.files`, obj['files']);
                    update(index, { ...watch().items[index], files: obj['files'] });
                    submitFormProgrammatically(index); /**@AUTOSAVE_FILE */
                } else {
                    setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
                }
            },
            onCancel: () => {
                console.log('Delete canceled');
            },
        });
    }

    /**
     * Updates the `isPublic` field of a compliance item.
     * @param index - Index of the compliance item.
     * @param value - Boolean value indicating if the item is public.
     */
    const updateItem = (index: number, value: boolean) => {
        changeToGrayNoDebounce(index, "isPublic/Private", stateMap);
        update(index, { ...watch().items[index], isPublic: value });

    };


    /**
     * Monitors changes in form fields and updates the `isUpdated` status accordingly.
     * @param index - Index of the compliance item.
     * @param idName - ID or name associated with the form field.
     */
    const formChangesStatus = (index: number, idName: string) => {
        const obj: any = { ...watch().items[index] };
        if (!obj['_id']) return;
        if (obj['subTitle'] != tempDataRef.current[index]['subTitle'] || obj['description'] != tempDataRef.current[index]['description']) {
            setValue(`items.${index}.isUpdated`, true);
            if (!obj['isUpdated']) {
                update(index, { ...obj, isUpdated: true });
                setTimeout(() => {
                    markFocus(`${idName}-${index}`);
                }, 0);
            }
        } else {
            update(index, { ...obj, isUpdated: false });
            setTimeout(() => {
                markFocus(`${idName}-${index}`);
            }, 0);
        }
    }

    /**
     * Marks focus on an input field by ID and sets the cursor at the end of the text.
     * @param id - ID of the input field.
     */
    const markFocus = (id: string) => {
        const el = document.getElementById(`${id}`) as HTMLInputElement | HTMLTextAreaElement;
        el?.focus();
        el.setSelectionRange(el.value.length, el.value.length);
    }

    // Define the changeToGray function
    const changeToGrayDebounce = async (index: number, id: string, isNewState: boolean): Promise<void> => {
        return new Promise((resolve) => {
            const obj: any = { ...watch().items[index] };

            if (obj['_id']) {
                if (isNewState) {
                    setCheckMarkStates(prev => ({
                        ...prev,
                        [index]: { color: 'default', refresh: isNewState, trigger: !checkMarkStates[index]?.trigger }
                    }));

                    // setCheckMarkStates(prev => ({
                    //     ...prev,
                    //     [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
                    // }));
                    console.log('changeToGray', index, id, obj['_id']);
                } else {
                    setCheckMarkStates(prev => ({
                        ...prev,
                        [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
                    }));
                    console.log('changeToGray else', index, id);
                }
            }

            resolve();
        });
    };

    const changeToGray = debounce(changeToGrayDebounce as any, 3000, stateMap);

    const changeToGrayNoDebounce = (index: number, id: string, stateMap: any) => {
        console.log('changeToGray2', index, id);

        if (!stateMap.has(index)) {
            stateMap.set(index, { timeout: null, isDebounced: true });

            setCheckMarkStates(prev => ({
                ...prev,
                [index]: { color: 'default', refresh: true, trigger: !checkMarkStates[index]?.trigger }
            }));

        } else {
            setCheckMarkStates(prev => ({
                ...prev,
                [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
            }));
        }

    };

    return (
        <div>
            <Offcanvas show={show} placement={'end'} style={{ minWidth: 730 }} onHide={onHide}>
                <form method="post" autoComplete={'off'} className="h-100" onSubmit={handleSubmit(onSubmit2)}>
                    <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ddd' }}>
                        <Offcanvas.Title>
                            <div className="title-header-dynamic">
                                <FontAwesomeIcon icon={faFileShield} color="var(--lt-primary)" />
                                <span>Compliance</span>
                                {/* <button type="button" style={{ position: 'absolute', right: 60, top: 20, fontSize: 16, color: 'var(--lt-primary)' }} onClick={() => setShowHistory(true)}>History</button> */}
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pe-0 pt-0 h-100 ps-0">
                        <div className="card" style={{ height: 'calc(100% - 10px)', maxHeight: 'calc(100% - 10px)', overflowY: 'hidden', minWidth: 500 }}>
                            <div className="card-body" style={{ padding: '15px', overflowY: 'auto', maxHeight: (window.innerHeight - 65) }}>
                                {fields.map((field: any, index: number) => (
                                    <section ref={(el) => (cardRefs.current[index] = el)} key={field.id} className="card sc-card-list shadow-sm pb-3" style={{}}>
                                        <div className="form-group d-flex flex-row align-items-center">
                                            <div className="col-6 col-md-6 col-lg-6">
                                                <span style={{ display: 'inline-block' }}>
                                                    <CheckMark
                                                        width={20}
                                                        height={20}
                                                        color={checkMarkStates[index]?.color as 'success' | 'default' | 'error' | 'primary' | 'warn' | 'light'}
                                                        refresh={checkMarkStates[index]?.refresh ? true : false}
                                                        trigger={checkMarkStates[index]?.trigger}
                                                        index={index}
                                                    />
                                                    &nbsp;&nbsp;&nbsp;
                                                </span>

                                                <Controller
                                                    name={`items.${index}.subTitle`}
                                                    control={control}
                                                    defaultValue={field.subTitle}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="text" className="dotted-input" placeholder="Enter Title" id={`subTitle-${index}`} {...field} onKeyUp={(e) => {
                                                        field.onChange(e);
                                                        changeToGray(index, "subTitle");
                                                    }} />}
                                                />


                                                {field?.icon ? (
                                                    <span className="selected-icon">
                                                        <img src={process.env.PUBLIC_URL + 'assets/imgs/compliance/' + field?.icon} />
                                                    </span>
                                                ) : null}

                                                <span onClick={() => onSelectedIcon('pick-icon', index)} className="select-icon pointer">Select Icon</span>
                                                {errors.items && errors.items?.[index]?.subTitle && (
                                                    <div style={{ marginLeft: 32 }} className="sc-input-err-msg">{errors.items?.[index]?.message}</div>
                                                )}
                                            </div>

                                            <div className="col-3 col-md-3 col-lg-3 text-end">
                                                <div className="publc-prit-btns">
                                                    <button type="button" className={`btn-sm ${field.isPublic ? 'activeTwo' : ''}`} onClick={() => updateItem(index, true)}>Public</button>
                                                    <button type="button" className={`btn-sm ${field.isPublic ? '' : 'activeTwo'}`} onClick={() => updateItem(index, false)}>Private</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3 d-flex flex-row align-items-center">
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <div className="form-group" style={{ width: '100%' }}>
                                                <label><b>Description</b></label>
                                                <Controller
                                                    name={`items.${index}.description`}
                                                    control={control}
                                                    defaultValue={field.description}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <textarea className="form-control mt-1" id={`description-${index}`} rows={6} {...field} onKeyUp={(e) => {
                                                        field.onChange(e);
                                                        changeToGray(index, "description");
                                                    }} ></textarea>}
                                                />
                                                {errors.items && errors.items?.[index]?.description && (
                                                    <div className="sc-input-err-msg">{errors.items?.[index]?.message}</div>
                                                )}
                                            </div>
                                        </div>


                                        {field?._id ? (
                                            <section>
                                                <div style={{ paddingLeft: 28 }}>
                                                    <div className="row align-items-center mt-3">
                                                        <div className="col-8 col-md-8 col-lg-8">
                                                            Upload files
                                                        </div>
                                                        <div className="col-4 col-md-4 col-lg-4 text-end">
                                                            <input type="file" accept="image/jpeg,image/png,application/pdf" ref={(el) => (fileInputRefs.current[index] = el)} onChange={(e) => onFileUpload(e, index)} id="documentFileInput" style={{ opacity: 0, width: 0, height: 0 }} />

                                                            <div>
                                                                <IconButton varient={'Text'} className="position-relative" style={{ height: 30, width: 30, borderRadius: 100, }} type="button" onClick={() => chooseFile(index)}>
                                                                    <img src={process.env.PUBLIC_URL + 'assets/imgs/upload-icon.png'} alt="upload-icon" style={{ width: 25, marginTop: 9, marginRight: 2 }} />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(field?.files && field?.files.length > 0) ? (
                                                        <ul className="list-group list-group-flush tc-docs-list">
                                                            {field?.files.map((item: any, sno: number) => (
                                                                <li key={`compliance-files-list-${sno}`} className="list-group-item">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8 col-lg-8 col-xl-8 text-truncate" >
                                                                            <FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />
                                                                            &nbsp;&nbsp;&nbsp;{item.fileName}</div>
                                                                        <div className="col-4 col-lg-4 col-xl-4 tc-docs-action text-end">
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faTrash} color={'var(--lt-danger)'} />
                                                                                <a href="#" onClick={() => onFileDelete(index, sno)} className="view-download-link text-danger">Delete</a>
                                                                            </span>
                                                                            <span>&nbsp;&nbsp;</span>
                                                                            <span>
                                                                                <FontAwesomeIcon icon={faDownload} color={'var(--lt-primary)'} />
                                                                                <a href="#" onClick={() => onFileDownload(index, sno, field.isPublic)} className="view-download-link">Download</a>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : null}
                                                </div>
                                            </section>
                                        ) : null}


                                        <Row className="mt-1">
                                            <Col className="text-danger capitalize-first-letter ps-5">
                                                <small>
                                                    {index == currentIndex && notification.type == 'error' ? notification.msg : ''}
                                                </small>
                                            </Col>
                                            <Col className="text-end">
                                                <IconButton onClick={() => deleteComplianceCard(index)} style={{ height: 25, paddingTop: 12, marginRight: 25, position: 'relative' }} type="button">
                                                    <span style={{ color: 'var(--lt-danger)' }}>Delete</span>
                                                </IconButton>
                                                <PrimaryButton onClick={() => submitFormProgrammatically(index)} type="button">
                                                    <FontAwesomeIcon style={{ fontSize: 13 }} icon={faSave} color="var(--lt-light)" />&nbsp;&nbsp;{field?._id ? 'Update' : 'Save'}
                                                </PrimaryButton>
                                            </Col>
                                        </Row>
                                    </section>
                                ))}

                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div />
                                    <IconButton varient="Circle" style={{ height: 30, width: 30, backgroundColor: 'var(--lt-primary)' }} onClick={() => addNew()}>
                                        <FontAwesomeIcon style={{ fontSize: 18 }} icon={faPlus} color="var(--lt-light)" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <History show={showHistory} type="compliance" onHide={() => setShowHistory(false)} />
                    </Offcanvas.Body>
                </form>
            </Offcanvas>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
            >
                <Modal.Body>
                    <div className='row mt-4'>
                        {compliancePredefined.map((item: any, index: number) => {
                            return (
                                <div onClick={() => onSelectedIcon(item, index)} className={`col-6 col-md-3 text-center mousehover-effect ${selectedIconCompliance == index ? 'active' : ''}`} key={`compliance-${item.id}${index}`}>
                                    <img src={process.env.PUBLIC_URL + '/assets/imgs/compliance/' + item?.icon} style={{ width: 60, height: 60 }} alt={`Avatar ${item?.id}`} />
                                    <p style={{ color: 'var(--lt-primary)', fontSize: 15 }}>
                                        <div className='circle-tick'>
                                            <FontAwesomeIcon icon={faCircleCheck} color={selectedIconCompliance == index ? 'var(--lt-success)' : 'var(--lt-gray)'} />
                                        </div>
                                        {item.subTitle}</p>
                                </div>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ textAlign: 'right', borderTop: 'none' }}>
                    <Button variant="secondary" onClick={() => toggleCompliancePopup(false)}>Close</Button>
                    <Button onClick={onFinalIconSeletected}>Select</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ComplianceEditModel;