export const compliancePredefined:any = [
    { subTitle: "CCPA", iconName: "ccpa.svg", icon: process.env.PUBLIC_URL + 'ccpa.svg' },
    { subTitle: "EU-US DPF", iconName: "eu_us_dpf.webp", icon: process.env.PUBLIC_URL + 'eu_us_dpf.webp', },
    { subTitle: "GDPR", iconName: "gdpr.svg", icon: process.env.PUBLIC_URL + 'gdpr.svg', },
    { subTitle: "ISO 22301", iconName: "iso.svg", icon: process.env.PUBLIC_URL + 'iso.svg', },
    { subTitle: "ISO 27001", iconName: "iso.svg", icon: process.env.PUBLIC_URL + 'iso.svg', },
    { subTitle: "ISO 27001 SoA", iconName: "iso.svg", icon: process.env.PUBLIC_URL + 'iso.svg', },
    { subTitle: "ISO 27018", iconName: "iso.svg", icon: process.env.PUBLIC_URL + 'iso.svg', },
    { subTitle: "PCI DSS", iconName: "pci.png", icon: process.env.PUBLIC_URL + 'pci.png', },
    { subTitle: "PIPEDA", iconName: "pipeda.svg", icon: process.env.PUBLIC_URL + 'pipeda.svg', },
    { subTitle: "SOC 2", iconName: "soc.png", icon: process.env.PUBLIC_URL + 'soc.png', },
];