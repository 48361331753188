
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import RightSideDialog from './../../../Components/RightSideDialog';
import IconButton from './../../../Components/Buttons/IconButton';
import { faCheckCircle, faCircleCheck, faDownload, faFilePdf, faFileShield, faInfoCircle, faNewspaper, faPlus, faSave, faShieldHalved, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import CheckMark from './../../../Components/CheckMark';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import './Styles.css';
import { NotificationModel, OverviewModel, ComplianceModel, ComplianceFormModel } from './../../../Models/SecurityModel';
import { getOverview, addAndUpdateOverview, getComplianceData, addAndUpdateCompliance, deleteCompliance, getAuth, saveHistory } from './../../../ApiServices/TrustCenter';
import ComplianceEditModel from './ComplianceEditModel';
import ReactContext from './../../../ApiServices/ReactContext/ReactContext';
import ComplienceRightSidePopup from './../ComplienceRightSidePopup';
import GetAccessPermission from './../../../TrustCenter/GetAccessPermission';
import History from './../History';

interface CompliencePopup {
    show: boolean;
    index: number;
}

const OverviewComlianceTruestCenter = () => {
    const [overviewDialog, setOverviewDialog] = useState(false);
    const [complianceDialog, setComplianceDialog] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [overviewInfo, setOverviewInfo] = useState<OverviewModel>({ subTitle: "", description: "", _id: "" });
    const [complianceList, setComplianceList] = useState<ComplianceModel[]>([]);
    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
    const [compliancePopup, setCompliancePopup] = useState<CompliencePopup>({ show: false, index: 0 });
    const [isGetAccess, setIsGetAccess] = useState(false);
    const { control: control1, handleSubmit: handleSubmitForm1, formState: { errors }, watch, reset } = useForm<OverviewModel>({
        defaultValues: {
            subTitle: "",
            description: "",
            _id: ""
        }
    });

    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;

    useEffect(() => {
        getOverviewList();
        getComplianceList();
    }, []);



    /**@OVERVIEW_LIST */
    const getOverviewList = async () => {
        const result: any = await getOverview();
        console.log(result, 'result');
        if (result && result.data && result.data.length) {
            setOverviewInfo({
                _id: result.data[0]['_id'],
                subTitle: result.data[0]['subTitle'],
                description: result.data[0]['description']
            });
        }
    }

    /**@OVERVIEW_OPEN_POPUP_FOR_EDIT */
    const editOverview = () => {
        setNotification({ type: 'none', msg: `` })
        reset({
            _id: overviewInfo['_id'] || '',
            subTitle: overviewInfo['subTitle'] || '',
            description: overviewInfo['description'] || ''
        });
        setOverviewDialog(true);
    }

    /**@GET_GET_COMPLIANCE_LIST */
    const getComplianceList = async () => {
        const result: any = await getComplianceData();
        let temp: ComplianceModel[] = [];
        if (result && result.length) {
            for (let i = 0; i < result.length; i++) {
                temp.push({
                    _id: result[i]['_id'],
                    icon: result[i]['icon'],
                    files: result[i]['files'],
                    subTitle: result[i]['subTitle'],
                    description: result[i]['description'],
                    isPublic: result[i]['isPublic'],
                })
            }
        }

        setData({ ...data, complianceList: temp });
        setComplianceList(temp);
    }

    const handleToggleDialog = () => {
        setOverviewDialog(!overviewDialog);
    };

    /**@SUBMIT_OVERVIEW_FORM */
    const onSubmit: SubmitHandler<OverviewModel> = async (data) => {
        setNotification({ type: 'none', msg: '' });
        setLoading(true);
        const watchedValues = watch();
        const obj: any = { ...watchedValues };
        const _id = obj._id ? obj._id : overviewInfo['_id'];
        if (obj?._id !== undefined) delete obj._id;
        addHistory(_id, obj);
        const result: any = await addAndUpdateOverview(_id, obj); /**@UPADTE_API */
        if (result && result.status == true) {
            setNotification({ type: 'success', msg: `Overview Updated Successfully` })
            setLoading(false);
            getOverviewList();
        } else {
            setLoading(false);
            setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
        }
    }

    /**@ADD_COMPLIANCE_HISTORY */
    const addHistory = (_id: string, obj: any) => {
        const auth: any = getAuth();
        let reqBody: any = [{
            ...obj,
            firstName: auth.firstName,
            lastName: auth.lastName,
            email: auth.email,
            userId: auth._id,
            status: (_id ? 'update' : 'create'),
            type: 'overview',
        }];

        if (reqBody.length) {
            saveHistory(reqBody).then((res: any) => {
                if (res) {

                }
            });
        }
    }

    /**@OVERVIEW_OPEN_POPUP_FOR_EDIT */
    const editCompliance = () => {
        setComplianceDialog(true);
    }

    const openCompliancePopup = (index: number) => {
        setData({ ...data, complianceList: complianceList });
        setCompliancePopup({ show: true, index: index });
    }

    const onAccessRequestPermision = () => {
        setIsGetAccess(true);
        setCompliancePopup({ show: false, index: 0 })
    }


    return (
        <>
            <div className="row mb-4">
                <div className="col-lg-6">
                    <div className="card card-A-oc h-100">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>
                                    <div className="d-flex align-items-center header ">
                                        <FontAwesomeIcon icon={faNewspaper} color="#014181" />
                                        <span className="ml-3" style={{ color: "#014181" }}>Overview</span>
                                    </div>
                                </h2>
                                <IconButton onClick={() => editOverview()} style={{ height: 25 }} className="doc-edit-icon ml-auto">
                                    <span>Edit&nbsp;&nbsp;</span>
                                    <img style={{ width: 16, height: 'auto' }} src={process.env.PUBLIC_URL + 'assets/imgs/edit.png'} />
                                </IconButton>
                            </div>

                            <div className="pt-0">
                                <p style={{ color: "var(--lt-primary)", fontWeight: 600 }}>{overviewInfo.subTitle}</p>
                                <p className='povertxt' style={{ whiteSpace: 'pre-wrap' }}>{overviewInfo.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-B-oc h-100">
                        <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2>
                                    <div className="d-flex align-items-center header">
                                        <FontAwesomeIcon icon={faShieldHalved} color="#014181" />
                                        <span className="ml-3" style={{ color: "#014181" }}>Compliance</span>
                                    </div>
                                </h2>
                                <IconButton onClick={() => editCompliance()} style={{ height: 25 }} className="doc-edit-icon ml-auto">
                                    <span>Edit&nbsp;&nbsp;</span>
                                    <img style={{ width: 16, height: 'auto' }} src={process.env.PUBLIC_URL + 'assets/imgs/edit.png'} />
                                </IconButton>
                            </div>

                            <div className='row mt-4'>
                                {complianceList.map((item: any, index: number) => {
                                    return (
                                        <div onClick={() => openCompliancePopup(index)} className='col-6 col-md-3 text-center pointer' key={`compliance-${item.subTitle}${index}`}>
                                            <img src={process.env.PUBLIC_URL + '/assets/imgs/compliance/' + item?.icon} style={{ width: 60, height: 60 }} alt={`Avatar ${item?.subTitle}`} />
                                            <p className='ellipsis-2l' style={{ color: 'var(--lt-primary)', fontSize: 15 }}>
                                                <div className='circle-tick'>
                                                    <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                                </div>
                                                {item.subTitle}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Offcanvas show={overviewDialog} placement={'end'} style={{ minWidth: 730 }} onHide={handleToggleDialog}>
                <form method="post" autoComplete={'off'} className="h-100" onSubmit={handleSubmitForm1(onSubmit)}>
                    <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ddd' }}>
                        <Offcanvas.Title>
                            <div className="title-header-dynamic">
                                <FontAwesomeIcon icon={faFileShield} color="var(--lt-primary)" />
                                <span>Overview</span>
                                {/* <button type="button" onClick={() => setShowHistory(true)} style={{ position: 'absolute', right: 60, top: 20, fontSize: 16, color: 'var(--lt-primary)' }}>History</button> */}
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pe-0 pt-0 h-100 ps-0">
                        <div className="card" style={{ height: 'calc(100% - 10px)', maxHeight: 'calc(100% - 10px)', overflowY: 'hidden', minWidth: 500 }}>
                            <div className="card-body" style={{ padding: '15px', overflowY: 'auto', maxHeight: 'calc(100% - 88px)' }}>
                                <div className="card sc-card-list shadow-sm pb-3" style={{}}>
                                    <div className="form-group d-flex flex-row align-items-center">
                                        <div className="col-8 col-md-9 col-lg-9">
                                            <span style={{ display: 'inline-block' }}>
                                                {isLoading ? (
                                                    <CheckMark height={20} width={20} color={'default'} />
                                                ) : (<CheckMark height={20} width={20} color={(notification.type === 'success' ? 'success' : (overviewInfo._id ? 'success' : 'default'))} />)}
                                                &nbsp;&nbsp;&nbsp;
                                            </span>

                                            <Controller
                                                name={`subTitle`}
                                                control={control1}
                                                defaultValue={''}
                                                rules={{
                                                    required: 'This field is required',
                                                    maxLength: {
                                                        value: 60,
                                                        message: 'The sub title length should be maximum 60 characters',
                                                    }
                                                }}
                                                render={({ field }) => <input type="text" className="dotted-input"
                                                    {...field}
                                                   onBlur={(e:any) => {
                                                        const trimmedValue = e.target.value.trim(); // Trim whitespace
                                                        field.onChange(trimmedValue);
                                                    }}
                                                    onPaste={(e:any) => {
                                                        setTimeout(() => {
                                                            field.onChange(e.target?.value); // Update the field value
                                                        }, 0); // Timeout to ensure pasted value is captured
                                                    }}
                                                    placeholder="Enter Title"
                                                />}
                                            />
                                            {errors?.subTitle && (
                                                <div style={{ marginLeft: 32 }} className="sc-input-err-msg">{errors.subTitle?.message}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-4 d-flex flex-row align-items-center">
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <div className="form-group" style={{ width: '100%' }}>
                                            <label><b>Description</b></label>
                                            <Controller
                                                name={`description`}
                                                control={control1}
                                                defaultValue={''}
                                                rules={{ required: 'This field is required' }}
                                                render={({ field }) => <textarea className="form-control mt-1" rows={12} {...field}></textarea>}
                                            />
                                            {errors.description && (
                                                <div className="sc-input-err-msg">{errors.description?.message}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    {/* {notification.type != 'none' ? <p className={`text-${notification.type == 'error' ? 'danger' : 'success'}`}>
                                        <FontAwesomeIcon style={{ fontSize: 15, marginTop: 5 }} icon={notification.type == 'error' ? faInfoCircle : faCheckCircle} color={notification.type == 'error' ? 'var(--lt-danger)' : 'var(--lt-success)'} />&nbsp;&nbsp;{notification.msg}</p> : null} */}
                                </div>
                            </div>
                            <div className="card-footer text-end pl-0">
                                <PrimaryButton type="submit">
                                    <FontAwesomeIcon style={{ fontSize: 13 }} icon={faSave} color="var(--lt-light)" />&nbsp;&nbsp;Update
                                </PrimaryButton>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </form>
            </Offcanvas>

            {/* COMPLEANCE */}
            <ComplianceEditModel  show={complianceDialog} onHide={() => setComplianceDialog(false)} getComplianceList={() => getComplianceList()} />

            {compliancePopup.show ? (
                <ComplienceRightSidePopup onAccessRequest={() => onAccessRequestPermision()} show={compliancePopup.show} index={compliancePopup.index} onHide={() => setCompliancePopup({ show: false, index: 0 })} />
            ) : null}

            {isGetAccess ? (
                <GetAccessPermission onHide={() => { setIsGetAccess(!isGetAccess) }} openPopup={isGetAccess} />
            ) : null}

            {showHistory ? (
                <History show={showHistory} type="overview" onHide={() => setShowHistory(false)} />
            ) : null}

        </>
    )
};

export default OverviewComlianceTruestCenter;