import { call, put, takeLatest, take, select, delay } from "redux-saga/effects";
import { types } from "../Types/SagaType";
import EnterpriseService from '../../ApiServices/Enterprise.Service';
import {getSecurityQuestion, getComplianceData, getCheckSpecialDomain} from '../../ApiServices/TrustCenter';

const STORAGE_KEY = process.env.STORAGE_KEY;

const token = localStorage.getItem(`${STORAGE_KEY}/token`);


/**
 * @description getCompanyList
 */
function* getCompanyList({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(EnterpriseService.getCompanys);
            if(res && res.data && res.data && res.data.length){
                yield put({ type: types.GET_COMPANY_LIST_SUCCESS, payload: res.data });
            } else {
                yield put({ type: types.GET_COMPANY_LIST_FAILURE, payload: [] });
            }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error createNewChallenges*');
        yield put({ type: types.GET_COMPANY_LIST_FAILURE, payload: [] });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description delete Companies
 */

function* deleteCompany({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const result:any = yield call(EnterpriseService.deleteCompanys, payload);
        if(result){
            const res:any = yield call(EnterpriseService.getCompanys);
            if(res && res.data && res.data && res.data.length){
                yield put({ type: types.GET_COMPANY_LIST_SUCCESS, payload: res.data });
            } else {
                yield put({ type: types.GET_COMPANY_LIST_SUCCESS, payload: [] });
            }
            yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
        }else{
            yield put({type:types.DELETE_COMPANY_FAILURE, payload:result.message})
        }
    } catch (error) {
        console.log(error, 'error deleteCompany*');
        yield put({ type: types.GET_COMPANY_LIST_FAILURE, payload: [] });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description getComplianceList
 */
function* getComplianceList({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(EnterpriseService.getCompliancefiles, payload);
            if(res && res.data && res.data && res.data.length){
                yield put({ type: types.GET_COMPLIANCE_LIST_SUCCESS, payload: res.data });
            } else {
                yield put({ type: types.GET_COMPLIANCE_LIST_FAILURE, payload: [] });
            }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error getComplianceList*');
        yield put({ type: types.GET_COMPLIANCE_LIST_FAILURE, payload: [] });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description getQuestionnirList
 */
function* getQuestionnirList({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(EnterpriseService.getQuestionnaires, payload);
            if(res && res.data && res.data && res.data.length){
                yield put({ type: types.GET_QUESTIONNAIRE_LIST_SUCCESS, payload: res.data });
            } else {
                yield put({ type: types.GET_QUESTIONNAIRE_LIST_FAILURE, payload: [] });
            }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error getQuestionnirList*');
        yield put({ type: types.GET_QUESTIONNAIRE_LIST_FAILURE, payload: [] });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description updateAnswerForEnterprise
 */
function* updateAnswerForEnterprise({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        let obj:any=payload.data;
        if(!obj['comment']){
            delete obj['comment'];
        }
        const res:any = yield call(EnterpriseService.updateAnswer, obj);
            if(res && res.data && res.status==true){
                yield put({ type: types.UPDATE_ENTERPRISE_SUCCESS, payload: {
                    data:{answer:obj.answer, reviewstatus:obj.reviewstatus, llmstatus:obj.llmstatus, comment:obj.comment},
                    index:payload.index,
                    questionId:obj.questionId
                }});
            } else {
                yield put({ type: types.UPDATE_ENTERPRISE_SUCCESS, payload: {} });
            }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error updateAnswerForEnterprise*');
        yield put({ type: types.UPDATE_ENTERPRISE_SUCCESS, payload: {} });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description setSelectedQuestion
 */
function* countReviewEnterPrise({ payload }:any): any {
    yield delay(500);
    const dataReview = yield select((state:any) => state.AppReducer.selectedQuestion);
    let countOfApproved:number=0;
    let countOfReview:number=0;
    
    if(dataReview && dataReview.questions && dataReview.questions.length){
        for (let i = 0; i < dataReview.questions.length; i++) {
            const el = dataReview.questions[i];
            if(el && el.reviewstatus && !el.llmstatus){
                console.log(el, 'el');
                countOfReview++;
            }else if(el.llmstatus){
                countOfApproved++;
            }
        }
        yield put({ type: types.SET_COUNT_SUCCESS, payload: {countOfApproved,countOfReview} });
    }
    
}

/**
 * @description setSelectedQuestion
 */
function* setSelectedQuestion({ payload }:any): any {
    try {
        yield put({ type: types.SET_SELECTED_SUCCESS, payload: payload });
    } catch (error) {
        yield put({ type: types.SET_SELECTED_SUCCESS, payload: {_id:'', company_id:'', files:[]} });
    }
}

/**
 * @description setAuthRequest
 */
function* setAuthRequest({ payload }:any): any {
    yield put({ type: types.SET_AUTH_SUCCESS, payload: payload });
}


/**
 * @description getTSecurityList
 */
function* getTSecurityList({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(getSecurityQuestion, payload);
        if(res && res.data && res.data && res.data.length){
            yield put({ type: types.GET_TS_LIST_SUCCESS, payload: res.data });
        } else {
            yield put({ type: types.GET_TS_LIST_SUCCESS, payload: [] });
        }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error getTSecurityList*');
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}

/**
 * @description getTCenterComplianceList
 */
function* getTCenterComplianceList({ payload }:any): any {
    try {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(getComplianceData, payload);
        console.log(res, 'resres');
        
        if(res && res.length){
            yield put({ type: types.GET_TC_COMPLIANCE_LIST_SUCCESS, payload: res });
        } else {
            yield put({ type: types.GET_TC_COMPLIANCE_LIST_SUCCESS, payload: [] });
        }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        console.log(error, 'error getTCenterComplianceList*');
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}


/**
 * @description checkSpecialDomainFun
 */
function* checkSpecialDomainFun({ payload }:any): any {
    try {
        yield put({ type: types.CHECK_SPECIAL_DOMAIN_SUCCESS, payload: '' });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: true });
        const res:any = yield call(getCheckSpecialDomain, payload);
        if(res){
            yield put({ type: types.CHECK_SPECIAL_DOMAIN_SUCCESS, payload: 'TRUE' });
        } else {
            yield put({ type: types.CHECK_SPECIAL_DOMAIN_SUCCESS, payload: 'FALSE' });
        }
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        yield put({ type: types.CHECK_SPECIAL_DOMAIN_SUCCESS, payload: 'FALSE' });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}


/**
 * @description getClientDetailsForSetting
 */
function* getClientDetailsForSetting({ payload }:any): any {
    let defaultVal={_id:"",maindomain:"", complianceBucket:"",questionnairesBucket:"", cname:"", database:"",fileStorage:""};
    try {
        const res:any = yield call(EnterpriseService.getClientDetails, payload);
        if(res && res.data && res.data.length){
            yield put({ type: types.GET_CLIENT_DETAILS_SUCCESS, payload: res.data[0] });
        } else {
            yield put({ type: types.GET_CLIENT_DETAILS_SUCCESS, payload: defaultVal});
        }
    } catch (error) {
        console.log(error, 'error for getClientDetailsForSetting*');
        
        yield put({ type: types.GET_CLIENT_DETAILS_SUCCESS, payload: defaultVal });
    }
}

/**
 * @description setEnterpriseFileLoading
 */
function* setEnterpriseFileLoading({ payload }:any): any {
    try {
        if(payload && payload['action']== "documents"){
            yield put({ type: types.SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_SUCCESS, payload:payload});
        }else if(payload && payload['action']== "remove-from-documents-list"){
            const currentArray = yield select((state:any) => state.AppReducer.complianceArrayList);
            yield put({ type: types.GET_COMPLIANCE_LIST_SUCCESS, payload:currentArray.slice(0, -1)});
        }else if(payload && payload['action']== "questions"){
            yield put({ type: types.SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_SUCCESS, payload:payload});
        }else if(payload && payload['action']== "remove-from-questions-list"){
            const currentArray = yield select((state:any) => state.AppReducer.questionArrayList);
            yield put({ type: types.GET_QUESTIONNAIRE_LIST_SUCCESS, payload:currentArray.slice(0, -1)});
        }
        
    } catch (error) {
        console.log(error, 'error for setEnterpriseFileLoading*');
    }
}

/**
 * @description deleteEnterpriseItemFromList
 */
function* deleteEnterpriseItemFromList({ payload }:any): any {
    try {
        if(payload && payload['action']== "documents"){
            const currentArray = yield select((state:any) => state.AppReducer.complianceArrayList);
            yield put({ type: types.GET_COMPLIANCE_LIST_SUCCESS, payload:currentArray.filter((item:any) => item?._id !== payload.id)});
        }else if(payload && payload['action']== "questions"){
            const currentArray = yield select((state:any) => state.AppReducer.questionArrayList);
            yield put({ type: types.GET_QUESTIONNAIRE_LIST_SUCCESS, payload:currentArray.filter((item:any) => item?._id !== payload.id)});
        }
    } catch (error) {
        console.log(error, 'error for deleteEnterpriseItemFromList*');
    }
}


/**
 * @description onChangeSnackbarAction
 */
interface SnackbarProps{
    payload:'warn'|'success'|'info'|'error' |'off'|'clean'| false;
}

function* onChangeSnackbarAction({ payload }:SnackbarProps) {
    try {
        if(payload=='warn'){
            yield put({ type: types.SET_SNACK_BAR_ACTION_WARNING, payload:true });
        }
        if(payload=='success'){
            yield put({ type: types.SET_SNACK_BAR_ACTION_SUCCESS, payload:true });
        }
        if(payload=='info'){
            yield put({ type: types.SET_SNACK_BAR_ACTION_INFO, payload:true });
        }
        if(payload=='error'){
            yield put({ type: types.SET_SNACK_BAR_ACTION_ERROR, payload:true });
        }
        if(payload=='off' || payload=='clean' || payload==false){
            yield put({ type: types.SET_SNACK_BAR_ACTION_OFF, payload:false });
        }
    } catch (error) {
        yield put({ type: types.SET_SNACK_BAR_ACTION_OFF, payload: false });
    }
}

/**
 *
 * @description Show Loading
 */
function* clearAllStateValue(): Generator<any, any, any> {
    try {
        yield put({ type: types.RESET_STATE_SUCCESS, payload: true });
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    } catch (error) {
        yield put({ type: types.SET_LOADING_SUCCESS, payload: false });
    }
}




export default function* AppSaga() {
    yield takeLatest(types.GET_COMPANY_LIST_REQUEST, getCompanyList);
    yield takeLatest(types.DELETE_COMPANY_REQUEST, deleteCompany);
    yield takeLatest(types.GET_COMPLIANCE_LIST_REQUEST, getComplianceList);
    yield takeLatest(types.GET_QUESTIONNAIRE_LIST_REQUEST, getQuestionnirList);
    yield takeLatest(types.SET_SELECTED_REQUEST, setSelectedQuestion);
    yield takeLatest(types.UPDATE_ENTERPRISE_REQUEST, updateAnswerForEnterprise);
    yield takeLatest(types.SET_SNACK_BAR_ACTION_REQUEST, onChangeSnackbarAction);
    yield takeLatest(types.SET_AUTH_REQUEST, setAuthRequest);
    yield takeLatest(types.RESET_STATE, clearAllStateValue);
    yield takeLatest(types.GET_TS_LIST_REQUEST, getTSecurityList);
    yield takeLatest(types.GET_TC_COMPLIANCE_LIST_REQUEST, getTCenterComplianceList);
    yield takeLatest(types.CHECK_SPECIAL_DOMAIN_REQUEST, checkSpecialDomainFun);
    yield takeLatest(types.GET_CLIENT_DETAILS_REQUEST, getClientDetailsForSetting);
    yield takeLatest(types.SET_ENTERPRISE_FILE_LOADING_REQUEST, setEnterpriseFileLoading);
    yield takeLatest(types.DELETE_ENTERPRISE_FILE_REQUEST, deleteEnterpriseItemFromList);
    yield takeLatest(types.SET_COUNT_REQUEST, countReviewEnterPrise);
}