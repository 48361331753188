import React, { useEffect, useState } from 'react';
import { faDoorOpen, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Button, Container, Form, Col, Row, } from 'react-bootstrap';
import PrimaryButton from './../../Components/Buttons/PrimaryButton';
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { SecurityReviewFormModel } from './../../Models/SecurityModel';
import Validate from './../../Helper/ValidationUtils';
import { addAndUpdateAccounts, userAccessRequest } from './../../ApiServices/TrustCenter';
import { toastManager } from './../../Components/ToastDialog/ToastManager';
import { Link } from 'react-router-dom';
import { getClientDetails } from './../../ApiServices/SettingsApi';
import { useNavigate } from 'react-router-dom';


interface Props {
    openPopup: boolean;
    onHide: (e: boolean) => void;
}

export interface DomainProp {
    database: string;
    fileStorage: string;
    cname: string;
}

const GetAccessPermission = ({ openPopup, onHide }: Props) => {
    const navigate = useNavigate();

    const [getAccessPopup, setShowAccessPopup] = useState(openPopup);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [email, setEmail] = useState<string>("");
    const [show, setShow] = useState(false);
    const [formTouched, setFormTouched] = useState(false);
    const [hostDetails, sethostDetails] = useState<DomainProp>({ database: "", fileStorage: "", cname: "" });
    const [companyName, setCompanyName] = useState("");

    const { control, handleSubmit, formState: { errors }, watch, reset } = useForm<SecurityReviewFormModel>({
        defaultValues: {
            firstName: "",
            lastName: "",
            jobTitle: "",
            workEmail: "",
            companyName: "",
            relationshpLinkedIn: "",
            countryRegion: "",
            _id: "",
            cboxA: false,
            cboxB: false,
        }
    });


    useEffect(() => {
        // getDomainDetails();
        let companyName = window.location.hostname.split('.')[0]
        const capitalizedCompanyName = companyName.charAt(0).toUpperCase() + companyName.slice(1);

        setCompanyName(capitalizedCompanyName);
    }, [])

    const toggleGetAccessModal = (typeBool: boolean) => {
        setShowAccessPopup(typeBool);
        onHide(typeBool);
    }


    const countryList: any = [{ "country": "United States" }, { "country": "China" }, { "country": "India" }, { "country": "Indonesia" }, { "country": "Brazil" }, { "country": "Nigeria" }, { "country": "Bangladesh" }, { "country": "Russia" }, { "country": "Mexico" }, { "country": "Japan" }, { "country": "Ethiopia" }, { "country": "Philippines" }, { "country": "Egypt" }, { "country": "Vietnam" }, { "country": "DR Congo" }, { "country": "Turkey" }, { "country": "Iran" }, { "country": "Germany" }, { "country": "Thailand" }];

    const toggleShow = (e: boolean) => {
        setShow(e)
    };

    const checkGetAccessValidation = async () => {

        let result: any = await userAccessRequest({ "email": email });
        const capitalizedCompanyName = companyName.charAt(0).toUpperCase() + companyName.slice(1);

        console.log(result, 'result');

        if (result.status) {
            localStorage.setItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`, JSON.stringify({ token: result.data.token }));
            window.dispatchEvent(new Event('storage'));
            // setShowAccessPopup(false);
            onHide(false);
            navigate('/');
        } else {
            // setShowAccessPopup(false);
            toggleShow(true);
            reset({
                firstName: "",
                lastName: "",
                jobTitle: "",
                workEmail: email,
                companyName: "",
                relationshpLinkedIn: "",
                countryRegion: "",
                _id: "",
                cboxA: false,
                cboxB: false,
            })
        }
    }

    const getDomainDetails = async () => {
        const gres = await getClientDetails();
        sethostDetails(gres?.data[0] || {})
    }

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
        if (Validate.Email(e.target.value)) {
            setIsEmailValid(true);
        } else {
            setIsEmailValid(false);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isEmailValid) {
            e.preventDefault();
        }
    };


    const onSubmit = async (e: any) => {
        // e.preventDefault();
        const data = watch();
        const obj: any = {
            firstName: data.firstName,
            lastName: data.lastName,
            jobTitle: data.jobTitle,
            workEmail: data.workEmail,
            email: data.workEmail,
            companyName: data.companyName,
            relationshpLinkedIn: data.relationshpLinkedIn,
            countryRegion: data.countryRegion,
            getUpdates: data.cboxA,
            termsOfservice: data.cboxB
        }

        const result: any = await addAndUpdateAccounts('', obj); /**@UPADTE_API */

        if (result && result.status == true) {
            toastManager.show({
                title: 'Success!',
                message: 'Your request has been submitted successfully. You will receive an email upon approval.',
                type: 'success'
            });

            reset({
                firstName: "",
                lastName: "",
                jobTitle: "",
                workEmail: "",
                companyName: "",
                relationshpLinkedIn: "",
                countryRegion: "",
                _id: "",
                cboxA: false,
                cboxB: false,
            })
            setEmail("");
            toggleShow(false);
            onHide(false);
        } else {
            toastManager.show({
                title: 'Error!',
                message: 'Something went wrong, please try again.',
                type: 'danger'
            });
            toggleShow(false);
            onHide(false);
        }
    };


    return (
        <div >
            <Modal
                style={{ marginTop: '50px', left: '8px' }}
                show={getAccessPopup}
                onHide={() => toggleGetAccessModal(false)}
                dialogClassName="custom-modal"
            >
                <Modal.Body>
                    <button onClick={() => toggleGetAccessModal(false)} type="button" className="btn-close-icon">
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <div className="welcome-logo">
                        <FontAwesomeIcon icon={faDoorOpen} color={'var(--lt-text-primary)'} />
                    </div>
                    <div className="box">
                        <h5>Get access to {companyName}'s Trust Center</h5>
                        <h6 className="text-muted">To get started, please enter your email</h6>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div data-testid="form-feedback-email">
                                <div className="form-group">
                                    <label className="form-label">
                                        <span>Work Email</span>
                                        <span className="required-label">*</span>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        onBlur={() => setFormTouched(true)}
                                        onChange={onChangeEmail}
                                        value={email}
                                        name="email"
                                        placeholder="john@example.com"
                                        type="email"
                                        id="memberEmail"
                                        className="form-control"
                                        onKeyDown={handleKeyDown}
                                    />
                                    <div className="sc-6147408b-0 kpRxNk"></div>
                                    {!isEmailValid && formTouched ? (
                                        <div className="invalid-feedback d-block">Please Enter valid Email id</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        onClick={() => toggleGetAccessModal(false)}
                        type="button"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                    >
                        Back to Trust Center
                    </button>
                    <PrimaryButton
                        disabled={!isEmailValid}
                        onClick={checkGetAccessValidation}
                        name={'Continue'}
                    />
                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={() => toggleShow(false)}>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                    <Modal.Title>Request access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-primary'>
                        Please provide the following details to get access to {companyName}'s Trust Center
                    </p>

                    <Form onSubmit={handleSubmit(onSubmit)} method="post">
                        <Row md={2} className='mb-2'>
                            <Col>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>First name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`firstName`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter First name" {...field} />}
                                    />
                                    {errors?.firstName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Last name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`lastName`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter Last name" {...field} />}
                                    />
                                    {errors?.lastName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Job title <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`jobTitle`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter job title" {...field} />}
                                    />
                                    {errors?.jobTitle && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.jobTitle?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Work email <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`workEmail`}
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                                                message: "Invalid email address"
                                            }
                                        }}
                                        render={({ field }) => <Form.Control required readOnly type="text" placeholder="Enter Work email" {...field} />}
                                    />
                                    {errors?.workEmail && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.workEmail?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Company name <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`companyName`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => <Form.Control required type="text" placeholder="Enter Company name" {...field} />}
                                    />
                                    {errors?.companyName && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.companyName?.message}</Form.Control.Feedback>
                                    )}


                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Relation with {companyName} <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`relationshpLinkedIn`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => (
                                            <Form.Select required {...field}>
                                                <option>Select</option>
                                                <option>Customer</option>
                                                <option>Prospective Customer</option>
                                                <option>Vendor</option>
                                                <option>Partner</option>
                                                <option>Regulator/Auditor</option>
                                                <option>Researcher</option>
                                                <option>Other</option>
                                            </Form.Select>
                                        )}
                                    />
                                    {errors?.relationshpLinkedIn && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.relationshpLinkedIn?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} className='mb-2'>
                                <Form.Group controlId="validationCustom02">
                                    <Form.Label>Country / Region <span className='text-danger'>*</span></Form.Label>
                                    <Controller
                                        name={`countryRegion`}
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                        render={({ field }) => (
                                            <Form.Select required {...field}>
                                                <option value={""}>Select</option>
                                                {countryList.map((item: any, index: number) => <option key={`country-${index}`}>{item.country}</option>)}
                                            </Form.Select>
                                        )}
                                    />
                                    {errors?.countryRegion && (
                                        <Form.Control.Feedback className="d-block" type="invalid">{errors.countryRegion?.message}</Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mb-2">
                            <Controller
                                name={`cboxA`}
                                control={control}
                                rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-checkbox"
                                        {...field}
                                        value={'false'}
                                        label={
                                            <div>
                                                I have read and agree to {companyName}'s <Link to='/NDA' className='link-href ' target='_blank'>NDA</Link>, along with Targhee Security's <Link to='/terms-of-service' className='link-href ' target='_blank'>Terms of Service</Link> and <Link to='/privacy-policy' className='link-href ' target='_blank'>Privacy Policy</Link>  of {companyName}
                                            </div>
                                        }
                                    />
                                )}
                            />

                            <Controller name={`cboxB`} control={control} rules={{}}
                                render={({ field }) => (
                                    <Form.Check // prettier-ignore
                                        type="checkbox"
                                        id="custom-checkbox"
                                        {...field}
                                        value={'false'}
                                        label={<div>I would like to get updates from {companyName}'s Trust Center via email
                                        </div>}
                                    />
                                )}
                            />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { toggleShow(false); setShowAccessPopup(true); }}>Back</Button>
                    <Button className='custom-btn' type="button" variant="primary" onClick={() => {
                        handleSubmit(onSubmit)();
                    }}>
                        Submit Request
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default GetAccessPermission;
