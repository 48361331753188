import SideMenu from '../../../Components/SideMenu'
import WithSidemenuHeader from '../../../Components/WithSidemenuHeader'
import React, { useEffect, useState } from 'react'
import './nda.css'

import { NdaModel } from '../../../Models/SettingsModel'
import { updateOrsaveNDA, getNdadata, } from '../../../ApiServices/SettingsApi'

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { NotificationModel } from '../../../Models/SecurityModel';
import CheckMark from '../../../Components/CheckMark';


type ColorType = 'success' | 'default';
const AddNDA = () => {

  const [color, setColor] = useState<ColorType>('success');
  const [inputValue, setInputValue] = useState('');
  const [icon,  setIcon] =useState<any>('');
  const [showUpdateIcon, setShowUpdateIcon] = useState(false);

  const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
  const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<NdaModel>({
    defaultValues: {
      _id: '',
      description: ''
    }
  });

 

  useEffect(() => {
    getNDA();
  }, [])

  /**@GET_NDA */
  const getNDA = async () => {
    const result: any = await getNdadata(false);
    if (result) {
      reset({
        _id:result?.data?._id,
        description: result?.data?.description,
      })
    }
  }

  /**@PUT_UPDATE_NDA */
  const onSubmit: SubmitHandler<NdaModel> = async (data) => {
    setColor('default');
    setNotification({ type: 'none', msg: '' });
    const obj: any = data;
    let id: string = obj._id ? obj._id : '';
    let sendData: any = {
      description: obj.description,
    }
    const result = await updateOrsaveNDA(id, sendData); /**@UPADTE_API */
    if (result && result.status == true) {
      setNotification({ type: 'success', msg: `${(data?.description ? 'Updated' : 'Added')} Successfully` })
      getNDA()
      setShowUpdateIcon(true)
      setIcon(<CheckMark height={20} width={20} color={'success'} />);

    } else {
      setColor('default');
      setIcon('')
      setShowUpdateIcon(false)
      setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
    }

  }

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };


  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title={'Non-Disclosure Agreement'} />
        <div className="router-container ">
          <div className="row">
            <div className="col-lg-10 col-xl-10 offset-xl-1 offset-lg-1 mt-4">
              <div className="card border">
                <div className="card-header bg-light border-bottom">
                  Add NDA
                </div>
                <form method="post" autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body px-0 pt-1">
                  <div className="mt-4 d-flex flex-row align-items-center">
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <div className="form-group" style={{ width: '95%' }}>

                      <Controller
                        name={`description`}
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'This field is required' }}
                        render={({ field }) => <textarea className="form-control mt-1 scrollable-textarea" rows={20} {...field}></textarea>}
                      />

                    </div>
                  </div>
                  {notification.type == 'error' && (
                                    <div className="sc-input-err-msg" style={{margin:15}}>{notification?.msg}</div>
                  )}
                    
                  <div className="d-flex justify-content-end " style={{marginRight:'40px'}}>
                      <button className='save-btn px-4'>Save</button>
                      <span style={{ position: 'relative', top: '10px', left: '8px' }}> {icon}</span>
                  </div>

                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
       
      </section>
    </main>
  )
}

export default AddNDA;

