import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { getHistoryList } from "./../../../ApiServices/TrustCenter";
import { PaginationProps } from '@/Models/SecurityModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';

interface Props {
    type: any;
    show: boolean;
    onHide: () => void;
}

function History({ type, onHide, show }: Props) {
    const [pagination, setPagination] = useState<PaginationProps>({ pageSize: 1000, totalPages: 0, currentPage: 1 });
    const [historyList, setHistoryList] = useState<any>([]);

    useEffect(() => {
        if (show) {
            getHistory();
        }
    }, [show])

    const getHistory = async () => {
        const result: any = await getHistoryList(pagination.currentPage, pagination.pageSize, type);
        if (result && result.data) {
            setHistoryList(result.data);
        }
    }

    if (!show) return (<></>);

    const FileGroupList = ({ files, isPublic }: any) => {

        return (
            <ul className="list-group list-group-flush tc-docs-list border-0">
                {files.map((item: any, fileIndex: number) => (
                    <li key={`compliance-files-list-${fileIndex}`} className="list-group-item">
                        <div className="row align-items-center">
                            <div className="col-8 col-lg-8 col-xl-8 text-truncate" >
                                <FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />
                                &nbsp;&nbsp;&nbsp;{item.fileName}</div>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    const AMToPM = (date: any) => {
        return new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    }

    return (
        <Offcanvas show={show} placement={'end'} style={{ minWidth: (type == 'overview' ? 730 : 730) }} onHide={onHide}>
            <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ddd' }}>
                <Offcanvas.Title>
                    <div className="title-header-dynamic">
                        History
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="h-100">
                {historyList.map((item: any, index: number) => (
                    <div key={`history-${index}`} className="card shadow-sm mb-3">
                        <div className="card-body" style={{ padding: '15px', overflowY: 'auto', maxHeight: (window.innerHeight - 65) }}>
                            <div>
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                    <h5>{item?.subTitle || item?.title} </h5>
                                    <div className="publc-prit-btns">
                                        <button type="button" className={`btn-sm ${item.isPublic ? 'activeTwo' : ''}`}>Public</button>
                                        <button type="button" className={`btn-sm ${item.isPublic ? '' : 'activeTwo'}`}>Private</button>
                                    </div>
                                </div>
                                <p className={'pt-3'}>{item['description']}</p>
                            </div>

                            {(item?.files && item?.files.length > 0) ? (
                                <FileGroupList files={item?.files} isPublic={item?.isPublic} />
                            ) : null}
                        </div>
                        <hr style={{ color: '#dddddd' }} />
                        <div className={"ps-3 pe-3 text-capitalize"} style={{ fontSize: 14, paddingBottom: 16 }}>
                            <span style={{ color: '#1f83e7' }}>{item.firstName} {item.lastName}</span>,
                            <span style={{ color: '#ffa335' }}>&nbsp;&nbsp;{AMToPM(item.createdAt)}</span>
                        </div>
                    </div>
                ))}

                <div className='pt-4 pb-4'></div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}
export default History;