import React, { useEffect, useState } from 'react'
import SideMenu from './../../Components/SideMenu';
import './Accounts.css'
import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import { faUserPlus, faClockRotateLeft, faCheck, faTrash, faAddressCard, faSearch, faArrowDown, faFilter, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuth } from './../../ApiServices/Auth.Service'
import { getAccountsList, accountStatusApi, getApprovedAccountsList, DeleteaccountsApi } from '../../ApiServices/Account.Service';
import { SecurityReviewModel, PaginationProps } from '../../Models/AccountModel';
import { confirmationManager } from './../../Components/ConfirmationDialog/ConfirmationManager';
import Pager from './../../Helper/Pager';
import { toastManager } from './../../Components/ToastDialog/ToastManager';

const Account = () => {
  const [pagination, setPagination] = useState<PaginationProps>({ pageSize: 10, totalPages: 0, currentPage: 1 });
  const [pagination2, setPagination2] = useState<PaginationProps>({ pageSize: 10, totalPages: 0, currentPage: 1 });
  const [accountList, setAccountList] = useState<SecurityReviewModel[]>([]);
  const [approvedAccountList, setApprovedAccountList] = useState<SecurityReviewModel[]>([]);

  useEffect(() => {
    _getAccountsList();
  }, []);

  useEffect(() => {
    _getApprovedAccountsList();
  }, []);

  const _getAccountsList = async () => {
    const result: any = await getAccountsList(pagination.currentPage, pagination.pageSize);
    if (result && result?.data && result?.data?.length) {
      
      setAccountList(result.data);
    } else {
      setAccountList([]);
    }
    setPagination((preProps: any) => ({ ...preProps, totalPages: result?.totalPages }));
  }

  const _getApprovedAccountsList = async () => {
    console.log('a')
    const result: any = await getApprovedAccountsList(pagination2.currentPage, pagination2.pageSize);
    console.log('b')
    if (result && result.data && result.data.length) {
      setApprovedAccountList(result.data);
      console.log('result', result)
    } else {
      setApprovedAccountList([]);
    }
    setPagination2((preProps: any) => ({ ...preProps, totalPages: result?.totalPages }));
  }

  //Approve User
  const handleAccountStatus = async (status: string, _id: string) => {
    let authData: any = getAuth()

    console.log('authData', authData)
    let aobj = {
      id: authData?._id,
      userName: `${authData?.firstName} ${authData?.lastName}`,
    }
    
    const res = await accountStatusApi(_id, { "Approved": status, 'approvedbyuser': aobj });
    console.log('res', res);
    if(res.status){
      toastManager.show({
        title: 'Account approval!',
        message: 'Account request has been marked as approved successfully',
        type: 'success'
      });
      await _getAccountsList();
      await _getApprovedAccountsList();
    } else {
      toastManager.show({
        title: 'Account was not approved due to an error.',
        message: '',
        type: 'warn'
      });
      await _getAccountsList();
      await _getApprovedAccountsList();
    }

  }


  const convertToLocalTime=(datetime:any)=> {
    const date = new Date(datetime);
    // Format the date to the local timezone using Intl.DateTimeFormat
    const options:any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    };
  
    // Use the user's local timezone
    const localDate = new Intl.DateTimeFormat('default', options).format(date);
    // const getDate = (d: any) => d.split('T')[0];
    console.log(localDate, 'localDate');
    
    return localDate.split('at')[0];
  }

  const handleDeleteAccount = (_id: string) => {
    confirmationManager.requestConfirmation({
      heading: "Account Confirmation!",
      message: `Are you sure you want to delete the account?`,
      onConfirm: async () => {
        let result: any = await DeleteaccountsApi(_id);
        if (result.status) {
          await _getAccountsList();
          await _getApprovedAccountsList();
        }
      },
      onCancel: () => {
        // Handle the cancel action
        console.log(' canceled');
      },
    });
  };




  

  const handlePageChange = (page: number) => {
    setPagination((preProps: any) => ({ ...preProps, currentPage: page }));
  };

  const handlePageChange2 = (page: number) => {
    setPagination2((preProps: any) => ({ ...preProps, currentPage: page }));
  };

  return (
    <main className="acc-main">
      <section className="left-menu-col">
        <SideMenu />
      </section>
      <section className="right-routers-col">
        <WithSidemenuHeader title='Accounts' />
        <div className="router-container">
          {/* access request */}
          <div className="statuscard card shadow-lg mt-3 px-2  ">
            <div className="statuscard-header card-header">
              <div className="py-3">
                  <FontAwesomeIcon icon={faUserPlus} color="#000" className='lefticon' />
                  <span className="pgetilerleft">Access Requests</span>
                  
                  {/* <div className="navbar-right" style={{ color: "var(--lt-primary)" }}>
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  <span className="pgetileright">View History</span>
                </div> */}
              </div>

              {/* <div className="d-flex position-relative gap-2 tbl-search-main">
                  <div className="searchTbl">
                    <FontAwesomeIcon icon={faSearch} color="#999" className="acc-search-icon" />
                    <input className="acc-search" placeholder="Search by order#, name..." />
                  </div>
                  <div className="dataRqst">
                    <button className='btn btn-sm border py-2'><FontAwesomeIcon icon={faArrowDown} /> Data Requested </button>
                  </div>
                  <div className="fiterBtn">
                    <button className='btn btn-sm border py-2'> <FontAwesomeIcon icon={faFilter} /></button>
                  </div>
                </div> */}

              {/* <div className="border-bottom mb-2"></div> */}

              <div className="table-responsive table-bordered">
                <table className="table table-responsive table-hover ">

                  <thead className='headtl'>
                    <tr >
                      {/* <th scope="col" style={{ width: '5%' }} ><input className="form-check-input" type="checkbox" /></th> */}
                      <th scope="col" style={{ width: '5%' }}>#</th>
                      <th scope="col" style={{ width: '15%' }}>Requester</th>
                      <th scope="col" style={{ width: '10%' }}>Company</th>

                      <th scope="col" style={{ width: '15%' }}>Customer Type</th>

                      <th scope="col" style={{ width: '15%' }}>Work Email</th>

                      <th scope="col" style={{ width: '15%' }}>Date Requested </th>
                      <th scope="col" style={{ width: '10%' }}>Status</th>
                      <th scope="col" className="text-start" style={{ width: '20%' }}>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountList?.map((item: any, index: number) => (
                      <tr className='thtl' key={`account-list-${index}`}>
                        {/* <th scope="row"><input className="form-check-input" type="checkbox" /></th> */}

                        <td>{(index + 1)}</td>


                        <td><i className="fa fa-check-circle-o green"></i>
                          <span className="ms-1">{item.firstName + ' ' + item.lastName}</span>
                        </td>


                        <td>{item.companyName}</td>

                        <td>{item.relationshpLinkedIn}</td>

                        <td>{item.workEmail}</td>

                        <td>{convertToLocalTime(item.createdAt)}</td>

                        <td>{item.Approved}</td>

                        <td className="text-start gap-2">

                          <div className="gap-2">
                            <span onClick={() => handleAccountStatus('approved', item._id)} className='btn_tick'>
                              <FontAwesomeIcon icon={faCheck} color='green' />
                            </span>

                            <span onClick={() => handleDeleteAccount(item._id)} className='btn_cros'>

                              <FontAwesomeIcon icon={faTrash} color={'var(--lt-danger)'} />
                            </span>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                  
                  </tfoot>
                </table>
                {pagination?.totalPages ? (
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <Pager
                      totalPages={pagination.totalPages}
                      currentPage={pagination.currentPage}
                      onPageChange={handlePageChange}
                    />
                  </div>
                ) : null}
              </div>

            </div>
          </div>
          {/*Accounts */}
          <div className="statuscard card shadow-lg mt-5 px-2  ">
            <div className="statuscard-header card-header">
              <div className="py-3">
                <FontAwesomeIcon icon={faAddressCard} color="#000" className='lefticon' />
                <span className="pgetilerleft">Accounts</span>
              </div>
              
              {/* <div className="d-flex position-relative gap-2 tbl-search-main">
                  <div className="searchTbl">
                    <FontAwesomeIcon icon={faSearch} color="#999" className="acc-search-icon" />
                    <input className="acc-search" placeholder="Search by order#, name..." />
                  </div>
                  <div className="dataRqst">
                    <button className='btn btn-sm border py-2'><FontAwesomeIcon icon={faArrowDown} /> Data Requested </button>
                  </div>
                  <div className="fiterBtn">
                    <button className='btn btn-sm border py-2'> <FontAwesomeIcon icon={faFilter} /></button>
                  </div>
                </div> */}

              <div className="table-responsive table-bordered">
                <table className="table table-responsive table-hover ">

                  <thead className='headtl'>
                    <tr>
                      <th scope="col" style={{ width: '5%' }}>#</th>
                      <th scope="col" style={{ width: '15%' }}>Company</th>
                      <th scope="col" style={{ width: '15%' }}>Requester</th>
                      <th scope="col" style={{ width: '10%' }}>Email </th>

                      <th scope="col" style={{ width: '10%' }}>Permission </th>
                      <th scope="col" style={{ width: '10%' }}>Created </th>
                      <th scope="col" style={{ width: '20%' }}>Approved By </th>
                      <th scope="col" className="text-start" style={{ width: '20%' }}>

                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedAccountList?.map((item: any, index: number) => (
                      <tr className='thtl'>
                        <td>{(index + 1)}</td>
                        <td>{item?.companyName}</td>

                        <td><i className="fa fa-check-circle-o green"></i>
                          <span className="ms-1">{item.firstName + ' ' + item.lastName}</span>
                        </td>

                        <td>{item.workEmail}</td>

                        <td>
                          <span style={{ backgroundColor: '#a5e0af', borderRadius: 14, padding: 5, display: 'inline-block', lineHeight: 1 }}>
                            <FontAwesomeIcon icon={faCheck} color='green' />&nbsp;Active
                          </span>
                        </td>


                        <td>{convertToLocalTime(item.createdAt)}</td>
                        <td><i className="fa fa-check-circle-o green"></i>
                          <span className="ms-1">{item?.approvedbyuser?.userName}</span>
                        </td>
                        <td className="text-start">
                          <div className="d-flex">
                            {/* <span >
                              <button type="button" className="btn btn-outline-secondary btn-sm">Edit</button>
                            </span> */}
                            <span style={{ marginLeft: 5 }} onClick={() => handleDeleteAccount(item._id)}>
                              <button type="button" className="btn btn-outline-secondary btn-sm">Delete</button>
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>


                  </tfoot>
                </table>

                {pagination2?.totalPages ? (
                  <div className="d-flex flex-row justify-content-end align-items-center">

                    <Pager
                      totalPages={pagination2.totalPages}
                      currentPage={pagination2.currentPage}
                      onPageChange={handlePageChange2}
                    />
                  </div>) : null}

              </div>


            </div>
          </div>

        </div>
      </section>
    </main>
  )
}

export default Account;
