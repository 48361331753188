// Documents.tsx
import React, { useRef, useState, useEffect, useContext } from 'react';
import './Documents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faCircleDown, faCircleCheck, faArrowRight, faAngleDown, faFileLines, faXmark, faDoorOpen, faLockOpen, faFilePdf, faImage, faDownload, faShieldHalved, faFileShield, faLock, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Tabs from './../../Components/Tabs';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import { getDocsList, getComplianceDataList, createDownloadLogs, userAccessCheck } from '../../ApiServices/TrustCenter';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { DocumentModelNew } from './../../Models/EditorDocumentModel';
import CheckMark from '../../Components/CheckMark';
import BulkDownload from './../BulkDownload';
import { S3Download } from './../../ApiServices/S3Bucket';
import GetAccessPermission from './../GetAccessPermission';
import ReactContext from './../../ApiServices/ReactContext/ReactContext';
import SecutiryCardDetailPopup from './../../SecurityCard/SecutiryCardDetailPopup';
import { ComplianceModel } from './../../Models/SecurityModel';
declare var bootstrap: any;

const Documents = () => {
    const tabNames = ['All', 'Public', 'Private'];
    const [fileAccessPermission, setFileAccessPermission] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [teampData, setTempData] = useState<DocumentModelNew[]>([]);
    const [documents, setDocuments] = useState<DocumentModelNew[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [activeIndexcard, setActiveIndexcard] = useState<number>(0);
    const contentRef: any = useRef(null);
    const cardRefs: any = useRef([]);
    const [openModal, setOpenModal] = useState(false);
    const [tempObj, setTempObj] = useState<any>({});
    const [isGetAccess, setIsGetAccess] = useState(false);
    const [dataCompliance, setDataCompliance] = useState([]);
    const [showSecurityDialog, setShowSecurityDialog] = useState(false);
    const [selectCardSubIndexNo, setSelectCardSubIndexNo] = useState(0); /**@FOR_SECURITY_CARD */
    const [selectCardIndexNo, setSelectCardIndexNo] = useState(0);/**@FOR_SECURITY_CARD */

    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;


    const toggleCollapse = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === `${process.env.REACT_APP_STORAGE_KEY}/fileAccess`) {
                getAccessCheck();
            }
        };

        // Add event listener
        window.addEventListener('storage', handleStorageChange);

        // Initial check on mount
        getAccessCheck();
        fetchDocuments();

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };


    }, []);



    const getAccessCheck = async () => {
        const fileAccess = await userAccessCheck();
        console.log(fileAccess, 'fileAccess');
        setFileAccessPermission(fileAccess);
    }

    const fetchDocuments = async () => {
        try {
            const docsapi: any = await getDocsList(true);
            if (docsapi && docsapi.data && docsapi.data.length) {
                setDocuments(docsapi.data);
                setTempData(docsapi.data);
            } else {
                setDocuments([]);
                setTempData([]);
            }
        } catch (err) {
            console.error(err); // Log the error
            setError('Failed to fetch documents');
        } finally {
            setLoading(false);
        }
    };

    const onChnageTabs = (type: string) => {
        console.log(type, 'type');
        if (type === 'Public') {
            setDocuments(teampData.filter((item: any) => item.isPublic == true));
        } else if (type === 'Private') {
            setDocuments(teampData.filter((item: any) => item.isPublic == false));
        } else {
            setDocuments(teampData);
        }
        setActiveTab(type);
        setIsExpanded(false);
    }


    const handleToggleDialog = (item: any) => {
        setTempObj(item);
        if (item && item.type == "compliance") {
            getAccessCheck();
            let dataCom: any = getComplianceDataList() || [];
            setDataCompliance(dataCom);
            let _activeIndexcard: number = 0;
            for (let i = 0; i < dataCom.length; i++) {
                console.log(dataCom[i]._id, item._id);
                if (dataCom[i]._id === item._id) {
                    setActiveIndexcard(i);
                    _activeIndexcard = i;
                    setShowDialog(!showDialog);
                    break;
                }
            }
            setTimeout(() => {
                scrollToIndex(_activeIndexcard);
            }, 400);
        } else if (item && item.type == "security") {
            reload('SECURITY_LIST');
            setTimeout(() => {
                for (let i = 0; i < data.securityList.length; i++) {
                    if (data.securityList[i]._id === item._id) {
                        setSelectCardIndexNo(i);
                        for (let k = 0; k < data.securityList[i].subData.length; k++) {
                            const elK: any = data.securityList[i].subData[k];
                            if (elK.title === item.documentName) {
                                setSelectCardSubIndexNo(k);
                                break;
                            }
                        }
                        // console.log('✅');
                        break;
                    }
                }
                setShowSecurityDialog(true);

            }, 300);
        }
    };


    const scrollToIndex = (index: number) => {
        if (cardRefs.current[index]) {
            cardRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    const onAccessRequest = () => {
        setShowDialog(false);
        setIsGetAccess(true);
    };

    const FileGroupList = ({ sno, files, isPublic }: any) => {
        if (isPublic == false && fileAccessPermission == false) {
            return (
                <div style={{ marginLeft: 32 }}>
                    <PrimaryButton onClick={onAccessRequest}>Get Access</PrimaryButton>
                </div>
            )
        }

        return (
            <ul className="list-group list-group-flush tc-docs-list border-0">
                {files.map((item: any, fileIndex: number) => (
                    <li key={`compliance-files-list-${fileIndex}`} className="list-group-item mb-0">
                        <div className="row align-items-center">
                            <div className="col-8 col-lg-8 col-xl-8 text-truncate" >
                                <FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />
                                &nbsp;&nbsp;&nbsp;{item.fileName}</div>
                            <div className="col-4 col-lg-4 col-xl-4 tc-docs-action text-end" onClick={() => onFileDownload(sno, fileIndex, isPublic)}>
                                <span>
                                    <FontAwesomeIcon icon={faDownload} color={'var(--lt-primary)'} />
                                    <a className="view-download-link">Download</a>
                                </span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    const onFileDownload = async (index: number, fileIndex: number, isPublic: boolean) => {
        const obj: any = { ...documents[index] };
        let fileObj: any = obj.files[fileIndex];
        S3Download({ fileKey: fileObj._id, bucketName: 'mytest1-docs', newFileName: fileObj.fileName }).then((res: any) => {
            console.log(fileObj, 'obj');
            createDownloadLogs([fileObj.fileName], 'compliance', 'user', isPublic);
        }).catch((err: any) => {
            console.log(err, 'err file');
        })
    };

    return (
        <div className='wrapper'>
            <div className=" h-auto">
                <div className="statuscard-headerd">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <h2>
                                <div className="d-flex align-items-center header">
                                    {/* <FontAwesomeIcon icon={faFileArrowDown} color="#014181" /> */}
                                    <h2 className="text-secondary fw-bold me-4 complaince-text" >Documents</h2>
                                </div>
                            </h2>

                            <div className="filter" >
                                <Tabs tabs={tabNames} onChangeTab={(e) => onChnageTabs(e)} />
                            </div>
                        </div>

                        <PrimaryButton onClick={() => setOpenModal(true)} type="button" className="bulk-btn">
                            <span className="sc-4f686923-0 gvNcza">
                                <FontAwesomeIcon icon={faCircleDown} color="white" />
                            </span>
                            <span>&nbsp;&nbsp;Bulk Download</span>
                        </PrimaryButton>
                    </div>
                </div>
                <div className="pt-0 pb-0" style={{ padding: 12 }}>
                    {activeTab != 'Public' && fileAccessPermission == false && (
                        <div className='text-center mt-2'>
                            <PrimaryButton onClick={onAccessRequest} name={'Request Access to Private Documents'} />
                        </div>
                    )}

                    <div className="container h-auto mt-4 pb-0">
                        <div className="row m">
                            {documents.slice(0, 6).map((item: any, index: number) => (
                                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2" key={index}>
                                    <div className="card shadow-sm document-itemm document-item" onClick={() => handleToggleDialog(item)}>

                                        <div className='circle-check'>
                                            <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                        </div>
                                        <div className="card-body text-center" style={{ position: 'relative', height: '100%' }}>
                                            <span className="truncate-text-2line">{item.documentName}</span>

                                            <div className="action-btn">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)', textAlign: 'center' }}>{item.isPublic && item.files.length ? 'download' : 'see details'}</div>

                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'>
                                                    <FontAwesomeIcon icon={faArrowRight} color="var(--lt-primary)" />
                                                    {/* <FontAwesomeIcon icon={faLock} /> */}
                                                </div>
                                            </div>

                                            {item?.isPublic && item.files.length ? (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLockOpen} color="var(--lt-primary)" /></div>
                                                </div>
                                            ) : (<div className="action-btn-lock">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLock} color="var(--lt-primary)" /></div>
                                            </div>
                                            )}


                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={`row mb-3 pb-2 collapse-content ${isExpanded ? 'expanded' : ''}`}
                            style={{ maxHeight: isExpanded ? contentRef.current.scrollHeight + 'px' : '0' }}
                            ref={contentRef}>
                            {documents.slice(6, documents.length).map((item: any, index: number) =>
                                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                                    <div className="card shadow-sm document-item" >
                                        <div className='circle-check'>
                                            <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                        </div>
                                        <div className="card-body" onClick={() => handleToggleDialog(item)} style={{ position: 'relative', height: '100%' }}>
                                            <span className="truncate-text-2line">{item.documentName}</span>

                                            <div className="action-btn">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)', textAlign: 'center' }}>{item.isPublic && item.files.length ? 'download' : 'see details'}</div>

                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'>
                                                    <FontAwesomeIcon icon={faArrowRight} color="var(--lt-primary)" />
                                                    {/* <FontAwesomeIcon icon={faLock} /> */}
                                                </div>
                                            </div>

                                            {item?.isPublic && item.files.length ? (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLockOpen} color="var(--lt-primary)" /></div>
                                                </div>
                                            ) : (<div className="action-btn-lock">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLock} color="var(--lt-primary)" /></div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {documents.length > 6 && (<div onClick={toggleCollapse} className="row text-center collapse-btn mt-3 pointer">
                        <FontAwesomeIcon icon={faAngleDown} color="var(--lt-light)" />
                    </div>)}
                </div>
            </div>

            {openModal ? (
                <BulkDownload openModal={openModal} openFrom={'user'} handleClose={() => setOpenModal(false)} />
            ) : null}

            <Offcanvas show={showDialog} placement={'end'} style={{ backgroundColor: 'white', minWidth: 730 }} onHide={() => setShowDialog(!showDialog)}>
                <Offcanvas.Header closeButton className='border-bottom'>
                    <Offcanvas.Title><FontAwesomeIcon icon={faFileShield} style={{ color: 'var(--lt-primary)' }} /> <span>&nbsp;Compliance</span> </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul className="list-group-cards">
                        {dataCompliance.map((res: any, index: number) => (
                            <li ref={(el) => (cardRefs.current[index] = el)} className="" key={`tc-docu-${index}`}>
                                <div style={{ margin: '0', padding: '10px' }} className={`card shadow-sm pb-3 ${activeIndexcard === index ? 'rp-card-active' : 'rp-card'}`}>
                                    <div className={``}>
                                        <span className="d-flex flex-row align-items-start ">
                                            <span style={{ marginTop: 2, marginRight: 8 }}>
                                                <CheckMark height={18} width={18} color={'success'} />
                                            </span>
                                            <b className='ms-1'> {res.subTitle}</b> <a className='CopyLink d-none'>Copy link</a>
                                        </span>
                                        <p className="ps-4" style={{ paddingTop: '10px', marginLeft: '6px' }}>{res.description}</p>


                                        {(res?.files && res?.files.length > 0) ? (
                                            <FileGroupList sno={index} files={res?.files} isPublic={res?.isPublic} />
                                        ) : null}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>

            {isGetAccess ? (
                <GetAccessPermission openPopup={isGetAccess} onHide={() => {
                    setIsGetAccess(false);
                    setShowSecurityDialog(false);
                    getAccessCheck()
                }} />
            ) : null}

            {/* DOCUMENT CHANGES */}
            <Offcanvas placement={'end'} show={showSecurityDialog} style={{ minWidth: '730px', background: '#fff' }} onHide={() => setShowSecurityDialog(false)}>
                <Offcanvas.Header closeButton className='rp-header'>
                    <Offcanvas.Title className='rp-title'><FontAwesomeIcon className="checkicon" icon={faShieldHalved} color={'var(--lt-primary)'} />&nbsp;&nbsp;{tempObj && tempObj?.mainTitle ? tempObj?.mainTitle : ''}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {showSecurityDialog ? (
                        <SecutiryCardDetailPopup data={data?.securityList && data?.securityList.length ? data?.securityList : []}
                            onAccessRequest={() => {
                                setShowSecurityDialog(false);
                                setIsGetAccess(true);
                            }}
                            selectCardSubIndexNo={selectCardSubIndexNo} selectCardIndexNo={selectCardIndexNo} />
                    ) : null}
                </Offcanvas.Body>
            </Offcanvas>

        </div>
    );

};

export default Documents;
