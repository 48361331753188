// LoadingPage.tsx

import React, { useState } from 'react';
import './LoadingPage.css';


const LoadingPage: React.FC = () => {
   

    const handleDownloadClick = async () => {
        const url = "https://adyen-qna.s3.us-east-2.amazonaws.com/ayden_questionnare2.xlsx";
        const filename = "adyen_questionnare2.xlsx";

        const response = await fetch(url);
        if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link:any = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', filename); // Set the filename for download
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); // Clean up DOM
            window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
        } else {
            console.error('Failed to download file:', response.statusText);
        }
    };

  
return (
    <div className='containera'>
            {/* <div className='heading-diva' >
                    <img src={process.env.PUBLIC_URL+'assets/imgs/logo-secondary.png'} alt="logo" style={{ width: '72px' }} />
                    <p className='headinga'>adyen_questionnaire2</p>
            </div> */}
            
            {/* <p className='processinga'>Targhee AI Securely Processing Documents ...</p> */}
            {/* <button className='download' onClick={handleDownloadClick} >Download</button> */}
    </div>
);
};

export default LoadingPage;