import React, {useEffect, useRef, useState } from 'react';
import WithSidemenuHeader from '../../../Components/WithSidemenuHeader';
import SideMenu from '../../../Components/SideMenu';
import './LLMStyles.css';
import EnterpriseService from './../../../ApiServices/Enterprise.Service'
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {S3DownloadForLLMQuestionReview} from './../../../ApiServices/S3Bucket';

// Sample questions and answers
const LLMReview = () => {
    const [llmQuestionList, setLLMQuestionList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileLoading, setFileLoading] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<number>(-1);
    const [error, setError] = useState<string>('');
    const expandedRef = useRef<HTMLDivElement | null>(null);
    const data = useSelector((state: any) => state.AppReducer);
    const collectionName = useRef<string>('');
    const question = useRef<string>('');
    const textareaRef:any = useRef(null);
    
    const handleToggle = (index: number) => {
        const element = document.getElementById(`answer-${index}`);
        if (element) {
            const rect = element.getBoundingClientRect();
            const viewHeight = window.innerHeight || document.documentElement.clientHeight;
            const spaceBelow = viewHeight - rect.bottom;
            const spaceAbove = rect.top;

            const expandElement = element.querySelector('.horizontal-scroll-text-full') as HTMLDivElement;

            if (expandElement) {
                // Reset styles to default before recalculating
                expandElement.style.top = 'auto';
                expandElement.style.bottom = 'auto';

                if (spaceBelow >= expandElement.offsetHeight + 20) {
                    // Enough space below, position below
                    expandElement.style.top = '100%';
                    expandElement.style.bottom = 'auto';
                } else if (spaceAbove >= expandElement.offsetHeight + 20) {
                    // Not enough space below, but enough above, position above
                    expandElement.style.top = 'auto';
                    expandElement.style.bottom = '100%';
                } else {
                    // Not enough space either above or below, stick to the side closest to the viewport center
                    if (spaceBelow > spaceAbove) {
                        expandElement.style.top = '100%';
                        expandElement.style.bottom = 'auto';
                    } else {
                        expandElement.style.top = 'auto';
                        expandElement.style.bottom = '100%';
                    }
                }
            }
        }
        setIsExpanded(index);
    };


    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (expandedRef.current && !expandedRef.current.contains(event.target as Node)) {
                setIsExpanded(-1);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    const getLLMQuestions = (collectionName: string) => {
        EnterpriseService.getLLMQuestionsAnswers(collectionName).then((res: any) => {
            if (res && res.data && res.data.length) {
                console.log(res, 'res.data');
                const parsedData = JSON.parse(res.data);
                setLLMQuestionList([...parsedData]);
                setIsLoading(false)
            }
        })
    };

    const onChangeFileLoadData = (e: { target: { value: string; }; }) => {
        collectionName.current = (e.target.value);
        console.log(e.target.value, 'target!!!!');
        setIsLoading(true);
        setLLMQuestionList([]);
        getLLMQuestions(e.target.value);
    }

    const onGenrateAnswerOfQuestion = async () => {
        console.log(question.current, 'question.current');
        if (!question.current) {
            setError('Please enter question');
            return;
        }
        if (question.current.length < 10) {
            setError('Question length should be at least 10 characters.');
            return;
        }

        setFileLoading(true);

        let fileName: string = "";
        for (let i = 0; i < llmQuestionList.length; i++) {
            if (llmQuestionList[i].fileName) {
                fileName = llmQuestionList[i].fileName;
                break;
            }
        }

        const obj: any = {
            question: question.current,
            fileName: fileName,
            collectionName: collectionName.current,
        }

        const res: any = await EnterpriseService.genarateAnswerUsingLlm(obj)
        if (res && res.data) {
            question.current="";
            clearTextarea()
            setLLMQuestionList((prevItems:any) => [...prevItems, res.data]);
            setFileLoading(false);
        } else {
            setFileLoading(false);
            setError(res?.message || res?.error);
        }
    }

    const clearTextarea = () => {
        if (textareaRef.current) {
            question.current=""
          textareaRef.current.value = ''; // Clear the textarea
          textareaRef.current.focus(); // Optional: refocus the textarea
        }
    };


    const handleChange = (e: any) => {
        question.current = e.target.value;
        // setQuestion(e.target.value);
    };


    const onDownload = () => {
        console.log('down-2down-load');
        // onClick={onDownload}
        // download-source-llm-question-review 
        for (let i = 0; i < llmQuestionList.length; i++) {
            const el = llmQuestionList[i];
            console.log(el.bucketName, 'el.bucketName');
            console.log(el.fileName, 'el.fileName');
            
            if(el.fileName && el.bucketName){
                const obj={bucketName: el.bucketName, fileId:el.fileId, fileName:el.fileName}
                S3DownloadForLLMQuestionReview(obj).then((res:any)=>{

                })
                break;
            }
        }
        
    };

    /**
     * Handles the change in company selection, updates the selected company, 
     * and dispatches actions to fetch compliance and questionnaires data.
     * @param e - The change event from the company selection input.
    */
    //  const onChangeCompany = async (e: any) => {
    //     for (let i = 0; i < data.companyList.length; i++) {
    //         if(data.companyList[i]?._id==e.target.value){
    //             companyName.current=data.companyList[i]?.companyName;
    //             break;
    //         }
    //     }

    //     setIsLoading(true);
    //     setLLMQuestionList([]);
    //     getLLMQuestions(companyName.current);
    // };

    const QuestionList = ({ questions, onSelect }: any) => (
        <div className="question-list llm-card question-card">
            {questions.map((q: any, index: number) => (
                <div style={{ display: 'flex', gap: 15, flexDirection: 'row', justifyContent: 'space-around', borderBottom: index < questions.length - 1 ? '1px solid #ddd' : 'none', }}>
                    <div
                        key={q._id}
                        style={{ paddingBottom: 5, paddingTop: 10, width: '40%' }}
                        onClick={() => { }}
                    >
                        <h4 style={{ fontSize: 16 }}><b>{index + 1}.&nbsp;&nbsp;</b>{q.question}</h4>
                    </div>

                    <div
                        key={q.id}
                        id={`answer-${index}`}
                        ref={isExpanded === index ? expandedRef : null} // Set ref only when expanded
                        style={{ paddingBottom: 5, display: 'flex', flexDirection: 'row', paddingTop: 10, width: '60%', overflowX: 'auto', boxSizing: 'border-box', }}
                    >
                        <span>
                            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 6H9.01M15 6H15.01M15 12H15.01M9 12H9.01M9 18H9.01M15 18H15.01M10 6C10 6.55228 9.55228 7 9 7C8.44772 7 8 6.55228 8 6C8 5.44772 8.44772 5 9 5C9.55228 5 10 5.44772 10 6ZM16 6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 14.4477 5 15 5C15.5523 5 16 5.44772 16 6ZM10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12ZM16 12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12ZM10 18C10 18.5523 9.55228 19 9 19C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17C9.55228 17 10 17.4477 10 18ZM16 18C16 18.5523 15.5523 19 15 19C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18Z" stroke="#ddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>

                        {isExpanded === index ? (
                            <div className="horizontal-scroll-text-full">
                                <span onClick={() => handleToggle(-1)} style={{ fontSize: 18, position: 'absolute', right: 15, marginTop: -8 }}>&times;</span>
                                <p style={{ fontSize: 14, paddingTop: 15 }}>
                                    {q.result}
                                </p>
                            </div>
                        ) : (
                            <p className="horizontal-scroll-text" style={{ fontSize: 14 }} onClick={() => handleToggle(index)}>
                                {q.result}
                            </p>
                        )}
                    </div>
                </div>
            ))}

            <div style={{ display: 'flex', gap: 15, flexDirection: 'row', justifyContent: 'space-around', borderBottom: 'none' }}>
                <div
                    style={{ paddingBottom: 5, paddingTop: 10, width: '40%' }}
                    onClick={() => { }}
                >
                    <textarea ref={textareaRef} onChange={handleChange} className="form-control" rows={4}></textarea>
                    {error ? <label className={'text-danger'}>{error}</label> : null}
                </div>

                <div
                    id={`answer-input`}
                    style={{ paddingBottom: 5, display: 'flex', flexDirection: 'row', paddingTop: 10, width: '60%', overflowX: 'auto', boxSizing: 'border-box', }}
                >
                    <span>
                        <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 6H9.01M15 6H15.01M15 12H15.01M9 12H9.01M9 18H9.01M15 18H15.01M10 6C10 6.55228 9.55228 7 9 7C8.44772 7 8 6.55228 8 6C8 5.44772 8.44772 5 9 5C9.55228 5 10 5.44772 10 6ZM16 6C16 6.55228 15.5523 7 15 7C14.4477 7 14 6.55228 14 6C14 5.44772 14.4477 5 15 5C15.5523 5 16 5.44772 16 6ZM10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12ZM16 12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12ZM10 18C10 18.5523 9.55228 19 9 19C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17C9.55228 17 10 17.4477 10 18ZM16 18C16 18.5523 15.5523 19 15 19C14.4477 19 14 18.5523 14 18C14 17.4477 14.4477 17 15 17C15.5523 17 16 17.4477 16 18Z" stroke="#ddd" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>

                    <p className="horizontal-scroll-text" style={{ fontSize: 14 }}>
                        <Button disabled={fileLoading} onClick={onGenrateAnswerOfQuestion} variant='secondary'>Generate Answer</Button>
                        {fileLoading ? (<span style={{marginLeft:20}}>
                            <img src={`${process.env.PUBLIC_URL}/assets/imgs/ios-loading.gif`} style={{width:20, height:20, opacity:0.4}} />
                        </span>) :null}
                    </p>
                </div>
            </div>
        </div>
    );
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        const fetchCollections = async () => {
            try {
                const response = await EnterpriseService.getTestCollections();
                setCollections(response); // Assuming response is an array of collection names
                if (response.length > 0) {
                    collectionName.current=response[0];
                    getLLMQuestions(response[0]); // Set the first collection as the default selected option
                }
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };

        fetchCollections();
    }, []);

    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'LLM Question Review'} />
                <div className="router-container" style={{ position: 'relative' }}>
                    <div style={{ marginBottom: 12 }}>
                        <Row>
                            <Col md={9} lg={10} xl={10}>
                                <select
                                    className='form-select'
                                    style={{ maxWidth: 350 }}
                                    onChange={onChangeFileLoadData}
                                >
                                    {collections && collections.map((collection, index) => (
                                        <option key={index} value={collection}>{collection}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col md={3} lg={2} xl={2} className={'text-end pe-4'}>
                                {!isLoading && llmQuestionList && llmQuestionList.length ? (<img className='pointer' onClick={()=>onDownload()} style={{ width: 30, marginTop: 5, height: 'auto' }} src={`${process.env.PUBLIC_URL}assets/imgs/download.png`} />) : null}
                            </Col>
                        </Row>

                        {/* {data.companyList.length ? (
                            <section>
                                <div className="row">
                                <div className="col-lg-4">
                                    <select onChange={(e: any) => onChangeCompany(e)} className='form-select'>
                                    <option value="new">Create Vendor</option>
                                    {data.companyList.map((item: any, index: number) => {
                                        return <option value={item?._id} key={index}>{item.companyName}</option>
                                    })}
                                    </select>
                                </div>
                                </div>
                            </section>
                        ) : null} */}
                    </div>
                    <div className="question-container" style={{ width: '100%' }}>
                        {isLoading ? (
                            <div>Loading...</div>
                        ) : null}
                        {llmQuestionList && llmQuestionList.length ? <QuestionList questions={llmQuestionList} /> : null}
                    </div>
                </div>
            </section>
        </main>
    );
};

export default LLMReview;