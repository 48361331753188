import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ForgotPasswordModel } from './../../Models/ForgotPasswordModel';
import { NotificationModel } from './../../Models/SecurityModel';
import { postForgotPassord, userOtpValidation, userPasswordChange } from '../../ApiServices/ForgotPasswordApi';
import OtpInput from './../../Components/OtpInput';
import CheckMark from './../../Components/CheckMark';
import { useParams } from 'react-router-dom';


const ForgotPassword = () => {
    const [errPwd, setErrors] = useState<string[]>([])
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorEmail, setEmailError] = useState('');
    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });
    const [currentCard, setCurrentCard] = useState(1);
    const [touched, setTouched] = useState({
        password: false,
        confirmPassword: false,
    });
    const [touchedEmail, setTouchedEmail] = useState(false);
    const [otpError, setOtpError] = useState('');
    const [otpVerified, setOtpVerified] = useState(false);
    const { emailValue } = useParams();

    useEffect(() => {
        if(emailValue){
            const salt = "t@rgh33";
            try {
                let base64 = emailValue.replace(/-/g, '+').replace(/_/g, '/');
                let saltedEmail = atob(base64);
                let email = saltedEmail.slice(salt.length);

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailValue && emailRegex.test(email)) {
                    setCurrentCard(2);
                    setEmail(email);
                }
            } catch (error) {
                console.error(`error: Failed to fetch email Id`);
            }
        }
    }, [emailValue])

    

    // password validation start
    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setConfirmPassword(e.target.value);
        if (e.target.value != password) {
            setNotification({ type: 'error', msg: `Password and confirm Password not matching` });
        }
    };

    function capitalizeFirstLetter(str: string) {
        if (!str) return str; // Handle empty strings
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    // const isButtonDisabled = inputValue.trim() === '';

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (currentCard == 1) {
            if (!email) return;
            const obj = {
                email: email
            }
            const result: any = await postForgotPassord(obj); /**@POST_API */
            if (result && result.status == true) {
                setCurrentCard((prev: number) => prev + 1);
                setNotification({ type: 'success', msg: `OTP has beed sent to your ${email}` })
                setEmailError('');
            } else {
                setEmailError(capitalizeFirstLetter(result.error));
                setNotification({ type: 'error', msg: result.error });
            }
        }

        if (currentCard == 2) {
            setOtpError('');
            if (otp.length != 4) {
                setOtpError(`Please Enter 4 digit Valid OTP`);
            } else {
                setOtpError('');
                const result: any = await userOtpValidation({ otp: otp, email: email }); /**@POST_API */
                if (result && result.status == true) {
                    setNotification({ type: 'success', msg: `OTP Validated Successfully` });
                    setOtpVerified(true);
                    setTimeout(() => {
                        setOtpVerified(false);
                        setCurrentCard((prev: number) => prev + 1);
                    }, 2000);
                } else {
                    if (result.message == 'Invalid OTP') {
                        setOtpError(`Please Enter Valid OTP`);
                    } else if (result.message == 'OTP has expired') {
                        setOtpError(`We're sorry, but your OTP has expired. Please request a new OTP to proceed.`);
                    } else {
                        setEmailError(capitalizeFirstLetter(result.error));
                    }
                    setNotification({ type: 'error', msg: result.message });
                }
            }
        }
        if (currentCard == 3) {
            if (!password || !confirmPassword || confirmPassword !== password) {
                setTouched({
                    password: true,
                    confirmPassword: true,
                });
                return;
            }

            if (errPwd.length) {
                return;
            }

            if (confirmPassword != password) {
                setNotification({ type: 'error', msg: `Password and confirm Password not matching` });
            } else {
                const obj: any = {
                    "email": email,
                    "password": password,
                    "cnfpassword": confirmPassword
                }
                const result: any = await userPasswordChange(obj); /**@POST_API */
                if (result && result.status == true) {
                    setNotification({ type: 'success', msg: `Password changed Successfully` });
                    window.location.href = '/login';
                } else {
                    setNotification({ type: 'error', msg: result.message });
                }
            }
        }
    }

    const [formData, setFormData] = useState({
        card1Input: '',
        card2Input: '',
        card3Input: '',
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleBlur = (field: any) => () => {
        setTouched({
            ...touched,
            [field]: true,
        });
    };


    /**
     * Validates the provided password based on various criteria. Updates error state based on password validity.
     * @param password - The password to validate.
     * @param type - The type of password ('psw' or other).
     * @returns An array of validation errors.
     */
    const validatePassword = (password: string): string[] => {
        const errors: string[] = [];

        if (!password) {
            return ['This field is required'];
        }

        if (!/[a-zA-Z]/.test(password)) {
            errors.push("Password must contain at least one letter.");
        }

        if (!/[0-9]/.test(password)) {
            errors.push("Password must contain at least one number.");
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push("Password must contain at least one special character.");
        }

        if (password.length < 8) {
            errors.push("Password should be a minimum of 8 characters.");
        }

        setErrors(errors);
        return errors;
    };


    return (
        <main>
            <section className="forgot-sec" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'white' }}>
                <div className="container">
                    <Link to="login" className='text-decoration-none'>
                        <img src={process.env.PUBLIC_URL + '/assets/imgs/watermark.png'} alt="watermark" className="watermark pointer" />
                    </Link>
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="card-forgot">

                                {/* new card start */}
                                <div>
                                    {currentCard === 1 && (
                                        <div>
                                            <h2 className='text-white fs-3 mb-5'>Forgot Password</h2>
                                            <div className="forgot-email" style={{ width: 320, minWidth: 320 }}>
                                                <div className="form-item mt-4">
                                                    <input
                                                        type="email"
                                                        value={email}
                                                        autoFocus
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="input"
                                                        autoComplete="off"
                                                        id="Email-address"
                                                        required
                                                        onBlur={() => setTouchedEmail(true)}
                                                    />
                                                    <label htmlFor="Email-address">Email address</label>
                                                    {touchedEmail && !email && (
                                                        <div className="sc-input-err-msg" style={{ fontSize: 12 }}>Please enter registered Email ID.</div>
                                                    )}
                                                    {errorEmail && email && (
                                                        <div className="sc-input-err-msg" style={{ fontSize: 12 }}>{errorEmail}</div>
                                                    )}
                                                </div>
                                                {/* <div className="mt-4">
                                                            <label htmlFor="pass" className='text-start '>Enter valid email</label>
                                                            <input
                                                                type="email"
                                                                value={email}
                                                                className='form-control-cust bg-white rounded py-2'
                                                                placeholder='Enter Email'
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div> */}
                                            </div>
                                        </div>
                                    )}
                                    {currentCard === 2 && (
                                        <section>
                                            <OtpInput onChangeOtp={(e: any) => setOtp(e)}
                                            />
                                            <div className='text-center sc-input-err-msg mt-2'>{otpError}</div>
                                        </section>
                                    )}
                                    {currentCard === 3 && (
                                        <div className="forgot-input" style={{ minWidth: 320 }}>
                                            <div className="mt-3">
                                                <label htmlFor="pass" className=''>New Password</label>
                                                <input
                                                    type="password"
                                                    className='form-control-cust bg-white rounded py-2'
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    required
                                                    onBlur={handleBlur('password')}
                                                    onKeyUp={(e: any) => { validatePassword(e.target.value) }}
                                                />
                                                {touched.password && !password && (
                                                    <div className="sc-input-err-msg" style={{ marginTop: -10, fontSize: 12 }}>New password is required.</div>
                                                )}

                                                {errPwd && (typeof errPwd == 'object') && (
                                                    <div className="sc-input-err-msg">
                                                        {errPwd.map((msg: any, index: number) => (
                                                            <p style={{ fontSize: 12, marginTop: -10, }} className="p-0 m-0" key={index}>{msg}</p>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-3">
                                                <label htmlFor="Email" className=''>Confirm Password</label>
                                                <input type="password"
                                                    className='form-control-cust bg-white rounded py-2'
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPasswordChange}
                                                    required
                                                    onBlur={handleBlur('confirmPassword')}
                                                />
                                                {touched.confirmPassword && !confirmPassword && (
                                                    <div className="sc-input-err-msg" style={{ marginTop: -10, fontSize: 12 }}>Confirm password is required.</div>
                                                )}

                                                {confirmPassword && confirmPassword != password && (
                                                    <div className="sc-input-err-msg" style={{ marginTop: -10, fontSize: 12 }}>Password & confirm password should be equal.</div>
                                                )}
                                            </div>
                                        </div>

                                    )}
                                    <div className="text-center mt-4">
                                        <button type='button' className='save-btn px-4 m-auto' onClick={onSubmit}>{(currentCard < 3 ? 'Next' : 'Submit')}</button>
                                    </div>

                                    {otpVerified ? (
                                        <section style={{ position: 'relative', width: '100%' }}>
                                            <div style={{ marginTop: 70, position: 'absolute', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <CheckMark color={'success'} trigger={true} height={20} width={20} />
                                                <span>&nbsp;OTP has been Successfully verified.</span>
                                            </div>
                                        </section>
                                    ) : null}

                                </div>
                                {/* new card end */}

                                {/* {sections[currentSection]}
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button type='submit' className='save-btn px-4 m-auto' onClick={handleNext} disabled={isButtonDisabled}>
                                                    {currentSection === sections.length - 1 ? 'Submit' : 'Next'}
                                                </button>
                                            </div> */}

                                <p className='mt-4' style={{ position: 'absolute', bottom: '10px', left: '50px' }}><Link to="login" className='text-decoration-none'> Login </Link> </p>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default ForgotPassword