import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface PaginationComponentProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pager: React.FC<PaginationComponentProps> = ({totalPages, currentPage, onPageChange }) => {

  const handlePageChange = (page: number) => {
    console.log(page, 'page');
    if (page >= 1 || page <= totalPages) {
      console.log('onPageChange', page);
      
      onPageChange(page);
    }
  };

  const renderPaginationItems = () => {
    let items = [];
    let startPage = Math.max(currentPage - 3, 1);
    let endPage = Math.min(currentPage + 3, totalPages);

    console.log(endPage, 'endPage', startPage);
    

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(<Pagination.Ellipsis key="start-ellipsis" />);
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(<Pagination.Ellipsis key="end-ellipsis" />);
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination>
      {/* <Pagination.First onClick={() => handlePageChange(1)} /> */}
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
      {renderPaginationItems()}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      {/* <Pagination.Last onClick={() => handlePageChange(totalPages)} /> */}
    </Pagination>
  );
};

export default Pager;