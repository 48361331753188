const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;


export const getHeader = async (url: string) => {
    let baseHeaders: { [key: string]: string | null } = {
        'Content-Type': 'application/json',
        'Accept': 'application/json, text/plain, */*'
    };

    let token = localStorage.getItem(`${STORAGE_KEY}/token`)
    if (token) {
        baseHeaders['Authorization'] = `Bearer ${token}`;
    }
    
    let dbNameToken = localStorage.getItem(`${STORAGE_KEY}/dbName`);
    if (dbNameToken) {
        baseHeaders['DbName'] = `Bearer ${dbNameToken}`;
        //console.log(dbNameToken, "dbNameToken");
    }

    

    return baseHeaders;
};


export const logoutOnTokenExpire = (url: any) => {
    // console.log(url);
    try {
        if (url?.includes('/settings') || url?.includes('/overview') || url?.includes('/usercreate')) {
            // console.log(url, 'not matched B', url.includes('/overview'), '<==>', url.includes('/settings'));
        } else {
            localStorage.clear();
            window.location.href = '/login';
        }
    } catch (error) {
        console.log(error);
    }
}

// ${url}${headers?.token ? '?access_token=' + headers?.token : ''}
const ApiFetch = {
    // For Api get use this
    fetchGet: async function (url: any) {
        try {
            const headers: any = await getHeader(url);

            return fetch(`${process.env.REACT_APP_BASE_URL}${url}`, { method: 'GET', headers: headers })
                .then(async response => {
                    if (response.status !== 200) {
                        console.log(`Unexpected status code: ${response.status}`, response); // Log non-200 responses
                        const errorBody = await response.clone().json();
                        console.log(`Response body for status ${response.status}:`, errorBody); // Log the response body
                    }
                    if (response.status == 401) logoutOnTokenExpire(url);
                    return response.json();
                })
                .then(responseJson => {
                    return responseJson;
                })
                .catch(error => {
                    console.log('Fetch error:', error);
                });
        } catch (error) {
            console.log(error, "header error");
        }
    },

    // for Api Post use this
    fetchPost: async function (url: any, body: any) {
        const headers: any = await getHeader(url);
        const option: any = {
            method: 'POST',
            headers: headers,
            body: body
        };
        try {
            return await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, option)
                .then(async response => {
                    if (response.status !== 200) {
                        console.log(`Unexpected status code: ${response.status}`, response); // Log non-200 responses
                        const errorBody = await response.clone().json();
                        console.log(`Response body for status ${response.status}:`, errorBody); // Log the response body
                    }
                    const statusCode = response.status;


                    if (statusCode == 401) logoutOnTokenExpire(url);



                    return response.json();
                })
                .then(responseJson => {
                    return responseJson;
                })
                .catch(error => {
                    console.log('Fetch error:', error);
                    logoutOnTokenExpire(error);
                });




        } catch (error) {
            console.error('Fetch error:', error);
        }
    },

    fetchPostFlask: async function (url: any, body: any) {
        const headers: any = await getHeader(url);
        let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/DbName`);
        try {
            dbNameToken = dbNameToken.replace(/["]/g, '')
        } catch (error) {}
        headers['DbName'] = dbNameToken;
        let headers2 = {
            'Content-Type': 'application/json',
            'DbName': dbNameToken
        }
        const option: any = {
            method: 'POST',
            headers: headers2,
            body: body
        };
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}${url}`, option);
        const statusCode = response.status;
        if (statusCode == 401) logoutOnTokenExpire(url);
        if (statusCode == 401) console.log("401 response:", response);
        const data = await response.json();
        return Promise.all([statusCode, data]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    },


    fetchPut: async function (url: string, body: any) {
        const headers: any = await getHeader(url);
        return fetch(`${process.env.REACT_APP_BASE_URL}${url}`, {
            method: 'PUT',
            headers: headers,
            body: body,
        }).then(async response => {
            if (response.status !== 200) {
                console.log(`Unexpected status code: ${response.status}`, response); // Log non-200 responses
                const errorBody = await response.clone().json();
                console.log(`Response body for status ${response.status}:`, errorBody); // Log the response body
            }

            if (response.status == 401) logoutOnTokenExpire(url);
            return response.json()
        }).then(responseJson => {
            return responseJson;
        }).catch(error => {
            logoutOnTokenExpire(error)
        });
    },

    fetchDelete: async function (url: string, body: any) {
        const headers: any = await getHeader(url);
        return fetch(process.env.REACT_APP_BASE_URL + url, {
            method: 'DELETE',
            headers: headers,
            body: body,
        }).then(response => {
            if (response.status == 401) logoutOnTokenExpire(url);
            return response.json()
        }).then(responseJson => {
            return responseJson;
        }).catch(error => {
            logoutOnTokenExpire(error)
        });
    },
    // for Api Post use this
    fetchFormDataPost: async function (url: any, body: any) {
        const strToken: any = await localStorage.getItem(`${STORAGE_KEY}/token`);
        let headers = {
            'Content-Type': 'multipart/form-data',// this is a imp line
            'Authorization': strToken,
            Accept: 'application/json',
        };
        const option: any = { method: 'POST', headers: headers, body: body };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, option);
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    },
    fetchFlaskGet: async function (url: any) {
        try {
            const headers: any = await getHeader(url);
            return fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}${url}`, { method: 'GET', headers: headers })
                .then(async response => {return response.json();})
                .then(responseJson => {return responseJson;})
                .catch(error => {console.log('Fetch error:', error);});
        } catch (error) {
            console.log(error, "header error");
        }
    },
};
export default ApiFetch;