export class FileModel {
    fileName: string;

    constructor(fileName: string) {
        this.fileName = fileName || '';
    }
}

export interface DocumentModelNew{
    _id: string;
    documentName: string;
    isPublic: boolean;
    description: string;
    files: Items[];
}

export interface BulkDownloadModelNew{
    _id: string;
    documentName: string;
    isPublic: boolean;
    description: string;
    files: Files[];
}

export interface Files{
    _id: string;
    fileName:  string;
    checked: boolean;
}

export interface DocumentFormModel{
    items:Items[];
}

export interface Items{
    _id: string;
    documentName: string;
    isPublic:     boolean;
    description:  string;
    files:        any[]; 
}