export const types: any = {
    GET_COMPANY_LIST_REQUEST: 'GET_COMPANY_LIST_REQUEST',
    GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
    GET_COMPANY_LIST_FAILURE: 'GET_COMPANY_LIST_FAILURE',

    GET_COMPLIANCE_LIST_REQUEST: 'GET_COMPLIANCE_LIST_REQUEST',
    GET_COMPLIANCE_LIST_SUCCESS: 'GET_COMPLIANCE_LIST_SUCCESS',
    GET_COMPLIANCE_LIST_FAILURE: 'GET_COMPLIANCE_LIST_FAILURE',

    GET_QUESTIONNAIRE_LIST_REQUEST: 'GET_QUESTIONNAIRE_LIST_REQUEST',
    GET_QUESTIONNAIRE_LIST_SUCCESS: 'GET_QUESTIONNAIRE_LIST_SUCCESS',
    GET_QUESTIONNAIRE_LIST_FAILURE: 'GET_QUESTIONNAIRE_LIST_FAILURE',

    SET_SELECTED_REQUEST: 'SET_SELECTED_REQUEST',
    SET_SELECTED_SUCCESS: 'SET_SELECTED_SUCCESS',

    DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
    DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',

    GET_CLIENT_DETAILS_REQUEST: 'GET_CLIENT_DETAILS_REQUEST',
    GET_CLIENT_DETAILS_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',

    SET_AUTH_REQUEST: 'SET_AUTH_REQUEST',
    SET_AUTH_SUCCESS: 'SET_AUTH_SUCCESS',

    UPDATE_ENTERPRISE_REQUEST: 'UPDATE_ENTERPRISE_REQUEST',
    UPDATE_ENTERPRISE_SUCCESS: 'UPDATE_ENTERPRISE_SUCCESS',

    GET_TS_LIST_REQUEST: 'GET_TS_LIST_REQUEST',
    GET_TS_LIST_SUCCESS: 'GET_TS_LIST_SUCCESS',

    SET_COUNT_REQUEST: 'SET_COUNT_REQUEST',
    SET_COUNT_SUCCESS: 'SET_COUNT_SUCCESS',

    GET_TC_COMPLIANCE_LIST_REQUEST: 'GET_TC_COMPLIANCE_LIST_REQUEST',
    GET_TC_COMPLIANCE_LIST_SUCCESS: 'GET_TC_COMPLIANCE_LIST_SUCCESS',

    CHECK_SPECIAL_DOMAIN_REQUEST: 'CHECK_SPECIAL_DOMAIN_REQUEST',
    CHECK_SPECIAL_DOMAIN_SUCCESS: 'CHECK_SPECIAL_DOMAIN_SUCCESS',

    RESET_STATE_REQUEST: 'RESET_STATE_REQUEST',
    RESET_STATE_SUCCESS: 'RESET_STATE_SUCCESS',

    SET_ENTERPRISE_FILE_LOADING_REQUEST: 'SET_ENTERPRISE_FILE_LOADING_REQUEST',
    SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_SUCCESS: 'SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_SUCCESS',
    SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_SUCCESS: 'SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_SUCCESS',
    SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_FAILURE:'SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_FAILURE',
    SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_FAILURE:'SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_FAILURE',
    DELETE_ENTERPRISE_FILE_REQUEST:'DELETE_ENTERPRISE_FILE_REQUEST',

    SET_SNACK_BAR_ACTION_REQUEST: 'SET_SNACK_BAR_ACTION_REQUEST',
    SET_SNACK_BAR_ACTION_ERROR: 'SET_SNACK_BAR_ACTION_ERROR',
    SET_SNACK_BAR_ACTION_WARNING: 'SET_SNACK_BAR_ACTION_WARNING',
    SET_SNACK_BAR_ACTION_INFO: 'SET_SNACK_BAR_ACTION_INFO',
    SET_SNACK_BAR_ACTION_SUCCESS: 'SET_SNACK_BAR_ACTION_SUCCESS',
    SET_SNACK_BAR_ACTION_OFF: 'SET_SNACK_BAR_ACTION_OFF',


    SET_EMPTEY_CARD_ID: 'SET_EMPTEY_CARD_ID',
    IS_LOADING: 'IS_LOADING',
    REDIRECT_REQUEST: 'REDIRECT_REQUEST',
    REDIRECT_REQUEST_PARAMS: 'REDIRECT_REQUEST_PARAMS',
    IS_LOGGED_IN: 'IS_LOGGED_IN',
    RESET_STATE: 'RESET_STATE',
    SET_USER_TOKEN: 'SET_USER_TOKEN',
    SET_LOADING_REQUEST: 'SET_LOADING_REQUEST',
    SET_LOADING_SUCCESS: 'SET_LOADING_SUCCESS',
};