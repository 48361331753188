// Documents.tsx
import React, { useRef, useState, useEffect, useContext } from 'react';
import './Documents.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faCircleDown, faCircleCheck, faArrowRight, faAngleDown, faLockOpen, faLock, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Tabs from './../../../Components/Tabs';
import { DocumentModelNew, } from '../../../Models/EditorDocumentModel';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { getDocsList } from './../../../ApiServices/TrustCenter';
import ComplianceEditModel from './../OverviewComlianceTruestCenter/ComplianceEditModel';
import SecurityCardEdit from './../../../SecurityCard/SecurityCardEdit';
import ReactContext from './../../../ApiServices/ReactContext/ReactContext';
import BulkDownload from './../../../TrustCenter/BulkDownload';

const EditorDocuments: React.FC = () => {
    const tabNames = ['All', 'Public', 'Private'];
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [showSecurityDialog, setShowSecurityDialog] = useState(false);
    const [teampData, setTempData] = useState<DocumentModelNew[]>([]);
    const [documents, setDocuments] = useState<DocumentModelNew[]>([]);
    const [tempObj, setTempObj] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const contentRef: any = useRef(null);
    const [complianceDialog, setComplianceDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;

    useEffect(() => {
        fetchDocuments();
        console.log('wow');
    }, []);

    useEffect(() => {
        console.log('data document cards', data.reloadType);

        fetchDocuments();

    }, [data.reloadType])

    /**@FETCH_DOCUMENTS */
    const fetchDocuments = async () => {
        try {
            const docsapi: any = await getDocsList();
            if (docsapi && docsapi.data && docsapi.data.length) {
                setDocuments(docsapi.data);
                setTempData(docsapi.data);
            } else {
                setDocuments([]);
                setTempData([]);
            }
        } catch (err) {
            console.error(err); // Log the error
            setError('Failed to fetch documents');
        } finally {
            setLoading(false);
        }
    };

    const toggleCollapse = () => {
        setIsExpanded(!isExpanded);
    };

    /**@ON_CHANGE_TABS */
    const onChangeTabs = (type: string) => {
        if (type === 'Public') {
            setDocuments(teampData.filter((item: any) => item.isPublic == true));
        } else if (type === 'Private') {
            setDocuments(teampData.filter((item: any) => item.isPublic == false));
        } else {
            setDocuments(teampData);
        }
        setActiveTab(type);
    }

    const editNewTrustCenterCard = (item: any) => {
        if (item && item.type == "compliance") {
            getComplianceList();
            setTempObj(item);
            setTimeout(() => {
                setComplianceDialog(true)
            }, 300);
        } else if (item && item.type == "security") {
            reload('SECURITY_LIST');
            setTempObj(item);
            setTimeout(() => {
                setShowSecurityDialog(true)
            }, 300);
        }
    }

    const getComplianceList = () => {
        fetchDocuments();
        if (data.reloadType == 'GET_COMPLIANCE_LIST') {
            reload('REFRESH');
        } else {
            reload('GET_COMPLIANCE_LIST');
        }
    }

    const getSecurityList = () => {
        fetchDocuments();
        if (data.reloadType == 'SECURITY_LIST') {
            reload('REFRESH');
        } else {
            reload('SECURITY_LIST');
        }
    }

    return (
        <div className='wrapper' style={{ width: '100%' }}>
            <div className="document-card card shadow-sm h-auto">
                <div className="statuscard-header card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <section className="d-flex align-items-center">
                            <h2>
                                <div className="d-flex align-items-center header">
                                    <FontAwesomeIcon icon={faFileArrowDown} color="#014181" />
                                    <span className="ml-3" style={{ color: "var(--lt-primary)" }}>Documents</span>
                                </div>
                            </h2>

                            <div style={{ marginLeft:16, marginBottom: '5px' }}>
                                <Tabs tabs={tabNames} isEditor={true} onChangeTab={(e) => onChangeTabs(e)} />
                            </div>
                        </section>

                        <section className="d-flex flex-row align-items-center justify-content-center">
                            <button onClick={() => setOpenModal(true)} type="button" className="ml-auto btn btn-outline-primary mt-1" style={{ marginRight: 20 }}>
                                <span>
                                    <FontAwesomeIcon icon={faCircleDown} color="var(--lt-primary)" />
                                </span>
                                <span>&nbsp;&nbsp;Bulk Download</span>
                            </button>
                        </section>
                    </div>
                </div>
                <div className="card-body pt-0 pb-0" style={{ padding: 12 }}>
                    <div className="container h-auto mt-4 pb-0">
                        <div className="row">
                            {/* {loading ? (<div className='text-center'>
                                <Spinner style={{ color: 'var(--lt-text-primary)' }} animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>) : null} */}

                            {error ? (<Alert variant={'warning'} style={{ display: 'flex', }}><svg style={{ height: 20 }} viewBox="0 0 14 16"><path fill-rule="evenodd" d="M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"></path></svg><span>&nbsp;&nbsp;No Document list found</span></Alert>) : null}

                            {documents && documents.length == 0 ? (<div className='w-100 text-center'></div>) : null}

                            {documents.slice(0, 6).map((item: DocumentModelNew, index: number) => (
                                <div key={`used_${index}`.toString()} className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                                    <div className="card shadow-sm document-item" onClick={() => editNewTrustCenterCard(item)}>
                                        <div className='circle-check'>
                                            <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                        </div>
                                        <div className="card-body text-center" style={{ position: 'relative', height: '100%' }}>
                                            <span className="truncate-text-2line"  style={{ paddingTop: '6px' }}>{item?.documentName}</span>

                                            <div className="action-btn">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)', textAlign: 'center' }}>{item?.isPublic && item.files.length ? 'download' : 'see details'}</div>
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faArrowRight} color="var(--lt-primary)" />
                                                </div>
                                            </div>

                                            {item?.isPublic && item.files.length ? (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLockOpen} color="var(--lt-primary)" /></div>
                                                </div>
                                            ) : (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLock} color="var(--lt-primary)" /></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={`row mb-3 pb-2 collapse-content ${isExpanded ? 'expanded' : ''}`}
                            style={{ maxHeight: isExpanded ? contentRef.current.scrollHeight + 'px' : '0' }}
                            ref={contentRef}>
                            {documents.slice(6, documents.length).map((item: any, index: number) => (
                                <div key={`unused_${index}`.toString()} className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                                    <div className="card shadow-sm document-item" onClick={() => editNewTrustCenterCard(item)} >
                                        <div className='circle-check'>
                                            <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                        </div>
                                        <div className="card-body" style={{ position: 'relative', height: '100%' }}>
                                            <span className="truncate-text-2line">{item.documentName}</span>

                                            <div className="action-btn">
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)', textAlign: 'center' }}>{item?.isPublic && item.files.length ? 'download' : 'see details'}</div>
                                                <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faArrowRight} color="var(--lt-primary)" />
                                                </div>
                                            </div>

                                            {item?.isPublic && item.files.length ? (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLockOpen} color="var(--lt-primary)" /></div>
                                                </div>
                                            ) : (
                                                <div className="action-btn-lock">
                                                    <div style={{ fontSize: 12, color: 'var(--lt-primary)' }} className='text-center'><FontAwesomeIcon icon={faLock} color="var(--lt-primary)" /></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {documents.length > 6 && (<div onClick={toggleCollapse} className="row text-center collapse-btn mt-3 pointer">
                        <FontAwesomeIcon icon={faAngleDown} color="var(--lt-light)" />
                    </div>)}
                </div>
            </div>

            {openModal ? (
                <BulkDownload openModal={openModal} openFrom={'editor'} handleClose={() => setOpenModal(false)} />
            ) : null}

            {/* COMPLEANCE */}
            
            <ComplianceEditModel selectedObj={tempObj}  show={complianceDialog} onHide={() => setComplianceDialog(false)} getComplianceList={() => getComplianceList()} />
            

            {/* DOCUMENT CHANGES */}
            {showSecurityDialog ? (
                <SecurityCardEdit securityData={data?.securityList && data?.securityList.length ? data?.securityList : []} selectedObj={tempObj} getData={() => getSecurityList()} show={showSecurityDialog} onHide={() => setShowSecurityDialog(false)} />
            ) : null}

        </div>
    );
};

export default EditorDocuments;