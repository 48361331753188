import { useContext, useEffect, useRef, useState } from "react";
import { S3Upload, S3Download } from '../../../ApiServices/S3Bucket';
import { faDownload, faFilePdf, faFileShield, faImage } from '@fortawesome/free-solid-svg-icons'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckMark from '../../../Components/CheckMark';
import { userAccessCheck, createDownloadLogs } from '../../../ApiServices/TrustCenter';
import PrimaryButton from '../../../Components/Buttons/PrimaryButton';
import ReactContext from './../../../ApiServices/ReactContext/ReactContext';

interface Props {
    show: boolean;
    onHide: () => void;
    index: number;
    onAccessRequest: () => void;
}

const ComplienceRightSidePopup = ({ show, onHide, index, onAccessRequest }: Props) => {
    const [showComplianceDialog, setShowComplianceDialog] = useState(show);
    const [fileAccessPermission, setFileAccessPermission] = useState<boolean>(false);
    const context = useContext(ReactContext);
    const cardRefs: any = useRef([]);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData, reload } = context;

    const handleToggleDialog = () => {
        setShowComplianceDialog(!showComplianceDialog);
        setTimeout(() => { onHide() }, 200);
    };
    
    useEffect(() => {
        (async () => {
            setFileAccessPermission((await userAccessCheck() ));
        })();
        
        setTimeout(() => {
            scrollToIndex(index);
        }, 500);
    }, [index])


    const scrollToIndex = (index: number) => {
        if (cardRefs.current[index]) {
            cardRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const onFileDownload = async (index: number, fileIndex: number, isPublic:boolean) => {
        //Download file
        const obj: any = { ...data.complianceList[index] };
        let fileObj: any = obj.files[fileIndex];
        console.log(fileObj, 'fileObj');
        S3Download({ fileKey: fileObj._id, bucketName: 'mytest1-docs', newFileName: fileObj.fileName }).then((res: any) => {
            console.log(res, 'res');
            createDownloadLogs([fileObj.fileName], 'compliance', 'editor', isPublic);
        }).catch((err: any) => {
            console.log(err, 'err file');
        })
    };


    const FileGroupList=({sno, files, isPublic}:any)=>{
        if(isPublic==false && fileAccessPermission==false){
            return (
                <div style={{ marginLeft: 34 }}>
                    <PrimaryButton onClick={onAccessRequest}>Get Access</PrimaryButton>
                </div>
            )
        }

        return (
            <ul className="list-group list-group-flush tc-docs-list border-0">
                {files.map((item: any, fileIndex: number) => (
                    <li key={`compliance-files-list-${fileIndex}`} className="list-group-item">
                        <div className="row align-items-center">
                            <div className="col-8 col-lg-8 col-xl-8 text-truncate" >
                                <FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />
                                &nbsp;&nbsp;&nbsp;{item.fileName}</div>
                            <div className="col-4 col-lg-4 col-xl-4 tc-docs-action text-end">
                                <span>
                                    <FontAwesomeIcon icon={faDownload} color={'var(--lt-primary)'} />
                                    <a href="javascript:void(0)" onClick={() => onFileDownload(sno, fileIndex, isPublic)} className="view-download-link">Download</a>
                                </span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <div>
            <Offcanvas show={showComplianceDialog} placement={'end'} style={{ minWidth: 730 }} onHide={handleToggleDialog}>
                <form method="post" autoComplete={'off'} className="h-100">
                    <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ddd' }}>
                        <Offcanvas.Title>
                            <div className="title-header-dynamic">
                                <FontAwesomeIcon icon={faFileShield} color="var(--lt-primary)" />
                                <span>Compliance</span>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pe-0 pt-0 h-100 ps-0">
                        <div className="card" style={{ height: 'calc(100% - 10px)', maxHeight: 'calc(100% - 10px)', overflowY: 'hidden', minWidth: 500 }}>
                            <div className="card-body" style={{ padding: '15px', overflowY: 'auto', maxHeight: (window.innerHeight - 120) }}>
                                {data.complianceList.map((item: any, sno: number) => (
                                    <div ref={(el) => (cardRefs.current[sno] = el)} key={`crpop-${sno}`} className={`card sc-card-list shadow-sm pb-3`} style={{ border: (index == sno ? '3px solid #0a0a9c' : 'none') }}>
                                        <div className="form-group d-flex flex-row align-items-center">
                                            <div className="col-8 col-md-9 col-lg-9">
                                                <span style={{ display: 'inline-block' }}>
                                                    <CheckMark height={18} width={18} color={'success'} />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <span><b style={{ fontSize: 17 }}>{item.subTitle}</b></span>
                                                </span>
                                                <p style={{ paddingLeft: 32, paddingTop: 10, fontSize: 15 }}>{item.description}</p>
                                            </div>
                                        </div>
                                       
                                        {(item?.files && item?.files.length > 0) ? (
                                            <FileGroupList sno={sno} files={item?.files} isPublic={item?.isPublic} />
                                        ) :null}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Offcanvas.Body>
                </form>
            </Offcanvas>
        </div>
    )
}

export default ComplienceRightSidePopup;