import React, { useEffect, useState } from 'react';
import './StickyNavbar.css';
import { getSettingInfo, getSettingInfoRefresh,  SettingModel,getClientDetails } from './../ApiServices/SettingsApi';
import GetAccessPermission from './../TrustCenter/GetAccessPermission';
import { toastManager } from './../Components/ToastDialog/ToastManager';
import { userAccessRequest } from './../ApiServices/TrustCenter';
import { useParams } from 'react-router-dom';
import { set } from 'react-hook-form';


const StickyNavbar: React.FC = () => {
    const { userId } = useParams();
    const [isGetAccess, setIsGetAccess] = useState(false);
    const [settingInfo, setSettingInfo] = useState<SettingModel>({ _id: '', logo: '', companyTitle: '' });
    const [imageUrl, setImageUrl] = useState('');
    const [bucketName, setBucketName] = useState('');

    useEffect(() => {
        toSetImageUrl();
        // getAccessChecktest();
        if (userId==='requestaccess') {
            setIsGetAccess(true);
        }
    }, []);



    const toSetImageUrl = async () => {
        let res = await getClientDetails(true);
        if(res && res?.data && res?.data.length){
            setBucketName(res?.data[0].fileStorage);
            let settingsRes = await getSettingInfoRefresh(true); // Await the function call
            if (settingsRes?.data) {
                setImageUrl("https://" + res?.data[0] + ".s3.us-east-2.amazonaws.com/linkedin-logo.png" );
            } else {
                setImageUrl(process.env.REACT_APP_S3_PATH + 'linkedin-logo.png');
            }    
        }
    };

    const getAccessChecktest = async () => {
        // Uncomment and complete this function if you need to perform any checks

        // let email = "saikasam98@gmail.com";
        // let result: any = await userAccessRequest({ "email": email });
        // if (result && result.status && result.data && result.data.token) {
        //     localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`, JSON.stringify({ token: result.data.token, email: email }));
        // }

    };

    const handleGetAccessClick = () => {
        console.log('Get Access Clicked');
        setIsGetAccess(true);
        console.log('Get Access Clicked2', isGetAccess);
    };

    const handleClosePopup = () => {
        setIsGetAccess(false);
        
    };

    return (
        <nav className="navbar sticky-top">
            <div className="container-top">
                <div className="navbar-left">
                    <img src={imageUrl} alt="LinkedIn Logo" className="navbar-logo" />
                    <p className='spacer' style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        fontWeight: 300,
                        fontSize: '1.6rem',
                        letterSpacing: '1px',
                        color: '#ffffff',
                        marginLeft: '25px',
                        height: '100%'
                    }}>
                        Trust Center
                    </p>
                </div>
                <div className="navbar-right">
                    <button onClick={handleGetAccessClick} className="getaccess-button btn btn-primary">Get Access</button>
                    {isGetAccess && (
                        <GetAccessPermission openPopup={isGetAccess} onHide={handleClosePopup} />
                    )}
                </div>
            </div>
        </nav>
    );
};

export default StickyNavbar;