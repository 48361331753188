import React, { ReactNode } from 'react';
import './PrimaryButton.css';

interface Props{
    name?:string;
    children?: ReactNode;
    onClick?:()=>void;
    type?:'submit' | 'button',
    disabled?:boolean;
    className?:string;
}
const PrimaryButton = ({ name, onClick, children, type, disabled, className }:Props) => {
    const combinedClassName = `custom-btn ${className}`.trim();
    return (
        <button disabled={disabled ? true:false} style={{opacity:disabled ? 0.3:1}} type={type || 'button'} className={combinedClassName} onClick={onClick}>
            {name || children}
        </button>
    );
};


export default PrimaryButton;