// import { Alert } from "react-native";
import { types } from "../Types/SagaType";
import { ActionProps, InitialStateProps, FileQuestionModels } from './Models/Reducers.Model';
const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;

const INITIAL_STATE: InitialStateProps = {
    error: '',
    redirect: '',
    isLoading: false,
    companyList: [],
    complianceArrayList: [],
    questionArrayList: [],
    selectedQuestion: { _id: '', questions: [], company_id: '', fileName: '', fileId: '' },
    authData: { _id: '', firstName: '', lastName: '', email: '', admin: false, token: '' },
    securityList: [],
    tCenterComplianceList: [], /**@THIS_IS_FOR_TRUST_CENTER */
    isSpecialDomain: "",
    clientDetails:{_id:"",maindomain:"", complianceBucket:"",questionnairesBucket:"", cname:"", database:"",fileStorage:""},
    countReviewItem:{countOfApproved:0,countOfReview:0}
};

const AppReducer = (state: InitialStateProps = INITIAL_STATE, action: ActionProps) => {
    switch (action.type) {
        case types.SET_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: action.payload,
            };

        case types.REDIRECT_REQUEST:
            return {
                ...state,
                redirect: action.payload,
                error: null,
            };

        case types.GET_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companyList: action.payload,
                error: null,
            };

        case types.GET_COMPANY_LIST_FAILURE:
            return {
                ...state,
                companyList: action.payload,
                error: null,
            };

        case types.GET_COMPLIANCE_LIST_SUCCESS:
            return {
                ...state,
                complianceArrayList: action.payload,
                error: null,
            };
        case types.GET_COMPLIANCE_LIST_FAILURE:
            return {
                ...state,
                complianceArrayList: action.payload,
                error: null,
            };

        case types.GET_QUESTIONNAIRE_LIST_SUCCESS:
            return {
                ...state,
                questionArrayList: action.payload,
                error: null,
            };
        case types.GET_QUESTIONNAIRE_LIST_FAILURE:
            return {
                ...state,
                questionArrayList: action.payload,
                error: null,
            };

        case types.SET_SELECTED_SUCCESS:
            return {
                ...state,
                selectedQuestion: action.payload,
                error: null,
            };

        case types.SET_AUTH_SUCCESS:
            return {
                ...state,
                authData: action.payload,
                error: null,
            };

            case types.UPDATE_ENTERPRISE_SUCCESS:
                // First, update the selectedQuestion state
                const updatedSelectedQuestion = {
                    ...state.selectedQuestion,
                    questions: state.selectedQuestion.questions.map((question, index) =>
                        index === action.payload.index ? { ...question, ...action.payload.data } : question
                    ),
                };
            
                // Now, calculate countOfApproved and countOfReview using the updated state
                let countOfApproved = 0;
                let countOfReview = 0;
            
                if (updatedSelectedQuestion && updatedSelectedQuestion.questions && updatedSelectedQuestion.questions.length) {
                    for (let i = 0; i < updatedSelectedQuestion.questions.length; i++) {
                        const el = updatedSelectedQuestion.questions[i];
                        if (el && el.reviewstatus && !el.llmstatus) {
                            countOfReview++;
                        } else if (el.llmstatus) {
                            countOfApproved++;
                        }
                    }
                }
            
                return {
                    ...state,
                    selectedQuestion: updatedSelectedQuestion,
                    questionArrayList: state.questionArrayList.map(item => {
                        return {
                            ...item,
                            files: item.files.map((file: any) => {
                                return {
                                    ...file,
                                    questions: file.questions.map((question: FileQuestionModels) =>
                                        question._id === action.payload.questionId ? { ...question, ...action.payload.data } : question
                                    )
                                };
                            })
                        };
                    }),
                    countReviewItem: {
                        countOfApproved,
                        countOfReview,
                    },
                    error: null,
                };

        case types.GET_TS_LIST_SUCCESS:
            return {
                ...state,
                securityList: action.payload,
                error: null,
            };

        case types.GET_TC_COMPLIANCE_LIST_SUCCESS:
            return {
                ...state,
                tCenterComplianceList: action.payload,
                error: null,
            };

        case types.CHECK_SPECIAL_DOMAIN_SUCCESS:
            return {
                ...state,
                isSpecialDomain: action.payload,
            };

        case types.GET_CLIENT_DETAILS_SUCCESS:
            return {
                ...state,
                clientDetails: action.payload,
            };

        case types.SET_ENTERPRISE_FILE_LOADING_COMPLIANCE_SUCCESS:
            console.log(action.payload, 'action.payload in App Reducer');
            return {
                ...state,
                complianceArrayList:[...state.complianceArrayList, {...action.payload}]
            };
        case types.SET_ENTERPRISE_FILE_LOADING_QUESTIONERY_SUCCESS:
            return {
                ...state,
                questionArrayList:[...state.questionArrayList, {...action.payload}]
            };
       
        case types.SET_COUNT_SUCCESS:
            return {
                ...state,
                countReviewItem: action.payload,
            };
       
        case types.DELETE_COMPANY_FAILURE:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default AppReducer;