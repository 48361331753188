import React, { useContext, useEffect, useState } from 'react';
import './OverviewCompliance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faNewspaper, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { ComplianceModel, OverviewModel } from './../../Models/SecurityModel';
import { getOverviewUser, getComplianceData, setComplianceDataList } from './../../ApiServices/TrustCenter';
import ComplienceRightSidePopup from './../../Screens/TrustCenterEditor/ComplienceRightSidePopup';
import ReactContext from './../../ApiServices/ReactContext/ReactContext';
import GetAccessPermission from './../../TrustCenter/GetAccessPermission';

interface CompliencePopup {
    show: boolean;
    index: number;
}
const OverviewCompliance: React.FC = () => {
    const [complianceList, setComplianceList] = useState<ComplianceModel[]>([]);
    const [compliancePopup, setCompliancePopup] = useState<CompliencePopup>({ show: false, index: 0 });
    const [isGetAccess, setIsGetAccess] = useState(false);

    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, setData } = context;


    useEffect(() => {

        getComplianceList();
    }, []);

    useEffect(() => {
        if (data.reloadType == 'GET_COMPLIANCE_LIST' || data.reloadType == 'REFRESH') {
            getComplianceList();
            console.log('❌');
        }
    }, [data?.reloadType])



    /**@GET_GET_COMPLIANCE_LIST */
    const getComplianceList = async () => {
        const result: any = await getComplianceData(true);
        let temp: ComplianceModel[] = [];
        if (result && result.length) {
            for (let i = 0; i < result.length; i++) {
                temp.push({
                    _id: result[i]['_id'],
                    icon: result[i]['icon'],
                    files: result[i]['files'],
                    subTitle: result[i]['subTitle'],
                    description: result[i]['description'],
                    isPublic: result[i]['isPublic'],
                })
            }
        }

        setData({ ...data, complianceList: temp });
        setComplianceList(temp);
        setComplianceDataList(temp);
    }


    const openCompliancePopup = (index: number) => {
        setData({ ...data, complianceList: complianceList });
        setCompliancePopup({ show: true, index: index });
    }

    const onAccessRequestPermision = () => {
        setIsGetAccess(true);
        setCompliancePopup({ show: false, index: 0 })
    }

    return (
        <div className='wrapper'>
            <div className="row">
                <div className="col-lg-12 mb-4">
                    <div className="compliance-sec h-100">
                        <div className="statuscard-headerr">
                            <h2 className="complaince-text">Compliance</h2>
                        </div>

                        <div className="d-flex flex-wrap dolly3">
                            {complianceList.map((item: any, index: number) => {
                                return (
                                    <div
                                        onClick={() => openCompliancePopup(index)}
                                        className="d-flex  align-items-center col-6 col-md-3 col-lg-2 dolly1 pointer"
                                        key={`ts-tem-compl-${index}`}
                                    >
                                        <div className="d-flex flex-column align-items-center  dolly2" >
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/imgs/compliance/${item?.icon}`}
                                                style={{ width: 55, height: 55 }}
                                                alt={`Avatar ${item?.id}`}
                                            />
                                            <p
                                                className="text-center ellipsis-2l"
                                                style={{ color: 'var(--lt-light)', fontSize: 15, marginTop: '7px' }}
                                            >
                                                <div className="circle-tick">
                                                    <FontAwesomeIcon icon={faCircleCheck} color="var(--lt-success)" />
                                                </div>
                                                {item.subTitle}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {compliancePopup.show ? (
                        <ComplienceRightSidePopup onAccessRequest={() => onAccessRequestPermision()} show={compliancePopup.show} index={compliancePopup.index} onHide={() => setCompliancePopup({ show: false, index: 0 })} />
                    ) : null}

                    {isGetAccess ? (
                        <GetAccessPermission openPopup={isGetAccess} onHide={() => setIsGetAccess(false)} />
                    ) : null}
                </div>
            </div>
        </div>
    );

};

export default OverviewCompliance;
