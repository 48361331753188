// EnterpriseApps.tsx
import Navbar from '../Components/Navbar/Navbar';
import './TrustCenter.css';
import React, { useEffect, useState } from 'react';
import StickyNavbar from '../StickyNavbar/StickyNavbar';
import SecurityCard from './../SecurityCard/SecurityCard';
import Documents from './Documents';
import SecurityReview from './SecurityReview';
import OverviewCompliance from './OverviewCompliance';
import CollapsibleOverview from './Overview';


const TrustCenter: React.FC = () => {
  
  useEffect(()=>{
    console.log('TrustCenter +++');
    
    return () => {
      console.log('Cleanup called');
    };
  },[]);

  return (
    <div className='containerr'>
      <StickyNavbar />
      <div className='fullPage'>
        <div className="overview-container">
          <CollapsibleOverview />
        </div>
        <div className="compliance-container">
          <SecurityReview />
        </div>
        <div className="document-container">
          <OverviewCompliance />
        </div>
        <div className="document-container mb-4">
          <Documents />
        </div>
        <div className="securitycard-container mb-4">
          <SecurityCard />
        </div>
      </div>
    </div>
  );

};

export default TrustCenter;
