import React, { ReactNode } from "react";
import './IconButton.css';
interface Props{
    children:ReactNode;
    onClick:(e:any)=>void;
    className?:string;
    style?:any;
    varient?:'Text' | 'Circle',
    type?:'submit' | 'button'
}
const IconButton = ({ children, onClick, className, style, varient, type }:Props) => {
    const [coords, setCoords] = React.useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = React.useState(false);
  
    React.useEffect(() => {
      if (coords.x !== -1 && coords.y !== -1) {
        setIsRippling(true);
        setTimeout(() => setIsRippling(false), 300);
      } else setIsRippling(false);
    }, [coords]);
  
    React.useEffect(() => {
      if (!isRippling) setCoords({ x: -1, y: -1 });
    }, [isRippling]);
  
    return (
      <button type={type || 'button'}
        className={`ripple-button ${className} ${varient && varient=='Circle' ? 'circle':'text'}`} style={{...style}}
        onClick={(e:any) => {
          const rect = e.target.getBoundingClientRect();
          setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
          onClick && onClick(e);
        }}
      >
        {isRippling ? (
          <span
            className="ripple"
            style={{
              left: coords.x,
              top: coords.y
            }}
          />
        ) : (
          ''
        )}
        <span className="content">{children}</span>
      </button>
    );
  };
  export default IconButton;