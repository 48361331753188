import { useContext, useEffect, useRef, useState,useCallback } from "react";
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { S3Upload, S3Download } from '../../ApiServices/S3Bucket';
import { NotificationModel, SecurityFormModel } from '../../Models/SecurityModel';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckMark from '../../Components/CheckMark';
import { FileModel } from '../../Models/EditorDocumentModel';
import { confirmationManager } from './../../Components/ConfirmationDialog/ConfirmationManager';
import IconButton from './../../Components/Buttons/IconButton';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import { faPlus, faPersonMilitaryPointing, faTrash, faFilePdf, faDownload, faImage } from '@fortawesome/free-solid-svg-icons';
import { createDownloadLogs, addAndUpdateSecurityQuestion, deleteAllSecurity, bulkDelete, saveHistory, getAuth, updateSubItems, addSubItems, deleteSubItems } from './../../ApiServices/TrustCenter';
import ReactContext from './../../ApiServices/ReactContext/ReactContext';
import History from './../../Screens/TrustCenterEditor/History';
import { Col, Row } from "react-bootstrap";
import { getValue } from "@testing-library/user-event/dist/utils";
import { useDispatch } from 'react-redux';
import { getTSList } from './../../Redux/Actions/SagaAction';

function debounce<T extends (...args: any[]) => Promise<void>>(
    func: T,
    wait: number,
    stateMap: Map<number, { timeout: ReturnType<typeof setTimeout> | null, isDebounced: boolean }>
  ) {
    return function (this: any, ...args: Parameters<T>): Promise<void> {
      return new Promise((resolve, reject) => {
        const [index, ...rest] = args;
  
        if (!stateMap.has(index)) {
          stateMap.set(index, { timeout: null, isDebounced: true });
  
          let isNewState = true;
          console.log('no value found Setting Value', index, isNewState);
  
          func.apply(this, [index, ...rest, isNewState])
            .then(resolve)
            .catch(reject);
        } else {
          const state = stateMap.get(index)!;
  
          // Always clear the previous timeout if it exists
          if (state.timeout) {
            clearTimeout(state.timeout);
          }
          let isNewState = false;
          // Set a new timeout to call the function after the wait period
          console.log('value Found', index, isNewState);
          state.timeout = setTimeout(() => {
            func.apply(this, [index, ...rest, isNewState])
              .then(resolve)
              .catch(reject);
          }, wait);
  
          // Update the stateMap with the new timeout
          stateMap.set(index, state);
        }
      });
    };
  }

interface Props {
    securityData: any;
    show: boolean;
    onHide: () => void;
    getData: () => void;
    selectedObj?: SecurityFormModel;
}

const SecurityCardEdit = ({ show, onHide, getData, securityData, selectedObj }: Props) => {
    const [showHistory, setShowHistory] = useState(false);
    const [currentIndexMsg, setCurrentIndexMsg] = useState<any>({ msg: '', indexNo: -1 });
    const [securityId, setSecurityId] = useState('');
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadAnimation, setLoadAnimation] = useState<boolean>(false);
    const subDataRef = useRef<any[]>([]);
    const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [renderAnimation, setRenderAnimation] = useState<boolean>(true);
    const [shouldClose, setShouldClose] = useState(false);
    const dispatch=useDispatch();
    const formRef: any = useRef(null);
    const { control, handleSubmit, formState: { errors }, watch, reset, setValue, getValues } = useForm<SecurityFormModel>({
        defaultValues: {
            mainTitle: '',
            _id: '',
            subData: []
        }
    });

    const { fields, append, remove, update } = useFieldArray({ control, name: 'subData' });

    const stateMap = useRef(new Map<number, { timeout: ReturnType<typeof setTimeout> | null, isDebounced: boolean }>()).current;
    const clearDebounceState = () => {
        stateMap.forEach((state, key) => {
            if (state.timeout) {
                clearTimeout(state.timeout);
            }
        });
        stateMap.clear();
    };
    
    
    

    const context = useContext(ReactContext);
    if (!context) throw new Error('MyContext.Provider is missing');
    const { data, reload } = context;
    const [checkMarkStates, setCheckMarkStates] = useState<{ [key: number]: { color: string, refresh: boolean, trigger: boolean } }>({});
    const [checkMarkStateHeader, setCheckMarkStateHeader] = useState<{ color: string, refresh: boolean, trigger: boolean }>({ color: '', refresh: false, trigger: false });

    const resetCheck = (index: number) => {
        console.log('resetCheck', index);
        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'success', refresh: true, trigger: !checkMarkStates[index]?.trigger }
        }));


    };

    const resetCheckInit = (index: number) => {
        console.log('resetCheck2', index);
        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'success', refresh: true, trigger: true }
        }));


    };

    const resetHeader = (index: number) => {

        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'success', refresh: true, trigger: !checkMarkStates[index]?.trigger }
        }));


    };

 
    const resetCheckInitHeader = () => {
        setCheckMarkStateHeader({
            color: 'success',
            refresh: true,
            trigger: true
        });
    };

    /**@EFFECT : Store initial values to compare with current form values */
    const initialFormValues = useRef<SecurityFormModel | null>(null);



    const handleClose = () => {
        if (initialFormValues.current) {
            reset(initialFormValues.current);
        }
        clearDebounceState();
        fields.forEach((_, index) => {
            resetCheck(index);
        });
        // Call the original onHide function
        onHide();
    };


    // Use effect to call onHide after setCheckMarkStates completes
    useEffect(() => {
        if (shouldClose) {
            handleClose();
            setShouldClose(false); // Reset the flag
        }
    }, [shouldClose, onHide]);

    /**@EFFECT : Reset unsaved values and set all check marks to success when the Offcanvas is closed */
    useEffect(() => {
        if (!show) {
            handleClose();
        }
    }, [show]);



    useEffect(() => {
        // Ensure that checkMarkStates are reset when the Offcanvas opens
        if (show) {
            if (fields){
                fields.forEach((_, index) => {
                resetCheck(index);
                });
            }
        }
    }, [show]);

    /**@EFFECT : Update security question data when securityData or selectedObj changes */
    useEffect(() => {
        
        //if maintitle
        if (securityData && !selectedObj?._id && securityData?.mainTitle) {
            setSecurityId(securityData._id);
            updateSecutityQuestion(securityData);
            initialFormValues.current = securityData;
        //if selectedObj
        } else if (selectedObj && selectedObj?._id) {
            setSecurityId(selectedObj?._id);
            if (data && data.securityList.length) {
                for (let i = 0; i < data.securityList.length; i++) {
                    if (data.securityList[i]._id === selectedObj?._id) {
                        updateSecutityQuestion(data.securityList[i]);
                        initialFormValues.current = data.securityList[i];
                        break;
                    }
                }
            }
        } else {
            //Setting default values
            setSecurityId('');
            if (securityData['isNewCard']) {
                reset({
                    mainTitle: '',
                    _id: '',
                    subData: []
                });
                append({ title: '', description: '', isPublic: false, files: [] });
                subDataRef.current=[{ title: '', description: '', isPublic: false, files: [] }];
                initialFormValues.current = {
                    mainTitle: '',
                    _id: '',
                    subData: [{
                        title: '',
                        description: '',
                        isPublic: false,
                        files: []
                    }]
                };
                changeToGrayNoDebounce(0, "",stateMap);
            }
        }
        setIsLoading(false);

        return () => {
            subDataRef.current=[];
        }

    }, [securityData]);


    
    /**@UPDATE : Update security question data */
    const updateSecutityQuestion = (data: any) => {
        reset({
            mainTitle: data?.mainTitle,
            _id: data?._id,
            subData: []
        });
        for (let i = 0; i < data.subData.length; i++) {
            const inputItems:any={ title: data.subData[i].title, description: data.subData[i].description, isPublic: data.subData[i].isPublic, _id: data.subData[i]._id, files: data.subData[i].files, isUpdated: false };
            append(inputItems);
            subDataRef.current.push(inputItems)
            resetCheckInit(i);
            
        }
    };

    /**@DELETE : Handle deletion of all security data */
    const handleDeleteAll = () => {
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete this item?',
            onConfirm: async () => {
                const watchedValues = watch();
                /**@BULK_DELETE_FROM_S3_BUCKET_START */
                let data: any = watchedValues['subData'];
                let deleteList: any = [];
                for (let i = 0; i < data.length; i++) {
                    const elI = data[i]['files'];
                    for (let j = 0; j < elI.length; j++) {
                        deleteList.push(elI[j]);
                    }
                }
                bulkDelete(deleteList, "mytest1-docs");
                /**@BULK_DELETE_FROM_S3_BUCKET_END */

                const result: any = await deleteAllSecurity(watchedValues?._id);
                if (result && result.status) {
                    getData();
                    onHide();
                    reload('a');
                }
            },
            onCancel: () => {
                // Handle the cancel action
            },
        });
    };


    /**@SUBMIT_DOCUMENT_FORM : Handle form submission */
    const onSubmit: SubmitHandler<SecurityFormModel> = async (data) => {
        setIsLoading(true);
        const obj: any = data;
        let _id: string = data?._id;
        delete obj._id;
        const result: any = await addAndUpdateSecurityQuestion(_id, obj); /**@UPADTE_API */
        if (result && result.status == true) {
            setIsLoading(false);
            reload('a');
            getData();
            dispatch(getTSList(true));
        } else {
            setIsLoading(false);
        }
    }

    
    /**@SAVE_HEADER : Handle save header action */
    const onSaveHeader = async (data: SecurityFormModel) => {
        setIsLoading(true);
        const obj: any = data;
        let _id: string = data?._id;
        delete obj._id;

        try {
            const result: any = await addAndUpdateSecurityQuestion(_id, obj); /**@UPADTE_API */
            if (result && result.status == true) {
                setIsLoading(false);
                reload('a');
                getData();
                resetCheck(0);
                // resetCheckInitHeader()
            } else {
                throw new Error('API response indicates failure');
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    /**@SAVE_HEADER : Example usage of onSaveHeader with a save button */
    const handleSaveClick = (data: SecurityFormModel) => {
        onSaveHeader(data);
        

    };

    /**@SUBMIT_FORM : Handle form submission */
    const onSubmitForm = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    }

    /**@HISTORY : Add history entry */
    const addHistory = (objBody: any) => {
        const auth = getAuth();
        let reqBody: any = {
            ...objBody,
            firstName: auth.firstName,
            lastName: auth.lastName,
            email: auth.email,
            userId: auth._id
        };
        if (Object.keys(reqBody).length) {
            saveHistory([reqBody]).then((res: any) => {

            })
        }
    }

    const updateItem = async (index: number, value: boolean) => {
        // await resetGray(index);
        changeToGrayNoDebounce(index, "",stateMap);

        update(index, { ...watch().subData[index], isPublic: value });
        
        // await changeToGray(index, ""); // Fix: Pass an empty string as the third argument
        
        
    };
    
    /**
     * @DOCUMENT_FILE_UPLOAD
     * Handles the upload of a document file for a specific sub-item in the form data.
     * @param event - Change event containing the file to upload.
     * @param index - Index of the sub-item in the form.
    */
    const onDocumentFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        console.log('index', index);
        if (event.target.files) {
            const objW: any = watch();
            const obj = JSON.parse(JSON.stringify(objW));
            const mainTitle: string = obj['mainTitle'] ? obj['mainTitle'].trim() : '';
            if (!mainTitle) {
                setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: 'Please Enter Group Name' }));
                return;
            } else {
                setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: '' }));
            }

            setIsFileLoading(true);
            const _id = obj._id;
            delete obj._id;
            const newFile = new FileModel(event.target.files[0].name);
            obj.subData[index].files.push(newFile);
            const subObjId = obj.subData[index]._id;

            const result = await updateSubItems(subObjId, obj.subData[index], _id)
            if (result && result.status) {
                const subObj = result.data.subData

                let updatedSubData = subObj.filter((subObjItem: { _id: any; }) => subObjItem._id === subObjId)
                    .map((subObjItem: { files: any; }) => {
                        return {
                            ...subObjItem,
                            files: [...subObjItem.files, newFile]
                        };
                    });

                updatedSubData = updatedSubData[0];
                updatedSubData.files.pop();

                let fileId: string = updatedSubData.files[updatedSubData.files.length - 1]._id;
                console.log('res 321');
                S3Upload({ file: event.target.files[0], fileId: fileId, bucketName: 'mytest1-docs', folderName: 'security-card' }).then((res: any) => {
                    console.log('res 123', res);
                    if(res.status==200){
                        console.log('res 1234');
                        setValue(`subData.${index}._id`, subObjId);
                        setValue(`subData.${index}.files`, updatedSubData.files);
                        resetCheck(index);
                        console.log('resetCheck in file upload', index);
                        getData();
                        reload('a');
                        update(index, { ...watch().subData[index], _id: updatedSubData._id, files: updatedSubData.files });
                    }else{
                        fileUploadFailedHandleForSecurityCard(obj, newFile, index, _id, subObjId, updatedSubData);
                    }
                    setIsFileLoading(false);
                }).catch((err: any) => {
                    console.log('res 456');
                    fileUploadFailedHandleForSecurityCard(obj, newFile, index, _id, subObjId, updatedSubData);
                    setIsFileLoading(false);
                })
            } else {
                setIsFileLoading(false);
            }
        }
    }

    /**
     * Handles the case when a file upload fails for a security card by removing the failed file
     * and updating the corresponding sub-data object.
     * 
     * @param {any} obj - The main object containing the sub-data array.
     * @param {any} newFile - The new file object that was attempted to be uploaded.
     * @param {number} index - The index of the sub-data item in the subData array.
     * @param {string} _id - The ID of the main object to be updated.
     * @param {string} subObjId - The ID of the sub-data object to be updated.
     * @param {any} updatedSubData - The updated sub-data object containing the new files and ID.
     */
     const fileUploadFailedHandleForSecurityCard=async(obj:any, newFile:any, index:number, _id:string, subObjId:string, updatedSubData:any)=>{
        if(obj.subData[index] && obj.subData[index].files.length){
            const fileName=obj.files[obj.subData[index].files.length-1]['fileName'];
            if(fileName==newFile.fileName){
                obj.files.pop();
                const result = await updateSubItems(subObjId, obj.subData[index], _id);
                setValue(`subData.${index}._id`, subObjId);
                setValue(`subData.${index}.files`, updatedSubData.files);
                setValue(`subData.${index}.isUpdated`, false);
                update(index, { ...watch().subData[index], _id: updatedSubData._id, files: updatedSubData.files });
               
             }
         }
        console.log('file upload failed');
    }

    /**
     * @FILE_DOWNLOAD
     * Handles the download of a file associated with a specific sub-item in the form data.
     * @param index - Index of the sub-item in the form.
     * @param fileIndex - Index of the file to download within the sub-item.
     * @param isPublic - Flag indicating if the file is public.
    */
    const onFileDownload = async (index: number, fileIndex: number, isPublic: boolean) => {
        const obj: any = { ...watch().subData[index] };
        let fileObj: any = obj.files[fileIndex];
        S3Download({ fileKey: fileObj._id, bucketName: 'mytest1-docs', newFileName: fileObj.fileName }).then((res: any) => {
            createDownloadLogs([fileObj.fileName], "Security Card", "editor", isPublic);
        }).catch((err: any) => {
            console.log(err, 'err file');
        })
    };

    /**
     * @FILE_DELETE
     * Handles the deletion of a file associated with a specific sub-item in the form data.
     * @param index - Index of the sub-item in the form.
     * @param fileIndex - Index of the file to delete within the sub-item.
    */
    const onFileDelete = async (index: number, fileIndex: number) => {
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete?',
            onConfirm: async () => {
                const obj: any = { ...watch().subData[index] };
                console.log('obj on delete', obj);

                /**@DELETE_FROM_S3_BUCKET*/
                let _deleteFiles: any = obj?.files[fileIndex] || [];
                bulkDelete([{ ..._deleteFiles }], "mytest1-docs");

                const id = obj._id;
                obj['files'] = obj.files.filter((item: any, sno: number) => sno != fileIndex);
                delete obj['_id']; /**@THIS_DELETE_ID_REQUIRED_FOR_UPDATE */
                // const result = await addAndUpdateSecurityQuestion(id, obj);
                console.log("before delete",id, obj, securityId);
                const obj2: any = watch();
                const _id = obj2._id;
                delete obj2._id;
                const result = await updateSubItems(id, obj, _id);
                
                if (result && result.status) {
                
                    setValue(`subData.${index}.files`, obj['files']);
                    update(index, { ...watch().subData[index], files: obj['files'] });
                    reload("a");
                    getData();
                    resetGray(index);
                    changeToGray(index, "");
                } else {
                }
            },
            onCancel: () => {
            },
        });
    }

    /**@CHOOSE_FILE_FROM_EXPLORER */
    const chooseFile = (index: number) => {
        if (fileInputRefs.current[index]) {
            fileInputRefs.current[index]?.click();
        }
    };

    /**
     * @ADD_NEW_CARD
     * Adds a new card item to the form data and updates the state.
    */
    const addNewCard = () => {
        changeToGrayNoDebounce(fields.length, "",stateMap);
        append({ title: '', description: '', isPublic: false, files: []});
        subDataRef.current.push({ title: '', description: '', isPublic: false, files: []});
    }

    /**
     * @DELETE_SECURITY_CARD
     * Deletes a security card item from the form data and updates the state.
     * @param index - Index of the item in the form.
    */
    const onSecurityCardDelete = (index: number) => {
       
        confirmationManager.requestConfirmation({
            message: 'Are you sure you want to delete?',
            onConfirm: async () => {
                /**@NEED_TO_DO */
                

                const obj2: any = watch();
                const _id = obj2._id;
                delete obj2._id;
                
                
                
                const obj: any = { ...watch().subData[index] };
                const objId = obj['_id'];

                remove(index);
                if (objId) {
                    
                
                deleteSubItems(_id, objId).then((res:any)=>{
                
                    subDataRef.current.splice(index, 1);

                    /**@DELETE_FROM_S3_BUCKET*/
                    let _deleteFiles: any = obj?.files || [];
                    bulkDelete([..._deleteFiles], "mytest1-docs");
                    reload("a");
                    getData();
                })
            }

                
            },
            onCancel: () => {
            },
        });
    }

    /**
     * @UPDATE_CARD_ITEM
     * Updates an existing card item in the form data and updates the state.
     * @param index - Index of the item in the form.
    */
   
    const onUpdateCardItem = (index: number) => {


        
      
        const obj: any = { ...watch().subData[index] };
        const subId: string = obj['_id'];
        const parentId: string = watch()._id;
        if (obj['_id']) delete obj['_id'];

        const data: any = JSON.parse(JSON.stringify(watch()));
        const mainTitle: string = data['mainTitle'] ? data['mainTitle'].trim() : '';
        if (!mainTitle) {
            setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: 'Please Enter Group Name' }));
            return;
        } else {
            setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: '' }));
        }

        updateSubItems(subId, obj, parentId).then((res: any) => {
            if (res.status) {                
                subDataRef.current[index]['title']=obj.title;
                subDataRef.current[index]['description']=obj.description;
                addHistory({...obj, _id:subId, status:'update', type:'Security Card'});
                setIsLoading(false);
                reload('a');
                getData();
                resetCheck(index);
                resetGray(index);
            }
        });
    }
    const resetGray = async (index: number): Promise<void> => {
        return new Promise((resolve) => {
            if (stateMap.has(index)) {
                const state = stateMap.get(index)!;
                if (state.timeout) {
                    clearTimeout(state.timeout);
                }
    
                stateMap.delete(index);
            }
            resolve();
        });
    };
    /**
     * @ADD_NEW_CARD_ITEMS
     * Adds a new sub-card item to the form data and updates the state.
     * @param index - Index of the item in the form.
    */
    const addSubCardItems = (index: number) => {
      
        
      
        const obj: any = { ...watch().subData[index] };
        console.log('obj on addSubCardItems', obj);
        const parentId: string = watch()._id;
        setIsLoading(true);

        const data: any = JSON.parse(JSON.stringify(watch()));
        const mainTitle: string = data['mainTitle'] ? data['mainTitle'].trim() : '';
        if (!mainTitle) {
            setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: 'Please Enter Group Name' }));
            return;
        } else {
            setCurrentIndexMsg((prev: any) => ({ ...prev, indexNo: index, msg: '' }));
        }

        if (parentId) {
            delete obj['_id'];
            addSubItems(obj, parentId).then((res: any) => {
                if (res.status) {
                    if (res.data && res.data.subData && res.data.subData.length) {

                        const tempObj: any = res.data.subData[res.data.subData.length - 1];

                        if (tempObj && tempObj['_id']) {
                            update(index, { ...watch().subData[index], _id: tempObj['_id'] });
                            console.log("tempObj",tempObj);
                            setSecurityId(tempObj['_id']);
                        }
                        
                        addHistory({ ...obj, _id: tempObj['_id'], status: 'create', type: 'Security Card' });
                        setIsLoading(false);
                        reload('a');
                        getData();
                         //reset to success
                         resetCheck(index);
                         resetGray(index);
                         console.log('resetCheck in update', index);
  
                        
                    }
                }
            });
        } else {
            let _id: string = data?._id;
            delete data['_id'];
            /**@ADD_API */
            addAndUpdateSecurityQuestion(_id, data).then((res: any) => {
                if (res && res.status == true && res.data) {
                    if (res.data && res.data.subData.length) {
                        update(index, { ...obj, _id:res.data.subData[0]['_id'], isUpdated: false });
                        subDataRef.current[index]['title']=obj['title'];
                        subDataRef.current[index]['description']=obj['description'];

                        addHistory({ ...obj, _id: res.data.subData[0]['_id'], status: 'create', type: 'Security Card' });
                        setSecurityId(res?.data?._id);
                        setValue('_id', res?.data?._id);
                        setValue(`subData.${index}._id`, res.data.subData[0]['_id']);
                        setIsLoading(false);
                        reload('a');
                        getData();
                        //reset gray
                        resetCheck(index);
                        resetGray(index);
                        
                    }
                } 
            })
        }
    }

    /**
     * @FORM_CHANGE_STATUS
     * Handle form change status for a specific item.
     * @param index - Index of the item in the form.
     * @param idName - Name or identifier of the item.
     */
    // Wrap changeToGray with debounce
// Define the changeToGray function
const changeToGrayDebounce = async (index: number, id: string, isNewState: boolean): Promise<void> => {
    return new Promise((resolve) => {
        const obj: any = { ...watch().subData[index] };

        if (obj['_id']) {
            if (isNewState) {
                setCheckMarkStates(prev => ({
                    ...prev,
                    [index]: { color: 'default', refresh: isNewState, trigger: !checkMarkStates[index]?.trigger }
                }));
                
                // setCheckMarkStates(prev => ({
                //     ...prev,
                //     [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
                // }));
                console.log('changeToGray', index, id, obj['_id']);
            } else {
                setCheckMarkStates(prev => ({
                    ...prev,
                    [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
                }));
                console.log('changeToGray else', index, id);
            }
        }

        resolve();
    });
};

const changeToGray = debounce(changeToGrayDebounce as any, 3000, stateMap);


const changeToGrayNoDebounce = (index: number, id: string, stateMap: any) => {
    console.log('changeToGray2', index, id);
    
    if (!stateMap.has(index)) {
        stateMap.set(index, { timeout: null, isDebounced: true });

        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'default', refresh: true, trigger: !checkMarkStates[index]?.trigger }
        }));

      } else {
        setCheckMarkStates(prev => ({
            ...prev,
            [index]: { color: 'default', refresh: false, trigger: !checkMarkStates[index]?.trigger }
        }));
      }
    
};
    

    
    

    return (
        <Offcanvas show={show} placement={'end'} style={{ minWidth: 730 }} onHide={handleClose}>
            <form ref={formRef} method="post" className="h-100" autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
                <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ddd' }}>
                    <Offcanvas.Title>
                        <div className="title-header-dynamic">
                            <FontAwesomeIcon icon={faPersonMilitaryPointing} color="var(--lt-primary)" />
                            <Controller
                                name={`mainTitle`}
                                control={control}
                                defaultValue={securityData?.keyName}
                                rules={{ required: 'This field is required' }}
                                render={({ field }) => <input type="text" className="dotted-input"
                                placeholder="Enter Group Name*" {...field} />}
                            />
                            {securityId ? (
                                <div className="d-flex align-items-center">
                                    <PrimaryButton onClick={() => handleSaveClick(watch())} type="button">Update</PrimaryButton>
                                    {/* <div className="d-flex align-items-center ms-2">
                                        <CheckMark 
                                        width={20}
                                        height={20}
                                        color={checkMarkStateHeader.color as 'success' | 'default' | 'error' | 'primary' | 'warn' | 'light'}
                                        refresh={checkMarkStateHeader.refresh}
                                        trigger={checkMarkStateHeader.trigger}
                                        index={-1}
                                        />
                                    </div> */}
                                </div>
                            ):null}

                        </div>
                        {errors?.mainTitle && (
                            <div style={{ marginLeft: 32 }} className="sc-input-err-msg">{errors.mainTitle?.message}</div>
                        )}
                        {/* <button type="button" style={{ position: 'absolute', right: 60, top: 20, fontSize: 16, color: 'var(--lt-primary)' }} onClick={() => setShowHistory(true)}>History</button> */}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pe-0 pt-0 ps-0 h-100">
                    <div className="card  h-100" style={{ height: 'calc(100% - 10px)', maxHeight: 'calc(100% - 10px)', overflowY: 'hidden', minWidth: 500 }}>
                        <div className="card-body h-100" style={{ padding: '15px 15px 15px 15px', overflowY: 'auto', maxHeight: (window.innerHeight - 120) }}>
                            {fields.map((field: any, index: number) => {
                                return (
                                    <div key={field.id} className="card sc-card-list shadow-sm pb-3" style={{}}>
                                        <div className="form-group d-flex flex-row align-items-center">
                                            <div className="col-7 col-md-7 col-lg-7">
                                            <span style={{ display: 'inline-block' }}>
                                            
                                            <CheckMark
                                                width={20}
                                                height={20}
                                                color={checkMarkStates[index]?.color as 'success' | 'default' | 'error' | 'primary' | 'warn' | 'light'}  
                                                refresh={checkMarkStates[index]?.refresh ? true : false}
                                                trigger={checkMarkStates[index]?.trigger }
                                                index={index}
                                            />
                                                        

{/* {!isLoading ? (<CheckMark height={20} width={20} color={(notification.type === 'success' ? 'success' : (field?.isUpdated ? 'default': (field?._id ? 'success' : 'default')))} />) : <CheckMark height={20} width={20} color={'default'} />} */}
                                                <span>&nbsp;&nbsp;&nbsp;</span>
                                            </span>

                                                <Controller
                                                    name={`subData.${index}.title`}
                                                    control={control}
                                                    defaultValue={field.title}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <input type="text" className="dotted-input" placeholder="Enter Title *" {...field} id={`title-${index}`} onKeyUp={(e) => {
                                                        field.onChange(e);
                                                        changeToGray(index, "title");
                                                    }}/>}
                                                />
                                                {errors.subData?.[index]?.title && (
                                                    <div style={{ marginLeft: 32 }} className="sc-input-err-msg">{errors.subData[index]?.title?.message}</div>
                                                )}
                                            </div>

                                            <div className="col-3 col-md-3 col-lg-3 text-end">
                                                <div className="publc-prit-btns">
                                                    <button type="button" className={`btn-sm ${field.isPublic ? 'activeTwo' : ''}`} onClick={() => updateItem(index, true)}>Public</button>
                                                    <button type="button" className={`btn-sm ${field.isPublic ? '' : 'activeTwo'}`} onClick={() => updateItem(index, false)}>Private</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 d-flex flex-row align-items-center">
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <div className="form-group" style={{ width: '100%' }}>
                                                <label><b>Description</b></label>
                                                <Controller
                                                    name={`subData.${index}.description`}
                                                    control={control}
                                                    defaultValue={field.description}
                                                    rules={{ required: 'This field is required' }}
                                                    render={({ field }) => <textarea placeholder="Enter Description*" className="form-control mt-1" rows={6} {...field} id={`description-${index}`} onKeyUp={(e) => {
                                                        field.onChange(e);
                                                        changeToGray(index, "description");
                                                    }} ></textarea>}
                                                />
                                                {errors.subData?.[index]?.description && (
                                                    <div className="sc-input-err-msg">{errors.subData[index]?.description?.message}</div>
                                                )}
                                            </div>
                                        </div>

                                        {securityId ? (
                                            <section>
                                                <div style={{ paddingLeft: 28 }}>
                                                {field?._id ? (
                                                    <div className="row align-items-center mt-3 mb-0">
                                                        <div className="col-8 col-md-8 col-lg-8">Upload files</div>
                                                        <div className="col-4 col-md-4 col-lg-4 text-end">
                                                            <input type="file" accept="image/jpeg,image/png,application/pdf" ref={(el) => (fileInputRefs.current[index] = el)} onChange={(e) => onDocumentFileUpload(e, index)} id="documentFileInput" style={{ opacity: 0, width: 0, height: 0 }} />

                                                            <div>
                                                                
                                                                    <IconButton varient={'Text'} className="position-relative" style={{ height: 30, width: 30, borderRadius: 100, }} type="button" onClick={() => chooseFile(index)}>
                                                                        <img src={process.env.PUBLIC_URL + 'assets/imgs/upload-icon.png'} alt="upload-icon" style={{ width: 25, marginTop: 9, marginRight: 2 }} />
                                                                    </IconButton>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                                    
                                                    <ul className="list-group list-group-flush tc-docs-list">
                                                        {field?.files.map((item: any, sno: number) => (
                                                            <li key={`doc-list-${sno}`} className="list-group-item">
                                                                <div className="row align-items-center">
                                                                    <div className="col-8 col-lg-8 col-xl-8 text-truncate" ><FontAwesomeIcon color={'var(--lt-primary)'} icon={(item.fileName.includes('.pdf') ? faFilePdf : faImage)} />&nbsp;&nbsp;&nbsp;{item.fileName}</div>
                                                                    <div className="col-4 col-lg-4 col-xl-4 tc-docs-action text-end">
                                                                        <span onClick={() => onFileDelete(index, sno)} >
                                                                            <FontAwesomeIcon icon={faTrash} color={'var(--lt-danger)'} />
                                                                            <a href="#" className="view-download-link text-danger">Delete</a>
                                                                        </span>
                                                                        <span>&nbsp;&nbsp;</span>
                                                                        <span onClick={() => onFileDownload(index, sno, field.isPublic)}>
                                                                            <FontAwesomeIcon icon={faDownload} color={'var(--lt-primary)'} />
                                                                            <a href="#" className="view-download-link">Download</a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </section>
                                        ) : null}

                                        <Row className="pt-2">
                                            <Col className="text-danger pt-2">
                                                <small>{currentIndexMsg.indexNo == index ? currentIndexMsg.msg : ''}</small>
                                            </Col>
                                            <Col className="text-end">
                                                <IconButton className="delete-btn" onClick={() => onSecurityCardDelete(index)}
                                                >Delete</IconButton>&nbsp;&nbsp;

                                                {(field._id ? (
                                                    <PrimaryButton type="button" className="submit-btn" onClick={() => onUpdateCardItem(index)}
                                                    >Update</PrimaryButton>
                                                ) : (
                                                    <PrimaryButton type="button" className="submit-btn" onClick={() => addSubCardItems(index)}
                                                    >Save</PrimaryButton>
                                                ))}
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}

                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <div />
                                <IconButton varient="Circle" style={{ height: 30, width: 30, backgroundColor: 'var(--lt-primary)' }} onClick={() => addNewCard()}>
                                    <FontAwesomeIcon style={{ fontSize: 18 }} icon={faPlus} color="var(--lt-light)" />
                                </IconButton>
                            </div>


                        </div>
                        <div className="card-footer text-end pl-0">
                            <IconButton className="delete-btn" onClick={handleDeleteAll}>Delete Card</IconButton>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </div>
                    </div>

                    <History show={showHistory} type="Security Card" onHide={() => setShowHistory(false)} />
                </Offcanvas.Body>
            </form>
        </Offcanvas>
    )
}

export default SecurityCardEdit
