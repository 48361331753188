import { types } from "../Types/SagaType";

/**
 * @description get the current autheticated user.
 */
export const getCompanyList = () => ({
    type: types.GET_COMPANY_LIST_REQUEST,
    payload: null
});

/**
 * @description delete the company request
 */
export const deleteCompanyRequest = (payload:any) => ({
    type: types.DELETE_COMPANY_REQUEST,
    payload
});

/**
 * @description get the getComplianceData.
 */
export const getComplianceData = (payload: string) => ({
    type: types.GET_COMPLIANCE_LIST_REQUEST,
    payload
});

/**
 * @description get the getQuestionnairesData.
 */
export const getQuestionnairesData = (payload: string) => ({
    type: types.GET_QUESTIONNAIRE_LIST_REQUEST,
    payload
});

/**
 * @description setSelectedQuestion
 */
export const setSelectedQuestion = (payload: any) => ({
    type: types.SET_SELECTED_REQUEST,
    payload
});

/**
 * @description enterprseUpdateQuestion
 */
export const enterprseUpdateQuestion = (payload: any, index: number) => ({
    type: types.UPDATE_ENTERPRISE_REQUEST,
    payload: { data: payload, index }
});

/**
 * @description setAuthData
 */
export const setAuthData = (payload: any) => ({
    type: types.SET_AUTH_REQUEST,
    payload: payload
});

/**
 * @description GET TS LIST
 */
export const getTSList = (payload: any) => ({
    type: types.GET_TS_LIST_REQUEST,
    payload: payload
});

/**
 * @description GET TS LIST
 */
export const getTCenterComplianceList = (payload: boolean) => ({
    type: types.GET_TC_COMPLIANCE_LIST_REQUEST,
    payload: payload
});

/**
 * @description GET TS LIST
 */
export const checkSpecialDomain = (payload: string) => ({
    type: types.CHECK_SPECIAL_DOMAIN_REQUEST,
    payload: payload
});

/**
 * @description GET Client Details
 */
export const getClientDetails = (payload?: boolean) => ({
    type: types.GET_CLIENT_DETAILS_REQUEST,
    payload: payload
});

/**
 * @description GET Client Details
 */
export const setEnterpriseFileLoading = (payload: any) => ({
    type: types.SET_ENTERPRISE_FILE_LOADING_REQUEST,
    payload
});

/**
 * @description GET Client Details
 */
export const removeEnterpriseItemFromList = (payload: any) => ({
    type: types.DELETE_ENTERPRISE_FILE_REQUEST,
    payload
});

/**
 * @description GET Client Details
 */
export const setReviewCount = (payload: any) => ({
    type: types.SET_COUNT_REQUEST,
    payload
});