import React, { useState, useEffect } from 'react';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Overview.css';
import { getOverviewUser } from './../../ApiServices/TrustCenter';
import { OverviewModel } from './../../Models/SecurityModel';



const CollapsibleOverview =   () => {
  const [open, setOpen] = useState<boolean>(false);
  const [overviewInfo, setOverviewInfo] = useState<OverviewModel>({ subTitle: "", description: "", _id: "" });

  useEffect(() => {
    getOverviewList();
  }, []);
  
  const toggleArrow = () => {
    setOpen(!open);
  };

  /**@OVERVIEW_LIST */
  const getOverviewList = async () => {
    const result: any = await getOverviewUser();
    if (result && result.data && result.data.length) {
      setOverviewInfo({
        _id: result.data[0]['_id'],
        subTitle: result.data[0]['subTitle'],
        description: result.data[0]['description']
      });
    }
  }

  if (!overviewInfo.subTitle) {
    return (<></>)
  }

  return (
    <div className="text-center">

      <button
        onClick={() => setOpen(!open)}
        aria-controls="collapse-content"
        aria-expanded={open}
        className="custom-button"
      >

        {overviewInfo.subTitle}
        <div onClick={toggleArrow} style={{marginTop:-8}}>
          {open ? <FontAwesomeIcon icon={faAngleUp} size="1x" /> : <FontAwesomeIcon icon={faChevronDown} size="1x" />}
        </div>

      </button>

      <Collapse in={open}>
        <div className="row justify-content-center abc w-100 ">
          <div className="text-white text-css p-3 pt-0 mt-2 text-start w-60">
            {/* <p>{overviewInfo.subTitle}</p> */}
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {overviewInfo.description}
            </p>
            {/* <p>LinkedIn maintains an Information Security Program to ensure the confidentiality, integrity, and availability of all computer and data communication systems while meeting the necessary legislative, industry, and contractual requirements.</p>
            <p>Our Smart Trust Center offers customers access to LinkedIn’s latest security reports and documents, including ISO certifications and our SOC 2 report.</p> */}
          </div>
        </div>
      </Collapse>

    </div>
  );
};

export default CollapsibleOverview;