import { useState, useEffect, useMemo, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDown, faFileLines, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import Form from 'react-bootstrap/Form';
import './BulkDownload.css';
import { BulkDownloadModelNew } from './../../Models/EditorDocumentModel';
import { getDocsList, bulkDownload, userAccessCheck, createDownloadLogs, getNewDocListForBulkdownload } from './../../ApiServices/TrustCenter';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    openFrom: 'user' | 'editor';
    openModal: boolean;
    handleClose: () => void;
}
const BulkDownload = ({ openModal, handleClose, openFrom }: Props) => {
    const [show, setShow] = useState(openModal);
    const [loading, setLoading] = useState<boolean>(true);
    const [documents, setDocuments] = useState<BulkDownloadModelNew[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const fileAccessPermission = useRef<boolean>(false);
    useEffect(() => {
        (async () => {
            const req: any = await userAccessCheck(); 
            if (openFrom == 'editor') {
                fileAccessPermission.current = true;
            }
            else if (req) {
                fileAccessPermission.current = true;
            } 
            else {
                fileAccessPermission.current = false;
            }
            fetchDocuments();
        })();
    }, []);

    const fetchDocuments = async () => {
        try {
            const docsapi: any = await getDocsList(openFrom == 'user' ? true : false);
            
            if (docsapi && docsapi.data && docsapi.data.length) {
                let temp = [];
                for (let i = 0; i < docsapi.data.length; i++) {
                    temp.push({ ...docsapi.data[i], checked: false });
                }
                setDocuments(temp);
            } else {
                setDocuments([]);
            }
            
        } catch (err) {
            console.error(err); // Log the error
            // setError('Failed to fetch documents');
        } finally {
            setLoading(false);
        }
    };


    const handleCheckAllFiles = () => {
        const allChecked = documents.every(doc =>
            doc.files.every(file => file.checked)
        );

        const newDocuments = documents.map(doc => ({
            ...doc,
            files: doc.files.map(file => ({ ...file, checked: !allChecked }))
        }));

        setDocuments(newDocuments);
    };

    const handleFileCheck = (docId: string, fileId: string) => {
        setDocuments(prevDocuments =>
            prevDocuments.map(doc => {
                if (doc._id === docId) {
                    const updatedFiles = doc.files.map(file => {
                        if (file._id === fileId) {
                            return { ...file, checked: !file.checked };
                        }
                        return file;
                    });
                    return { ...doc, files: updatedFiles };
                }
                return doc;
            })
        );
    };

    const checkedCount = useMemo(() => {
        return documents.reduce((count, doc) => {
            if ( fileAccessPermission.current === true  ) {
                return count + doc.files.filter(file => file.checked).length;
            } else if (doc?.isPublic === true) {
                return count + doc.files.filter(file => file.checked).length;
            }
            else {
                return count;
            }
            // if (doc?.isPublic === false && fileAccessPermission.current === false) {
            //     return count;
            // } else {
            //     return count + doc.files.filter(file => file.fileName).length;
            // }
        }, 0);
    }, [documents]);

    const countTotalFiles = useMemo(() => {
        return documents.reduce((count, doc) => {
            if ( fileAccessPermission.current === true  ) {
                return count + doc.files.length;
            } else if (doc?.isPublic === true) {
                return count + doc.files.length;
            }
            else {
                return count;
            }
        }, 0);
    }, [documents]);

    const downloadInBulk = async () => {
        let docs: any = documents.flatMap(doc => {
          

            if ( fileAccessPermission.current === true  ) {
                return doc.files.filter(file => file.checked).map(file => ({ fkey: file._id, fname: file.fileName }))
            } else if (doc?.isPublic === true) {
                return doc.files.filter(file => file.checked).map(file => ({ fkey: file._id, fname: file.fileName }))
            }
            
        });
        docs = docs.filter((item: any) => item);
        const obj: any = {
            fileKeys: [...docs]
        }
        const result: any = await bulkDownload(obj);
        if (result) {
            console.log('downloaed');
        }
               // let tcheck = getLoginUserAccess()
        // if(true ){
        //     await createDownloadLogs(obj, 'Bulk Downlaod', 'editor', false);
        // }else{
        //     await createDownloadLogs(obj, 'Bulk Downlaod', 'user', false);
        // }
    };

    const onClose = () => {
        setShow(false);
        setTimeout(() => {
            handleClose()
        }, 500);
    }

    const FileGroupList = ({ sno, files, isPublic, item }: any) => {
        console.log(fileAccessPermission.current, 'fileAccessPermission.current');


        return (files.map((file: any, fileIndex: number) => (
            <div key={`list-group-item-file-${fileIndex}`}>
                <Form.Check
                    name={`group-${sno}-${fileIndex}`}
                    type={'checkbox'}
                    id={`checkbox-${sno}-${fileIndex}-1`}
                >
                    <Form.Check.Input checked={file.checked} onChange={() => handleFileCheck(item._id, file._id)} type={'checkbox'} />
                    <Form.Check.Label style={{ color: (item.type == 'compliance' ? '#000' : 'var(--lt-primary)') }}>{file?.fileName}</Form.Check.Label>
                    <span className="extenstion pointer">{(file.fileName.includes('.pdf') ? 'pdf' : 'Image')}</span>
                </Form.Check>
            </div>
        )))
    }

    return (
        <div>
            <Modal size="lg" centered show={show} onHide={() => { setShow(false); handleClose() }}>
                <Modal.Header closeButton>
                    <div>
                        <h2 className="modal-title font-weight-bold" style={{ color: "var(--lt-primary)", fontWeight: '600' }} id="exampleModalLabel">Bulk download</h2>
                        <p>Documents that are unlocked and available for download can be bulk selected below.</p>
                        <div className="p-0 form-group">
                            <div className="form-check">
                                <label className="text-muted form-check-label">
                                    <input onChange={handleCheckAllFiles} name="selectAllChecked" type="checkbox" id="selectAllChecked" className="form-check-input" />
                                    <span>Select all {countTotalFiles} documents</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className={'overflow-y pt-0'}>
                    <ul className="list-group list-group-flush bulk-download avatar-list">
                        {loading ? <div className='text-center mt-5 mb-5'><Spinner animation="border" variant="primary" /></div> : (
                            documents.map((item: any, index: number) => (
                                <li key={`list-group-item-${index}`} className={`list-group-item ${((item?.isPublic === false && fileAccessPermission.current == false) ? 'hide' : '')}`}>
                                    <div className="avatar-item">
                                        <div className="avatar-img">
                                            {item.type == 'compliance' ? (
                                                <img src={process.env.PUBLIC_URL + '/assets/imgs/compliance/' + item.icon} alt={`Avatar ${index + 1}`} />
                                            ) : (<FontAwesomeIcon icon={faFileLines} style={{ fontSize: 22 }} color="rgb(10, 102, 194)" />)}
                                        </div>
                                        <div className="avatar-info">
                                            <h3 className="title font-weight-bold text-uppercase">{item.type == 'compliance' ? 'COMPLIANCE' : item.documentName}</h3>
                                            <div className="desc">{item.documentName}</div>
                                        </div>
                                    </div>
                                    {(item?.files && item?.files.length > 0) ? (
                                        <FileGroupList sno={index} item={item} files={item?.files} isPublic={item?.isPublic} />
                                    ) : null}
                                </li>
                            ))
                        )}


                        {/* <li className="list-group-item">
                            <div className="avatar-item">
                                <div className="avatar-img">
                                    <FontAwesomeIcon icon={faFileLines} style={{ fontSize: 22 }} color="rgb(10, 102, 194)" />
                                </div>
                                <div className="avatar-info">
                                    <h3 className="title font-weight-bold text-uppercase">REPORTS</h3>
                                    <div className="desc">{'Security Prospectus'}</div>
                                </div>
                            </div>
                            {docs.map((item: any, index: number) =>
                                <div key={index} className="form-check">
                                    <label className="text-muted form-check-label">
                                        <input name="selectAllChecked" type="checkbox" id="selectAllChecked" className="form-check-input" />
                                        <span className="name">{item.name}</span>
                                        <span className="extenstion pointer">pdf</span>
                                    </label>
                                </div>
                            )}
                        </li> */}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button> */}

                    <button type="button" onClick={onClose} className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                    <PrimaryButton onClick={() => downloadInBulk()} name='' >
                        <div>
                            <FontAwesomeIcon icon={faCircleDown} color="var(--lt-light)" />
                            <span>&nbsp;&nbsp;{`Download ${checkedCount} documents`}</span>
                        </div>
                    </PrimaryButton>
                </Modal.Footer>
            </Modal>

        </div>
    )
};

export default BulkDownload;